import React, { useState } from "react";
import "../Styles/FAQPage.css";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is Aryadhenu?",
      answer: (
        <p>
         Aryadhenu is a leading brand that provides natural, chemical-free, and healthy sweeteners. We collaborate with local farmers and agronomists to get high-quality sugarcane. These quality sugarcanes are used to deliver high-quality products such as Khandsari Sugar (Desi Khand), Cane Jaggery (Shudh Desi Gud), Jaggery Powder (Desi Shakkar) and Boora to fulfil the daily need for sweetness.
        </p>
      ),
    },
    {
      question: "What products does Aryadhenu offer?",
      answer: (
        <>
        <p>Aryadhenu offers a wide range of natural sweeteners that are of top-notch quality. 
        </p>
        <ul>
          <li>Khandsari Sugar (Shudh Desi Khand)</li>
          <li>Cane Jaggery (Shudh Desi Gud)</li>
          <li>Cane Jaggery Powder (Shudh Desi Shakkar)</li>
          <li>Khandsari Sugar Powder (Shudh Desi Boora)</li>
          
        </ul>
        </>
      ),
    },
    {
      question: "Why Aryadhenu Sweeteners are the best among all products in the market?",
      answer: (
        <p>Aryadhenu sources quality sugarcane from local farmers who use new agricultural practices, and quality seeds to get the best sugarcanes, These quality sugarcanes are then used to make authentic sweeteners that are pure and without any artificial additives, We use a natural purification process and pure A2 Cow ghee for flavour that makes our products best in terms of quality, purity and taste.Aryadhenu products are not only healthy but also produced without any chemicals or preservatives. They are made using traditional methods to ensure purity and health benefits.
        </p>
      ),
    },
    
    {
      question: "How do I purchase Aryadhenu products?",
      answer: (
        <p>
          You can easily purchase our khand, jaggery, shakkar and boora online through our official website, Amazon, Flipkart, Meesho, and other e-commerce platforms. Just browse the product categories, add items to your cart, and follow the checkout process to complete the purchase. Do not hesitate to call our customer care contact: +91-9467843826 if you have any doubts.

        </p>
      ),
    },
    {
      question: "Does Aryadhenu ship all over India and internationally?",
      answer: (
        <p>
          Aryadhenu ships all over India as well as Internationally, In every state we have distributors as well as we take bulk orders, for bulk orders please fill out the bulk order form so that we can contact and dispatch your order.
          <Link to='/bulk-order'>Bulk Order Form</Link>
        </p>
        
      ),
    },
    {
      question:"How long does delivery take?",
      answer:(
        <p>Delivery time may vary depending on your location, but orders are typically processed and shipped within 2-5 business days. Once your order is shipped, you will receive a tracking number and for further queries, you can contact our customer care contact number.</p>
      )

    },
    {
      question:"How can I create my account or register to purchase through the website?",
      answer:(
        <p>You can register through the link https://aryadhenu.com/signup. You have to enter your email ID, contact number and password to register. If you are already a customer of Aryadhenu, you can log in with your email and passwords that you have used during sign-up. If you forgot your password, you may request a new password with the e-mail address known to us.
        </p>
      )
    },
    {
      question:"Will I get a discount if I purchase combo products?",
      answer:(
        <p>
          Yes, If you are purchasing combo products then you will get discounts that are specifically mentioned in our Shop Now section, drop us a message if you have any doubts regarding the discount.
        </p>
      )
    },
    {
      question:"Why is desi khand better than refined sugar?",
      answer:(
        <p>
          Desi Khand is a natural and healthy alternative to refined sugar, with all the nutrients that are demolished by the chemical processing of refined sugar. White sugar is bleached by substances like sulphur and undergoes heavy chemical refinement as compared to Shudh Desi khand which is prepared the traditional way. Aryadhenu Shudh Desi Khand is 100 % natural, pure, chemical-free and rich in nutrients like Calcium, Iron, Magnesium etc.
        </p>
      )
    },
    {
      question:"Can we give desi khand to babies?",
      answer:(
        <p>
          Yes, Aryadhenu Shudh Desi Khand is a raw natural sugar with its natural flavour and taste, so once you start giving porridge, kheer, curd and milk to your child then it is the best choice for your baby above one year of age. Consult with your child nutritionist before starting any baby food for your child.

        </p>
      )
    },
    {
      question:"Which Jaggery is Best?",
      answer:(
        <p>
          Among all the jaggery that are available in the market, Aryadhenu Shudh Desi Gud is the best as it comes in the purest, chemical-free and nutrient-rich form among all available jaggery. It is prepared using the traditional process of A2 cow milk, Aryadhenu Jaggery retains all-natural minerals, rich aroma, and authentic taste, making it superior to regular jaggery available in the market.
        </p>
      )
    },
    {
      question:"Which Jaggery powder is best?",
      answer:(
        <p>
          When choosing the best jaggery powder, purity, nutritional value, and processing methods matter the most. Aryadhenu Shudh Desi Jaggery Powder is the finest choice because it is 100% pure, chemical-free, unrefined, and prepared using traditional methods to retain its natural sweetness, minerals, and health benefits. Its consistency, texture, taste and flavour are perfect for any dish or dessert.
        </p>
      )
    },
    
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <Helmet>
        <meta charset="UTF-8"></meta>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
         ></meta>
        <meta name="author" content="" />
        <meta name="robots" content=""></meta>
        <link rel="canonical" href=""></link>
        <title>FAQ's</title>
        <meta name="description" content="Frequently asked questions to Aryadhenu" />
        <meta name='keywords' content="Aryadhenu,Bulk Order,Khandsari sugar,Cane jaggery ,Cane jaggery powder,Khandsari sugar powder ,desi khand,Khand,Jaggery,gur,jaggery,desi gur,desi gud,shakkar,jaggery powder,brown sugar,boora,boora sugar,quality ,natural,online,raw,indian,sugarcane,pure ,natural ,buy online,substitute,alternate,raw sugar,Why Aryadhenu Sweeteners are best among all products in the market?,Why is desi khand better than refined sugar?,Which Jaggery is Best?
        ,Which Jaggery powder is best?,Does Aryadhenu ship all over India and internationally?" />
      </Helmet>
      <h1 className="faq-title">FAQ</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <span>{activeIndex === index ? "-" : "+"}</span>
            </button>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
      <Navbar isHomePage={false}/>
    </div>
  );
};

export default FAQPage;
