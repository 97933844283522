import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import AlertPopup from "../AlertPopup";
import BloggerEditor from "../Blogs/BloggerEditor";
import "./CreateBlog.css";
const CreateBlog = ({ blogToEdit, onEditComplete }) => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState("");
  const [category, setCategory] = useState("");
  const [keywordString, setKeywordString] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaCanonical, setMetaCanonical] = useState("");
  const [metaAuthor, setMetaAuthor] = useState("");
  const [metaRobots, setMetaRobots] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (blogToEdit) {
      setTitle(blogToEdit.title);
      setAuthor(blogToEdit.author);
      setSummary(blogToEdit.summary);
      setContent(blogToEdit.content);
      setCategory(blogToEdit.category);
      setKeywordString(blogToEdit.keywords.join(","));
      setMetaTitle(blogToEdit.metaTitle);
      setMetaAuthor(blogToEdit.metaAuthor);
      setMetaCanonical(blogToEdit.metaCanonical);
      setMetaDescription(blogToEdit.metaDescription);
      setMetaRobots(blogToEdit.metaRobots);
    }
  }, [blogToEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const keywordItems = keywordString.split(",").map((item) => item.trim());
      const newBlog = {
        title,
        author,
        summary,
        category,
        content,
        keywords: keywordItems,
        metaAuthor,
        metaCanonical,
        metaTitle,
        metaRobots,
        metaDescription,
      };
      console.log(newBlog, "blog being edited");
      if (blogToEdit) {
        // Update blog if editing
        await axios.put(
          `${BACKEND_URL}/update-blog/${blogToEdit._id}`,
          newBlog
        );
        setAlertMessage("Blog updated successfully!");
        onEditComplete();
      } else {
        await axios.post(`${BACKEND_URL}/api/create-blogs`, newBlog);
        setAlertMessage("Blog created successfully!");
        setTitle("");
        setAuthor("");
        setContent("");
        setSummary("");
        setCategory("");
        setMetaAuthor("");
        setMetaCanonical("");
        setMetaDescription("");
        setMetaTitle("");
        setMetaRobots("");
      }
    } catch (error) {
      setError(blogToEdit ? "Failed to update blog" : "Failed to create blog");
    } finally {
      setLoading(false);
    }
  };

  const uploadFileToServer = async (file, filename) => {
    const formData = new FormData();
    formData.append("file", file, filename);

    const response = await fetch(`${BACKEND_URL}/upload-files`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    if (response.ok && data.url) {
      return data.url;
    } else {
      throw new Error(data.error || "Upload failed");
    }
  };

  if (error) {
    return (
      <div className="error-class">
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className="create-blog-form">
          <form onSubmit={handleSubmit}>
            <h2>{blogToEdit ? "Edit Blog" : "Create Blog"}</h2>
            <AlertPopup
              message={alertMessage}
              onClose={() => setAlertMessage("")}
            />
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <input
              type="text"
              placeholder="Author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              required
            />

            <input
              type="text"
              placeholder="Summary"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />

            <input
              type="text"
              placeholder="Enter keywords separated by commas"
              value={keywordString}
              onChange={(event) => setKeywordString(event.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Meta Canonical"
              value={metaCanonical}
              onChange={(event) => setMetaCanonical(event.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Meta Author"
              value={metaAuthor}
              onChange={(event) => setMetaAuthor(event.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Meta Robots"
              value={metaRobots}
              onChange={(event) => setMetaRobots(event.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Meta Title"
              value={metaTitle}
              onChange={(event) => setMetaTitle(event.target.value)}
            />
            <input
              type="text"
              placeholder="Enter Meta Description"
              value={metaDescription}
              onChange={(event) => setMetaDescription(event.target.value)}
            />

            <BloggerEditor
              setContent={setContent}
              content={content}
              fileupload={uploadFileToServer}
            />

            <button type="submit" className="create-blog-btn">
              {blogToEdit ? "Update Blog" : "Create Blog"}
            </button>
          </form>
          {/* <div>
        <h3>Preview Content</h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div> */}
        </div>
      )}
    </>
  );
};

export default CreateBlog;
