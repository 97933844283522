import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faIndianRupeeSign,
  faArrowRightLong,
  faArrowLeftLong,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Shop.css";
import { AppContext } from "../AppContext";
import { Helmet } from "react-helmet";

import Navbar from "./Navbar";

import { useNavigate } from "react-router-dom";
import { useCart } from "../CartContext";
import CartPopup from "./CartPopup";

import ReactSlider from "react-slider";
import AlertPopup from "./AlertPopup";

const isTokenValid = () => {
  const token = localStorage.getItem("token");
  const expiry = localStorage.getItem("tokenExpiry");
  return token && new Date().getTime() < expiry;
};

function Shop() {
  const [messages, setMessages] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIndex, setFadeIndex] = useState(null); // Track the fading card
  const [quantity, setQuantity] = useState(1);
  const cardsToShow = 20;
  const [addedToCart, setAddedToCart] = useState(false);
  const { cart, addToCart } = useCart();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupProduct, setPopupProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [priceRange, setPriceRange] = useState([0, 5000]);
  const [discountPercentageFilter, setDiscountPercentageFilter] =
    useState(null);
  const [ratingFilter, setRatingFilter] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");

  const {
    products,
    setProducts,
    getActiveSaleForProduct,
    loading,
    productsLoading,
    setProductsLoading,
  } = useContext(AppContext);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 3000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, [navigate]);

  useEffect(() => {
    const fetchProducts = async () => {
      setProductsLoading(true);

      try {
        const response = await axios.get(`${BACKEND_URL}/getProduct`);

        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          setProducts(data);
          sessionStorage.setItem("products", JSON.stringify(data));
          setMessages({ success: response.message });
        } else {
          setMessages({ error: response.message });
        }
      } catch (error) {
        setMessages({ error: { error } });
      } finally {
        setProductsLoading(false); // Hide loader once data is fetched
      }
    };
    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      setProducts(JSON.parse(storedProducts));
      setProductsLoading(false);
    } else {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);

  const handleAddToCart = (card) => {
    if (card && !card.isStockAvailable) {
      setAlertMessage(`${card.title} out of stock`);
    } else if (card && card.isStockAvailable) {
      addToCart(card, quantity);
      setPopupProduct(card);
      setAddedToCart(true);
      setIsPopupVisible(true);
    }
  };

  const next = () => {
    if (currentIndex < products.length - cardsToShow) {
      setFadeIndex(currentIndex + cardsToShow);
      setCurrentIndex(currentIndex + 8);
      setTimeout(() => setFadeIndex(null), 300);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setFadeIndex(currentIndex - 1);
      setCurrentIndex(currentIndex - 8);
      setTimeout(() => setFadeIndex(null), 300);
    }
  };

  const navigateToCheckout = () => {
    handleAddToCart();
    if (isTokenValid()) {
      navigate("/login-checkout");
    } else {
      navigate("/checkout");
    }
  };

  const onClose = () => {
    setIsPopupVisible(false);
  };

  const handleOnClick = (productId) => {
    navigate(`/shop/${productId}`);
  };

  const calculateDiscountPercentage = (quotePrice, discountPrice) => {
    if (quotePrice && discountPrice) {
      const discount = ((quotePrice - discountPrice) / quotePrice) * 100;
      return discount > 0 ? discount.toFixed(1) : null; // Limit to 1 decimal
    }
    return null;
  };

  const resetFilters = () => {
    setPriceRange([0, 2000]);
    setDiscountPercentageFilter(null);
    setRatingFilter(null);
    setSortOption(null);
    setSearchTerm("");
  };

  useEffect(() => {
    const applyFilters = () => {
      const filtered = products
        .filter((product) => {
          if (
            searchTerm &&
            !product.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
            return false;

          if (
            product.discountPrice < priceRange[0] ||
            product.discountPrice > priceRange[1]
          )
            return false;

          const discountPercentage =
            ((product.quotePrice - product.discountPrice) /
              product.quotePrice) *
            100;
          if (
            discountPercentageFilter &&
            discountPercentage < discountPercentageFilter
          )
            return false;

          if (ratingFilter && product.rating < ratingFilter) return false;

          return true;
        })
        .sort((a, b) => {
          if (sortOption === "priceLowToHigh")
            return a.discountPrice - b.discountPrice;
          if (sortOption === "priceHighToLow")
            return b.discountPrice - a.discountPrice;
          if (sortOption === "discountHighToLow")
            return (
              ((b.quotePrice - b.discountPrice) / b.quotePrice) * 100 -
              ((a.quotePrice - a.discountPrice) / a.quotePrice) * 100
            );
          if (sortOption === "ratingHighToLow") return b.rating - a.rating;
          return 0;
        });
      setFilteredProducts(filtered);
    };

    applyFilters();
  }, [
    products,
    searchTerm,
    priceRange,
    discountPercentageFilter,
    ratingFilter,
    sortOption,
  ]);

  console.log(products, "products");
  console.log(productsLoading, "products loading");

  return (
    <>
      <Helmet>
        <meta charset="UTF-8"></meta>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
         ></meta>
        <meta name="author" content="" />
        <meta name="robots" content=""></meta>
        <link rel="canonical" href=""></link>
        <title>Shop at Aryadhenu</title>
        <meta
          name="description"
          content="Shop at Aryadhenu for 100% Natural Organic Sweeteners "
        />
        <meta
          name="keywords"
          content="Khand,Sugar,raw,cane,organic,natural,sweetener,brown,unrefined,best,healthy,pure,desi,shudh,khandsari,good,diet,unprocessed,aryadhenu,granulated,indian,online,quality,free,substitute,traditional,Jaggery,gur,cube,gud,sugar,,shudh,desi ,natural,best,healthy,original,online,raw,free ,substitute,shakkar,powdered,sugar,Boora"
        />
      </Helmet>
      {productsLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="shop">
          <AlertPopup
            message={alertMessage}
            onClose={() => setAlertMessage("")}
          />
          <div className="page-info">
            <h1>Shop Products</h1>
            <div className="page-info-navigation">
              <div
                className="page-info-navigation-icons"
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
              </div>
            </div>
            <div className="sort-filter">
              <h4>Sort By</h4>
              <select
                onChange={(e) => setSortOption(e.target.value)}
                value={sortOption}
              >
                <option value="">None</option>
                <option value="priceLowToHigh">Price: Low to High</option>
                <option value="priceHighToLow">Price: High to Low</option>
                <option value="discountHighToLow">Discount: High to Low</option>
                <option value="ratingHighToLow">Rating: High to Low</option>
              </select>
            </div>
          </div>
          <div className="shop-page-container">
            <div className="shop-page-sidebar">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search for products..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
              </div>

              <div className="shop-page-filters">
                <h3>Filters</h3>

                {/* Price Range Filter */}
                <div>
                  <h4>Price Range</h4>
                  <ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    value={priceRange}
                    onChange={(newValue) => setPriceRange(newValue)}
                    min={0}
                    max={2000}
                    step={1}
                    ariaLabel={["Lower thumb", "Upper thumb"]}
                    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <div className="price-label">₹{state.valueNow}</div>
                      </div>
                    )}
                  />
                </div>

                {/* Discount Filter */}
                <div>
                  <h4>Discount</h4>
                  {[5, 10, 20, 30].map((percentage) => (
                    <button
                      key={percentage}
                      onClick={() => setDiscountPercentageFilter(percentage)}
                      className={`discount-btn ${
                        discountPercentageFilter === percentage ? "active" : ""
                      }`}
                    >
                      {percentage}% or more
                    </button>
                  ))}
                </div>

                {/* Rating Filter */}
                <div>
                  <h4>Rating</h4>
                  {[4, 3, 2, 1].map((rating) => (
                    <button
                      key={rating}
                      onClick={() => setRatingFilter(rating)}
                      className={`rating-btn ${
                        ratingFilter === rating ? "active" : ""
                      }`}
                    >
                      {rating} stars or more
                    </button>
                  ))}
                </div>

                {/* Sorting Options */}

                <button onClick={resetFilters} className="reset-filters-btn">
                  Reset All Filters
                </button>
              </div>
            </div>

            <div className="shop-carousel-container">
              {filteredProducts.length === 0 ? (
                <div className="no-products-message">
                  <p>
                    No products match the applied filters or search. Try
                    adjusting your filters.
                  </p>
                </div>
              ) : (
                <div className="cards-container">
                  {filteredProducts
                    .slice(currentIndex, currentIndex + cardsToShow)
                    .map((card, index) => {
                      const discountPercentage = calculateDiscountPercentage(
                        card.quotePrice,
                        card.discountPrice
                      );
                      const activeSale = getActiveSaleForProduct(card._id);

                      return (
                        <>
                          <div
                            key={index}
                            className={`carousel-card ${
                              fadeIndex === currentIndex + index
                                ? "fade-in"
                                : ""
                            }`}
                          >
                            {discountPercentage > 0 && (
                              <div className="discount-badge">
                                {discountPercentage}% off
                              </div>
                            )}
                            {activeSale &&
                              activeSale.isPromotional === false && (
                                <div className="sale-information">
                                  <p>
                                    {" "}
                                    {activeSale.discountType === "amount"
                                      ? `Flat ₹${activeSale.discount} Off`
                                      : `${activeSale.discount}% Off`}{" "}
                                  </p>
                                  <p className="sale-min-purchase">
                                    Min Purchase: Rs.{activeSale.minPurchase}
                                  </p>
                                  <p>Coupon:{activeSale.couponCode}</p>
                                </div>
                              )}
                            <img
                              src={card.imageUrl}
                              alt="product"
                              onClick={() => handleOnClick(card._id)}
                            />
                            <div className="carousel-card-information">
                              <h5 onClick={() => handleOnClick(card._id)}>
                                {card.title}
                              </h5>
                              <span
                                className="review-stars"
                                style={{
                                  backgroundColor: "transparent",
                                  borderStyle: "none",
                                }}
                              >
                                {"⭐".repeat(card.rating)}
                              </span>
                              <div className="price-container">
                                {card.discountPrice === card.quotePrice ? (
                                  <>
                                    <p
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      MRP
                                    </p>
                                    <p
                                      style={{
                                        color: "#e42727",
                                        fontWeight: 700,
                                        fontSize: "20px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faIndianRupeeSign}
                                      />
                                      {card.discountPrice}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        color: "#e42727",
                                        fontWeight: 700,
                                        fontSize: "20px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faIndianRupeeSign}
                                      />
                                      {card.discountPrice}
                                    </p>
                                    <p
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      MRP
                                    </p>
                                    <p
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faIndianRupeeSign}
                                      />
                                      {card.quotePrice}
                                    </p>
                                  </>
                                )}
                              </div>

                              <div className="cart-buttons">
                                {isPopupVisible && popupProduct && (
                                  <CartPopup
                                    product={popupProduct}
                                    onCheckout={navigateToCheckout}
                                    onClose={onClose}
                                    quantity={1}
                                  />
                                )}
                              </div>
                              <div
                                className="cart-class"
                                onClick={() => {
                                  handleAddToCart(card);
                                }}
                              >
                                <button>
                                  <FontAwesomeIcon icon={faCartShopping} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              )}

              <div className="Scroll-cards">
                <button
                  className="left-scroll"
                  onClick={prev}
                  disabled={currentIndex === 0}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </button>
                <p>{currentIndex + 1}</p>
                <button
                  className="right-scroll"
                  onClick={next}
                  disabled={currentIndex >= products.length - cardsToShow}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Navbar isHomePage={false} />
    </>
  );
}

export default Shop;
