

import React,{useState,useEffect, useContext} from 'react'
import '../Styles/Carousal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencil,faIndianRupeeSign,faCartShopping,faArrowLeftLong,faArrowRightLong} 
from '@fortawesome/free-solid-svg-icons'
import { useCart } from '../CartContext';
import CartPopup from './CartPopup';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import AlertPopup from './AlertPopup';

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

const Carousel = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIndex, setFadeIndex] = useState(null); // Track the fading card
  const [quantity,setQuantity]=useState(1)
  const cardsToShow = 4;
  const [addedToCart,setAddedToCart]=useState(false)
  const {cart,addToCart}=useCart()
  const [isPopupVisible,setIsPopupVisible]=useState(false)
  const [popupProduct, setPopupProduct] = useState(null);
  const [currentProduct,setCurrentProductId]=useState('')
  const {getActiveSaleForProduct}=useContext(AppContext)
  const [alertMessage,setAlertMessage]=useState('')

  const navigate=useNavigate()
  const handleAddToCart = (card) => {

       if(!card.isStockAvailable){
      setAlertMessage(`${card.title} out of stock`)
    }
    else if (card.isStockAvailable){
     addToCart(card, quantity);
     setPopupProduct(card)
     setAddedToCart(true)
     setIsPopupVisible(true)
     
    }

    };
    
     useEffect(() => {
    // Scroll to top when currentIndex changes
    window.scrollTo(0, 0);
    }, [currentProduct]);
  const next = () => {
    if (currentIndex < cards.length - cardsToShow) {
      setFadeIndex(currentIndex + cardsToShow); // Set fade index for the new card
      setCurrentIndex(currentIndex + 1);
      setTimeout(() => setFadeIndex(null), 300); // Reset fade index after fade out
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setFadeIndex(currentIndex-1); // Set fade index for the outgoing card
      setCurrentIndex(currentIndex - 1);
      setTimeout(() => setFadeIndex(null), 300); // Reset fade index after fade out
    }
  };

  const navigateToCheckout=()=>{
      handleAddToCart()
      if(isTokenValid()){
        navigate('/login-checkout')
     }else{
        navigate('/checkout')
     }
    }

    const onClose=()=>{
      setIsPopupVisible(false)
    }

    const handleOnProductClick=(productId)=>{
      navigate(`/shop/${productId}`);
      setCurrentProductId(productId)

    }
   
    const calculateDiscountPercentage = (quotePrice, discountPrice) => {
    if (quotePrice && discountPrice) {
      const discount = ((quotePrice - discountPrice) / quotePrice) * 100;
      return discount > 0 ? discount.toFixed(1) : null; // Limit to 1 decimal
    }
    return null;
  };

  return (
    <div className="shop-carousel-container">
        <h1>Related Products</h1>
        <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
      <div className="cards-container">
        {cards.slice(currentIndex, currentIndex + cardsToShow).map((card, index) => {
          const discountPercentage = calculateDiscountPercentage(card.quotePrice, card.discountPrice);
          const activeSale = getActiveSaleForProduct(card._id);
          return (
          <div 
            key={index} 
            className={`carousel-card ${fadeIndex === currentIndex + index ? 'fade-in' : ''}`} 
            
          >
             {discountPercentage > 0 && (
                  <div className='discount-badge'>{discountPercentage}% off</div>
                )}
            {activeSale && 
              <div className='sale-information'>
                 <p> {activeSale.discountType === "amount" ?`Flat ₹${activeSale.discount} Off` :`${activeSale.discount}% Off`} </p>
                 <p className="sale-min-purchase">Min Purchase: Rs.{activeSale.minPurchase}</p>
                 <p>Coupon:{activeSale.couponCode}</p>
              </div>}
            <img src={card.imageUrl} alt='product' onClick={() => handleOnProductClick(card._id)}/>
             <div className='carousel-card-information'>
            <h5 onClick={() => handleOnProductClick(card._id)}>{card.title}</h5>
            <span className="review-stars" style={{ backgroundColor: 'transparent',borderStyle:'none' }}>{'⭐'.repeat(card.rating)}</span>
            <div className='price-container'>
                 {card.discountPrice===card.quotePrice?<>
                      <p style={{marginRight:'10px',fontSize:'12px'}}>MRP</p>
                      <p style={{color:'#e42727',fontWeight:700,fontSize:'20px'}}><FontAwesomeIcon icon={faIndianRupeeSign}/>{card.discountPrice}</p>
                    
                 </>:<>
                    <p style={{color:'#e42727',fontWeight:700,fontSize:'20px'}}><FontAwesomeIcon icon={faIndianRupeeSign}/>{card.discountPrice}</p>
                    <p style={{marginRight:'10px',fontSize:'12px'}}>MRP</p>
                    <p style={{textDecoration:'line-through'}}> <FontAwesomeIcon icon={faIndianRupeeSign}/>{card.quotePrice}</p>
                    </>}
            </div>
            <div className='cart-class'  onClick={()=>{handleAddToCart(card)}} >
                <button ><FontAwesomeIcon icon={faCartShopping} /></button>
                
            </div>
            </div>
            <div className='cart-buttons'>
            {isPopupVisible && popupProduct && (
                            <CartPopup
                              product={popupProduct}
                              onCheckout={navigateToCheckout}
                              onClose={onClose}
                              quantity={1}
                               />
                              )}
          </div>
          </div>
         
        )})}
      </div>
      

       <div className='Scroll-cards'>
           
         <button className='left-scroll' onClick={prev} disabled={currentIndex === 0} >
              <FontAwesomeIcon icon={faArrowLeftLong} />
              
            </button>
             <button className='right-scroll' onClick={next} disabled={currentIndex >= cards.length - cardsToShow}>
              
              <FontAwesomeIcon icon={faArrowRightLong} />
            
            </button>

          </div>
    </div>
  );
};

export default Carousel