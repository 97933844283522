import React, { useState, useEffect } from "react";
import "../Styles/Signup.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [errors, setErrors] = useState({});
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSingup = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is Required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email";
    }

    if (!password.trim()) {
      errors.password = "Password is required.";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long.";
    } else if (password !== confirmPassword) {
      errors.password = "Passwords not matching";
    }

    if (!/^[06789][0-9]{9,11}$/.test(mobileNumber)) {
      errors.mobileNumber = "Valid mobile number is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/signup`, {
        email: email,
        password: password,
        mobileNumber: mobileNumber,
      });

      if (response.status === 200 || response.status === 201) {
        // Handle successful signup:

        navigate("/login"); // Or display success message
      } else {
        // Handle specific backend errors:
        const { message, errors: backendErrors } = response.data;

        if (backendErrors && Object.keys(backendErrors).length > 0) {
          // Map backend errors to UI error messages:
          for (const field in backendErrors) {
            errors[field] = backendErrors[field];
          }
        } else {
          errors.global = "An error occurred during signup. Please try again.";
        }

        setErrors(errors);
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        setErrors({
          global:
            "Network issue detected. Please check your internet connection and try again.",
        });
      }

      setErrors({
        global:
          error.response?.data?.message ||
          "Something went wrong. Please try again.",
      }); // Set a generic error message
    }

    setIsSubmitting(false);
  };
  return (
    <div className="signup">
      <div className="signup-card">
        <h1>Create Account</h1>
        <form className="signup-form" onSubmit={handleSingup}>
          <input
            id="email"
            type="email"
            required
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {errors.email && <div className="signup-erros">{errors.email}</div>}

          <input
            type="text"
            id="mobileNumber"
            required
            placeholder="Mobile Number"
            value={mobileNumber}
            onChange={(event) => setmobileNumber(event.target.value)}
          />
          {errors.mobileNumber && (
            <div className="signup-erros">{errors.mobileNumber}</div>
          )}

          <input
            id="password"
            type="password"
            required
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <input
            id="confirmPassword"
            type="password"
            required
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          {errors.password && (
            <div className="signup-erros">{errors.password}</div>
          )}

          {errors.global && <div className="signup-erros">{errors.global}</div>}
          <button type="submit" disabled={isSubmitting}>
            Create Account
          </button>
          <div className="signup-navigation">
            <a href="/login">Registered User? Please login here</a>
          </div>
        </form>
      </div>
      <Navbar isHomePage={false} />
    </div>
  );
}

export default Signup;
