import React ,{useContext, useEffect, useState} from 'react'
import { AppContext } from '../AppContext'
import { useParams ,Link} from 'react-router-dom';
import '../Styles/ProductDetails.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCartShopping,faArrowRightLong,faArrowLeftLong,faIndianRupeeSign,faTruckFast,faHeadset} 
from '@fortawesome/free-solid-svg-icons'
import { useCart } from '../CartContext';
import Navbar from '../Components/Navbar'

function ProductDetails() {
   
    const {products}=useContext(AppContext)
    const [product,setProduct]=useState([])
    const [loading,setLoading]=useState(true)
    const [addedToCart,setAddedToCart]=useState(false)
    const [error,setError]=useState(null)
    const {id}=useParams();
    
    const [quantity, setQuantity] = useState(1);
    const { cart,addToCart } = useCart();

    const handleAddToCart = () => {
     addToCart(product, quantity);
     setAddedToCart(true)

    };

    const handleQuantityChange = (change) => {
        setQuantity(prevQuantity => {
            const newQuantity = prevQuantity + change;
            if (newQuantity < 1) return 1;
            if (newQuantity > (product ? product.stock : 1)) return product ? product.stock : 1;
            return newQuantity;
        });
    };
    
     useEffect(() => {
        const getProductDetails = async () => {
            try {
                // Check if products are available in the context
                if (!products || products.length === 0) {
                    // Retrieve from sessionStorage if not available in context
                    const storedProducts = sessionStorage.getItem('products');
                    if (storedProducts) {
                        const parsedProducts = JSON.parse(storedProducts);
                        setProduct(parsedProducts.find(product => product._id === id));
                        setLoading(false);
                    } else {
                        // Handle case where products are not available anywhere
                        setError('Products not available');
                        setLoading(false);
                    }
                } else {
                    // Products available in context
                    const foundProduct = products.find(product => product._id === id);
                    if (foundProduct) {
                        setProduct(foundProduct);
                    } else {
                        setError('Product not found');
                    }
                    setLoading(false);
                }
            } catch (err) {
                setError('An error occurred while fetching product details');
                setLoading(false);
            }
        };

        getProductDetails();
    }, [id, products]);

    
    
    
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }
    
    
    
  return (
    <div className='product-details'>
        <div className='product-intro'>
            <img src={product.imageUrl} alt='product image'/>
            <div className='product-intro-description'>
                <h1>{product.title}</h1>
                <div className='price-details'>
                 <p><FontAwesomeIcon icon={faIndianRupeeSign} />{product.discountPrice}</p>
                 <p style={{textDecoration:'line-through'}}><FontAwesomeIcon icon={faIndianRupeeSign} />{product.quotePrice}</p>
                </div>
                <p>{product.shortDescription}</p>
                <p><b>Brand</b> : {product.brand}</p>
                <p><b>Weight</b> :{product.weight}</p>
                <p><b>Diet Type</b> :{product.dietType}</p>
                <p><b>Package Type</b> :{product.package}</p>
                
                {product.isStockAvailable? (
                    <div className='add-to-cart'>
                        <div className='quantity-control'>
                            <div>
                            <button onClick={() => handleQuantityChange(-1)} disabled={quantity <= 1} className='decrease-quantity'>-</button>
                            </div>
                            <div className='quantity'><p>{quantity}</p></div>
                            <div>
                            <button onClick={() => handleQuantityChange(1) } className='increase-quantity' >+</button>
                            </div>
                        </div>
                        <button onClick={handleAddToCart}  className='add-to-cart-button'>
                             Add to Cart
                        </button>
                        {addedToCart?<div>Product Added to Cart. Click here to Go to Cart <Link to='/cart'><button>Cart</button></Link></div>:''}
                    </div>
                ) : (
                    <div className='stock-unavailable'>
                        <p>Stock Unavailable</p>
                    </div>
                )}
            </div>
        </div>
        <Navbar isHomePage={false}/>
    </div>
  )
}

export default ProductDetails