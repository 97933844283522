// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-blog-form{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    
}


.create-blog-btn {
    padding: 20px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.create-blog-btn:hover {
    background-color: #e42727;
}`, "",{"version":3,"sources":["webpack://./src/Components/Blogs/CreateBlog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;;AAErB;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".create-blog-form{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 110px;\n    \n}\n\n\n.create-blog-btn {\n    padding: 20px;\n    cursor: pointer;\n    font-family: 'Poppins', sans-serif;\n}\n\n.create-blog-btn:hover {\n    background-color: #e42727;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
