import { createContext,useState,useEffect } from "react";
import l1 from './Assets/Home-Page/HERO/1.jpg'
import l2 from './Assets/Home-Page/HERO/2.jpg'
import l3 from './Assets/Home-Page/HERO/3.jpg'
import axios from 'axios'
import l1Mobile from './Assets/Home-Page/png khand gur shakkar/gur png.png'
import l2Mobile from './Assets/Home-Page/png khand gur shakkar/khand png.png'
import l3Mobile from './Assets/Home-Page/png khand gur shakkar/shakkar png.png'

export const AppContext=createContext({
    slides:[],
    currentIndex:(null),
    products:[]

})

const AppProvider=({children})=>{
    const [currentIndex, setCurrentIndex] = useState(1);
    const [products,setProducts]=useState([])
    const [blogs, setBlogs] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [loginStatus,setLoginStatus]=useState(false)
    // const [slides,setSlides]=useState([])
    const [loading,setLoading]=useState(false)
    const [sales,setSales]=useState([])
    const [netWorkError,setNetWorkError]=useState('')
    const [slidesError,setSlidesError]=useState('')
    const [imagesData,setImagesData]=useState({})

     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
    const slides=[
        
    {
        id:3,
        title:'Zero Chemicals, Natural Sweeteners',
        imgSrc:l3,
        mobileImgSrc:l3Mobile,
        description:'100% chemical-free, Discover the natural taste of Aryadhenu Shudh Desi Shakkar and bring natural sweetness to your dishes.',
        
    },
    {
        id:1,
        title:'One & Only Substitute to Sugar',
        imgSrc:l1,
        mobileImgSrc:l1Mobile,
        description:'The Healthiest & Sulfur-free Substitute for Sugar. Khand is the smart choice over refined Sugar.',
        
    },
    {
        id:2,
        title:'Pure Sweetness, Pure Energy',
        imgSrc:l2,
        mobileImgSrc:l2Mobile,
        description:'The perfect blend of taste, nutrition, and purity, Add Aryadhenu Shudh Desi Gur to your diet to add nutrition to your body.',
        
    }
    
    
  ]

  // useEffect(()=>{
  //   const fetchSlides=async()=>{
  //      setLoading(true)
  //       try{
  //           const response=await axios.get(`${BACKEND_URL}/slides/get-slides`)
  //           const slidesArray=Object.values(response.data)
  //           setSlides(slidesArray)
           
  //       }catch(err){
  //         if (!navigator.onLine) {
  //               setSlidesError('No internet connection. Please check your network and try again.');
  //           } else {
  //               setSlidesError('Failed to fetch slides. Please try again later.');
  //           }

  //       }
  //       finally{
  //         setLoading(false)
  //       }
  //   }

  //   fetchSlides()
  // },[])

  useEffect(()=>{
        setLoading(true)
        try{
        const fetchProducts=async()=>{
            try{
              const response=await axios.get(`${BACKEND_URL}/getProduct`)

              if(response.status===200 || response.status===201){
                const data=response.data
                setProducts(data)
                sessionStorage.setItem('products',JSON.stringify(data))
            

              }else{
             
              }
            }
            catch(error){
              setNetWorkError('Failed to fetch blogs. Please try again later.')
                
            }
        }
        const storedProducts=sessionStorage.getItem('products')
        if(storedProducts){
          setProducts(JSON.parse(storedProducts))
        }else{
         fetchProducts()
        }}
        catch(error){
          
          setNetWorkError('Failed to fetch blogs. Please try again later.')
        }finally{
          setLoading(false)
        }
        
        
     },[])

     useEffect(()=>{
   const fetchAndSetImagesData = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/get-product-images`);
        const data = response.data;

        // Transform the array into an object with productId as the key
        const imagesDataObj = data.reduce((acc, item) => {
            acc[item.productId] = item.images;
            return acc;
        }, {});

        setImagesData(imagesDataObj);
    } catch (error) {
        console.error('Error fetching product images:', error.response?.data?.message || error.message);
    }
   };

   fetchAndSetImagesData()
    
   },[])

   useEffect(() => {
        const fetchBlogs = async () => {
        try{
          
            const response = await axios.get(`${BACKEND_URL}/get-blogs`);
            setBlogs(response.data);
            // Extract unique categories
            const uniqueCategories = [...new Set(response.data.map(blog => blog.category))];
            setCategories(uniqueCategories);
           
        }
        catch(error){
          
          setNetWorkError('Failed to fetch blogs. Please try again later.')

        }
      }
        fetchBlogs();
        
        
    }, []);

    // ${BACKEND_URL}/api/blogs

   useEffect(() => {
    const fetchFilteredBlogs = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/get-filtered-blogs`, {
                params: { search: searchTerm, category: selectedCategory },
            });
            setBlogs(response.data); // Set filtered blogs
            
        } catch (error) {
           
            setNetWorkError('Failed to fetch blogs. Please try again later.'); // Update error state with a user-friendly message
        }
    };

    fetchFilteredBlogs(); // Call the async function
}, [searchTerm, selectedCategory]);

    useEffect(()=>{
      const fetchSales=async()=>{
        try{
        const response=await axios.get(`${BACKEND_URL}/sale/get-active-sales`)
        
        if(response.data){
          setSales(response.data)
        }
      }catch(error){
        setNetWorkError('Failed to fetch blogs. Please try again later.')

      }
      }

      fetchSales()
    },[])

    

    const getActiveSaleForProduct = (productId) => {
    
    
    const activeProductSale =  sales.find(
      (sale) =>
        sale.type === "product" &&
        sale.productId === productId &&
        new Date(sale.startDate) <= new Date() &&
        new Date(sale.endDate) >= new Date()
    )
    
    

    // Check for any global sale
    const activeGlobalSale = sales.find(
      (sale) =>
        sale.type === "global" &&
        new Date(sale.startDate) <= new Date() &&
        new Date(sale.endDate) >= new Date()
    );

    // Return either product-specific sale or global sale if applicable
    
    return activeProductSale || activeGlobalSale;
  };

  const value={slides,currentIndex,setCurrentIndex,products,setProducts,blogs,setBlogs,filteredBlogs,setFilteredBlogs,
    searchTerm,setSearchTerm,selectedCategory,setSelectedCategory,setCategories,categories,loginStatus,setLoginStatus,
    loading,sales,getActiveSaleForProduct,netWorkError,slidesError,imagesData
  }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider