import React, { useState, useEffect } from "react";

import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import AlertPopup from "./AlertPopup";
import "../Styles/BusinessEnquiry.css";

function SendBusinessEnquiry() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [subjects, setSubjects] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isValidation, setIsValidation] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);

    return () => clearTimeout(timer);
  }, [navigate]);

  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = "Please enter name";
    }

    if (!email) {
      errors.email = "Please enter email";
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!mobile) {
      errors.mobile = "Please enter mobile";
    } else if (!/^(0?[6-9][0-9]{9})$/.test(mobile)) {
      errors.mobile =
        "Phone number should be 10 digits, start with 6-9, and may have an optional leading zero";
    }
    if (!message) {
      errors.message = "Please enter message";
    }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      setErrors(errors);
      return false;
    }
  };

  const handleSubmit = async () => {
    const isValidFormSubmission = validateForm();

    const body = {
      name: name,
      email: email,

      mobileNo: mobile,
      message: message,
    };

    if (isValidFormSubmission) {
      try {
        const response = await axios.post(
          `${BACKEND_URL}/send-business-enquiry`,
          body
        );

        if (response.status === 200) {
          setAlertMessage("Message sent successfully. Thanks for reaching us.");
          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
          setErrors({});
        } else {
          setAlertMessage("Sorry!! Could not send your message.");
        }
      } catch (err) {
        setAlertMessage("Failed to submit form.");
      }
    } else {
      setAlertMessage("Please fill all the form fields.");
    }
  };

  return (
    <div className="contact-container">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <AlertPopup
            message={alertMessage}
            onClose={() => setAlertMessage("")}
          />
          <div className="page-info">
            <h1>Business Enquiry</h1>
            <div className="page-info-navigation">
              <div
                className="page-info-navigation-icons"
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
              </div>
            </div>
          </div>
          <div className="business-enquiry-container">
            <div className="business-enquiry-form">
              <h5>Get in touch</h5>
              <h1>Write Us A Message</h1>
              <div className="business-enquiry-form-input-fields">
                <div className="business-enquiry-input">
                  <label htmlFor="name">
                    Your Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {errors.name ? (
                    <div className="error-message">{errors.name}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="business-enquiry-form-input-fields">
                <div className="business-enquiry-input">
                  <label htmlFor="name">
                    Your Email<span>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {errors.email ? (
                    <div className="error-message">{errors.email}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="business-enquiry-input">
                  <label htmlFor="name">
                    Your Phone <span>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                  />
                  {errors.mobile ? (
                    <div className="error-message">{errors.mobile}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="business-enquiry-form-input-fields">
                <div className="business-enquiry-input">
                  <label htmlFor="name">
                    Your Message<span>*</span>
                  </label>
                  <textarea
                    type="text"
                    required
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  {errors.message ? (
                    <div className="error-message">{errors.message}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button onClick={handleSubmit}>SEND MESSAGE</button>
            </div>
          </div>
        </>
      )}

      <Navbar isHomePage={false} />
    </div>
  );
}

export default SendBusinessEnquiry;
