import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { AppContext } from "../AppContext";
import axios from "axios";
import "../Styles/ShopProducts.css";
import Navbar from "../Components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faIndianRupeeSign,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitch,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useCart } from "../CartContext";
import Carousel from "./Carousal";
import dummyImage from "../Assets/user-dummy.png";

import { useNavigate, useParams } from "react-router-dom";
import CartPopup from "./CartPopup";

import ProductCarousel from "./ProductCarousel";
import ShareComponent from "../Components/ShareComponent";
import { Helmet } from "react-helmet";
import AlertPopup from "./AlertPopup";

import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

const isTokenValid = () => {
  const token = localStorage.getItem("token");
  const expiry = localStorage.getItem("tokenExpiry");
  return token && new Date().getTime() < expiry;
};

function ShopProducts() {
  const [messages, setMessages] = useState({});
  const { products, setProducts, imagesData, getActiveSaleForProduct } =
    useContext(AppContext);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();
  const [isDescriptionActive, setIsDescriptionActive] = useState(true);
  const [isReviewActive, setIsReviewActive] = useState(false);
  const [isSpecificationActive, setIsSpecificationActive] = useState(false);
  const [reviews, setReviews] = useState([]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { productId } = useParams(); // Get productId from URL
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [imageData, setImageData] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [currentReviewPage, setCurrentReviewPage] = useState(1);
  const [reviewsPerPage, setReviewsPerPage] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(true);
  const [currentReviews, setCurrentReviews] = useState([]);
  const [paginatedReviews, setPaginatedReviews] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [alertMessage, setAlertMessage] = useState("");
  const email = localStorage.getItem("email");

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/getProduct`);
        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          // setProducts(data);

          // Find the current product based on productId from URL
          const foundProduct =
            products.find((product) => product._id === productId) ||
            data.find((product) => product._id === productId);
          setCurrentProduct(foundProduct);

          if (foundProduct) {
            const relatedImages = imagesData[foundProduct._id] || [];

            const imagesWithCurrent = [foundProduct.imageUrl, ...relatedImages];

            setImageData(imagesWithCurrent);
          }
        } else {
          setMessages({ error: response.message });
        }
      } catch (error) {
        setMessages({ error: { error } });
      }
    };

    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      const parsedProducts = JSON.parse(storedProducts);
      setProducts(parsedProducts);
      const foundProduct = parsedProducts.find(
        (product) => product._id === productId
      );
      setCurrentProduct(foundProduct); // Fallback to first product if not found

      if (foundProduct) {
        const relatedImages = imagesData[foundProduct._id] || [];

        const imagesWithCurrent = [foundProduct.imageUrl, ...relatedImages];

        setImageData(imagesWithCurrent);
      }
    } else {
      fetchProducts();
    }
    // const discountPercentage=calculateDiscountPercentage()
    // setDiscountPercentage(discountPercentage)
  }, [productId, setProducts]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => {
      const nextPage = Math.min(prevPage + 1, products.length - 1);
      const nextProduct = products[nextPage];
      setCurrentProduct(nextProduct);
      navigate(`/shop/${nextProduct._id}`); // Navigate to the product detail page
      return nextPage;
    });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => {
      const prevPageIndex = Math.max(prevPage - 1, 0);
      const prevProduct = products[prevPageIndex];
      setCurrentProduct(prevProduct);
      navigate(`/shop/${prevProduct._id}`); // Navigate to the product detail page
      return prevPageIndex;
    });
  };

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/fetch-reviews`);
        setReviews(response.data);
      } catch (err) {
      } finally {
        // setIsLoading(false)
      }
    };

    fetchReviews();
  }, [productId]);

  const topReviews = useMemo(() => {
    return reviews
      .filter((review) => review.productId === productId)
      .sort((a, b) => new Date(b.reviewDate) - new Date(a.reviewDate));
  }, [reviews, productId]);

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      if (newQuantity < 1) return 1;
      if (newQuantity > (currentProduct ? currentProduct.stock : 1))
        return currentProduct ? currentProduct.stock : 1;
      return newQuantity;
    });
  };

  const handleAddToCart = () => {
    // if (currentProduct) {
    //     addToCart(currentProduct, quantity);
    //     setIsPopupVisible(true);
    // }

    if (!currentProduct.isStockAvailable) {
      setAlertMessage(`${currentProduct.title} out of stock`);
    } else if (currentProduct.isStockAvailable) {
      addToCart(currentProduct, quantity);

      setIsPopupVisible(true);
    }
  };

  const relatedProducts = products.filter(
    (product) => product._id !== currentProduct?._id
  );

  const navigateToCheckout = () => {
    handleAddToCart();
    if (isTokenValid()) {
      navigate("/login-checkout");
    } else {
      navigate("/checkout");
    }
  };

  const onClose = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    if (
      currentProduct &&
      currentProduct.quotePrice &&
      currentProduct.discountPrice
    ) {
      const discount =
        ((currentProduct.quotePrice - currentProduct.discountPrice) /
          currentProduct.quotePrice) *
        100;
      setDiscountPercentage(discount > 0 ? discount.toFixed(1) : null);
    } else {
      setDiscountPercentage(null); // Reset if data is missing
    }
  }, [currentProduct]);

  const totalPages = Math.ceil(topReviews.length / reviewsPerPage);

  const handleWriteReview = async () => {
    try {
      // Send a request to validate review eligibility
      const response = await axios.post(
        `${BACKEND_URL}/validate-review-eligibility`,
        {
          email,
          productId,
        }
      );

      if (response.data.eligible) {
        // Redirect to the review page if the user is eligible
        navigate(`/product/${productId}/review`, { state: { topReviews } });
      } else {
        // Set an alert message if eligibility fails
        setAlertMessage(
          response.data?.message ||
            "You must have ordered this product before reviewing or you have already reviewed it."
        );
      }
    } catch (error) {
      // Handle errors gracefully
      setAlertMessage(
        error.response?.data?.message ||
          "An error occurred while validating review eligibility. Please try again later."
      );
    }
  };

  // const handleReviewNext = () => {

  //     setStart(start+3)
  //     setEnd(end+3)
  // };

  // const handleReviewPrevious = () => {

  //       setStart(start-3)
  //       setEnd(end-3)
  // };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setReviewsPerPage(isMobile ? 1 : 3);
      setStart(0);
      setEnd(isMobile ? 1 : 3);
    };

    handleResize(); // Call on mount
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize); // Clean up listener on unmount
  }, []);

  const handleReviewNext = () => {
    const increment = window.innerWidth < 768 ? 1 : 3;
    const nextStart = start + increment;
    const nextEnd = end + increment;
    if (nextStart < topReviews.length) {
      setStart(nextStart);
      setEnd(nextEnd);
    }
  };

  const handleReviewPrevious = () => {
    const decrement = window.innerWidth < 768 ? 1 : 3;
    const prevStart = Math.max(0, start - decrement);
    const prevEnd = Math.max(reviewsPerPage, end - decrement);
    setStart(prevStart);
    setEnd(prevEnd);
  };

  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [isZoomed, setIsZoomed] = useState(false);
  const imageContainerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!isZoomed) return;

    const { left, top, width, height } =
      imageContainerRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const handleMouseEnter = () => setIsZoomed(true);
  const handleMouseLeave = () => setIsZoomed(false);

  const pageUrl = window.location.href;
  const title = currentProduct ? currentProduct.title : "";

  const imageUrl = currentProduct ? currentProduct.imageUrl : "";

  useEffect(() => {
    if (currentProduct && reviews) {
      setIsLoading(false);
    }
  }, [currentProduct, reviews]);

  const activeSale =
    currentProduct && getActiveSaleForProduct(currentProduct._id);

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Helmet>
            <meta charset="UTF-8"></meta>
            <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
            ></meta>
            <meta name="author" content="" />
            <meta name="robots" content=""></meta>
            <link rel="canonical" href=""></link>
            <title>{title} - Discover this amazing product!</title>
            <meta property="og:title" content={title} />

            <meta property="og:image" content={imageUrl} />
            <meta property="og:url" content={pageUrl} />

            <meta name="twitter:title" content={title} />

            <meta name="twitter:image" content={imageUrl} />
          </Helmet>
          <div className="product-container">
            <AlertPopup
              message={alertMessage}
              onClose={() => setAlertMessage("")}
            />
            <div className="mobile-navigation-buttons">
              <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                &lt;
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === products.length - 1}
              >
                &gt;
              </button>
            </div>
            <div className="product-image">
              {/* {currentProduct &&(
                                <img src={currentProduct.imageUrl} alt='product '/>

                                 
                            )} */}
              {activeSale && activeSale.isPromotional === false && (
                <div className="sale-information">
                  <p>
                    {" "}
                    {activeSale.discountType === "amount"
                      ? `Flat ₹${activeSale.discount} Off`
                      : `${activeSale.discount}% Off`}{" "}
                  </p>
                  <p className="sale-min-purchase">
                    Min Purchase: Rs.{activeSale.minPurchase}
                  </p>
                  <p>Coupon:{activeSale.couponCode}</p>
                </div>
              )}

              {imageData.length > 0 ? (
                <ProductCarousel images={imageData} /> // Use your Carousel component here
              ) : (
                currentProduct && (
                  // // <img src={currentProduct?.imageUrl || dummyImage} alt='product' />
                  <div
                    className="zoomable-image-container"
                    ref={imageContainerRef}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src={currentProduct?.imageUrl}
                      alt=""
                      style={{
                        transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
                        transform: isZoomed ? `scale(${3})` : "scale(1)",
                      }}
                    />
                  </div>
                )
              )}
            </div>
            <div className="product-details-container">
              {currentProduct && (
                <div>
                  <h3>{currentProduct.title} </h3>

                  <p>{currentProduct.shortDescription}</p>
                  <div className="write-review">
                    <p
                      onClick={handleWriteReview}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={faPencil} /> Write a Review
                    </p>
                  </div>
                  <div className="price-container">
                    {currentProduct.discountPrice ===
                    currentProduct.quotePrice ? (
                      <>
                        <p style={{ marginRight: "10px", fontSize: "12px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            color: "#E42727",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          <span>
                            {discountPercentage && -discountPercentage}
                            {discountPercentage && "%"}
                          </span>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {currentProduct.discountPrice}
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          style={{
                            color: "#E42727",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          <span>
                            {discountPercentage && -discountPercentage}
                            {discountPercentage && "%"}
                          </span>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {currentProduct.discountPrice}
                        </p>
                        <p style={{ marginRight: "10px", fontSize: "12px" }}>
                          MRP
                        </p>
                        <p style={{ textDecoration: "line-through" }}>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {currentProduct.quotePrice}
                        </p>
                      </>
                    )}
                  </div>
                  <p className="taxes-info">Inclusive of all Taxes</p>

                  <span
                    className="rating-stars"
                    style={{ textAlign: "left", display: "block" }}
                  >
                    {currentProduct.rating !== 0
                      ? Math.ceil(currentProduct.rating)
                      : ""}
                    {"⭐".repeat(currentProduct.rating)}
                  </span>
                  <div className="additional-details">
                    <h5>Brand :</h5>
                    <p>{currentProduct.brand}</p>
                  </div>
                  <div className="additional-details">
                    <h5>Category :</h5>
                    <p>{currentProduct.category}</p>
                  </div>
                  <div className="additional-details">
                    <h5>Diet Type :</h5>
                    <p>{currentProduct.dietType}</p>
                  </div>
                  <div className="additional-details">
                    <h5>Weight :</h5>
                    <p>{currentProduct.weight}</p>
                  </div>
                  <div className="additional-details">
                    <h5>Package Type :</h5>
                    <p>{currentProduct.package}</p>
                  </div>
                  <div className="additional-details">
                    <h5>In-Stock :</h5>
                    <p>
                      {currentProduct && currentProduct.isStockAvailable
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                  <div className="additional-details">
                    {/* <h5>Share :</h5>
                                        <p>
                                        <button className="share-button"  >
                                            <FontAwesomeIcon icon={faFacebook} /> 
                                        </button>
                                        <button className='share-button'>
                                            <FontAwesomeIcon icon={faTwitch}/>
                                        </button>
                                        <button className='share-button'>
                                            <FontAwesomeIcon icon={faWhatsapp} />
                                        </button>
                                        </p> */}
                    {pageUrl && title && imageUrl && (
                      <ShareComponent
                        url={pageUrl}
                        title={title}
                        imageUrl={imageUrl}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="cart-container">
                <h3>Quantity</h3>
                <div className="cart-quantity-control">
                  <button
                    onClick={() => handleQuantityChange(-1)}
                    disabled={quantity <= 1}
                  >
                    -
                  </button>
                  <p>{quantity}</p>
                  <button onClick={() => handleQuantityChange(1)}>+</button>
                </div>
                <div className="cart-buttons">
                  <button onClick={handleAddToCart} className="cart-button">
                    <FontAwesomeIcon
                      icon={faCartShopping}
                      style={{ color: "#ffffff" }}
                    />{" "}
                    Add to Cart
                  </button>

                  {isPopupVisible && (
                    <CartPopup
                      product={currentProduct}
                      onCheckout={navigateToCheckout}
                      onClose={onClose}
                      quantity={quantity}
                    />
                  )}
                  <button className="buy-button" onClick={navigateToCheckout}>
                    Buy it now
                  </button>
                </div>
              </div>
            </div>
            <div className="navigation-buttons">
              <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                &lt;
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === products.length - 1}
              >
                &gt;
              </button>
            </div>
          </div>
          <div className="description-reviews-container">
            <div className="description-review-options">
              <h3
                className={isDescriptionActive ? "description-active" : ""}
                onClick={() => {
                  setIsDescriptionActive(true);
                  setIsReviewActive(false);
                  setIsSpecificationActive(false);
                }}
              >
                Description
              </h3>
              <h3
                className={isReviewActive ? "review-active" : ""}
                onClick={() => {
                  setIsDescriptionActive(false);
                  setIsReviewActive(true);
                  setIsSpecificationActive(false);
                }}
              >
                Reviews
              </h3>
              <h3
                className={isSpecificationActive ? "specification-active" : ""}
                onClick={() => {
                  setIsDescriptionActive(false);
                  setIsReviewActive(false);
                  setIsSpecificationActive(true);
                }}
              >
                Specifications
              </h3>
            </div>
            <div className="description-content">
              {isDescriptionActive && currentProduct && (
                <>
                  {" "}
                  {currentProduct.description &&
                  currentProduct.description.length > 0 ? (
                    currentProduct.description.map((description, index) => (
                      <p key={index}>{description}</p>
                    ))
                  ) : (
                    <p>No description </p>
                  )}
                </>
              )}
            </div>
            <div className="reviews-content">
              {isReviewActive && topReviews && (
                <>
                  {topReviews.length > 0 ? (
                    topReviews.slice(start, end).map((review) => (
                      <div className="reviews-card" key={review._id}>
                        <div className="review-image">
                          <img
                            src={review.userPicture || dummyImage}
                            alt="review user"
                          />
                        </div>
                        <div className="review-content">
                          {/* <span className="review-stars" style={{ backgroundColor: 'transparent',borderStyle:'none' }}>{'⭐'.repeat(review.stars)}</span> */}
                          <span className="review-stars">
                            {Array(review.stars).fill("⭐").join("")}
                          </span>

                          <p>{review.username}</p>
                          <p>{review.reviewDescription}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No reviews</p>
                  )}
                </>
              )}
            </div>
            <div className="description-content">
              {isSpecificationActive && currentProduct && (
                <>
                  {" "}
                  {currentProduct.specifications &&
                  currentProduct.specifications.length > 0 ? (
                    currentProduct.specifications.map(
                      (specification, index) => (
                        <p key={index}>{specification}</p>
                      )
                    )
                  ) : (
                    <p>No specifications </p>
                  )}
                </>
              )}
            </div>
            {isReviewActive && totalPages > 1 && (
              <div className="scroll-cards">
                <button
                  className="left-scroll"
                  onClick={handleReviewPrevious}
                  disabled={start === 0}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </button>
                <button
                  className="right-scroll"
                  onClick={handleReviewNext}
                  disabled={end >= topReviews.length}
                >
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </button>
              </div>
            )}
          </div>
          <Carousel cards={relatedProducts} title="Related Products" />
        </>
      )}

      <Navbar />
    </div>
  );
}

export default ShopProducts;
