//

import React, { useState } from "react";
import "../Styles/Apply.css";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertPopup from "./AlertPopup";

function Apply() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    position: "",
    qualification: "",
    message: "",
    resume: null,
  });
  const [alertMessage, setAlertMessage] = useState("");

  const [errors, setErrors] = useState({
    email: "",
    mobileNo: "",
  });

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate email and mobile number on change
    if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailPattern.test(value) ? "" : "Invalid email address",
      }));
    }

    if (name === "mobileNo") {
      const mobilePattern = /^[6-9]\d{9}$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNo: mobilePattern.test(value)
          ? ""
          : "Invalid mobile number (10 digits starting with 6-9)",
      }));
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Final validation check before submission
    if (errors.email || errors.mobileNo) {
      setAlertMessage("Please fix the validation errors before submitting.");
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/applications/apply`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        setAlertMessage("Successfully applied to Job");
        navigate("/careers");
      } else {
        setAlertMessage("Failed to apply");
      }
    } catch (error) {}
  };

  return (
    <>
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage("")} />
      <div className="apply-job-container">
        <h2>Job Application</h2>
        <form onSubmit={handleSubmit}>
          <div className="job-form">
            <label>
              Name: <b>*</b>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="job-form">
            <label>
              Email: <b>*</b>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="job-form">
            <label>
              Mobile No: <b>*</b>
            </label>
            <input
              type="text"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              required
            />
            {errors.mobileNo && (
              <p className="error-message">{errors.mobileNo}</p>
            )}
          </div>
          <div className="job-form">
            <label>
              Position: <b>*</b>
            </label>
            <input
              type="text"
              name="position"
              value={formData.position}
              onChange={handleChange}
              required
            />
          </div>
          <div className="job-form">
            <label>Qualification</label>
            <input
              type="text"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              required
            />
          </div>
          <div className="job-form">
            <label>
              Message: <b>*</b>
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <div className="job-form">
            <label>
              Resume: <b>*</b>
            </label>
            <input
              type="file"
              name="resume"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <Navbar isHomePage={false} />
    </>
  );
}

export default Apply;
