// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-sample{
    padding-top: 0px;
   
}

.hero-sample img{
    width:100vw;
    height:100vh;
    flex-shrink: 0;
    margin-left: -20px;

    
    
}

@media screen and (max-width:768px){
   
    .hero-sample img{
        width: 90%;
            height: 60%;
            flex-shrink: 0;
            margin-left: -40px;
            margin-left: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/Hero.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;AAEpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,kBAAkB;;;;AAItB;;AAEA;;IAEI;QACI,UAAU;YACN,WAAW;YACX,cAAc;YACd,kBAAkB;YAClB,gBAAgB;IACxB;AACJ","sourcesContent":[".hero-sample{\n    padding-top: 0px;\n   \n}\n\n.hero-sample img{\n    width:100vw;\n    height:100vh;\n    flex-shrink: 0;\n    margin-left: -20px;\n\n    \n    \n}\n\n@media screen and (max-width:768px){\n   \n    .hero-sample img{\n        width: 90%;\n            height: 60%;\n            flex-shrink: 0;\n            margin-left: -40px;\n            margin-left: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
