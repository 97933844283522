import { createContext, useState, useEffect } from "react";
import l1 from "./Assets/Home-Page/HERO/1.jpg";
import l2 from "./Assets/Home-Page/HERO/2.jpg";
import l3 from "./Assets/Home-Page/HERO/3.jpg";
import axios from "axios";
import l1Mobile from "./Assets/Home-Page/png khand gur shakkar/gur png.png";
import l2Mobile from "./Assets/Home-Page/png khand gur shakkar/khand png.png";
import l3Mobile from "./Assets/Home-Page/png khand gur shakkar/shakkar png.png";

export const AppContext = createContext({
  slides: [],
  currentIndex: null,
  products: [],
});

const AppProvider = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [products, setProducts] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [loginStatus, setLoginStatus] = useState(false);
  // const [slides,setSlides]=useState([])
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [netWorkError, setNetWorkError] = useState("");
  const [slidesError, setSlidesError] = useState("");
  const [imagesData, setImagesData] = useState({});
  const [productsLoading, setProductsLoading] = useState(true);
  const [blogsLoading, setBlogsLoading] = useState(true);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const slides = [
    {
      id: 3,
      title: "Zero Chemicals-Natural Desi Shakkar",
      imgSrc: l3,
      mobileImgSrc: l3Mobile,
      description:
        "Discover the natural taste of 100% chemical-free Aryadhenu Cane Jaggery Powder and bring natural sweetness to your chai, coffee and dishes.",
    },
    {
      id: 1,
      title: "One & Only Substitute to Sugar",
      imgSrc: l1,
      mobileImgSrc: l1Mobile,
      description:
        "The Healthier & Sulfur-Free Substitute for White Sugar, Raw Khandsari Sugar is the smart choice over Refined Sugar!",
    },
    {
      id: 2,
      title: "Pure Sweetness, Pure Energy",
      imgSrc: l2,
      mobileImgSrc: l2Mobile,
      description:
        "Aryadhenu cane jaggery is the perfect blend of taste, flavour, nutrition, and purity! Add it to your daily diet to boost your body's nutrition and immunity.",
    },
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      setProductsLoading(true);
      try {
        const response = await axios.get(`${BACKEND_URL}/getProduct`);

        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          setProducts(data);
          sessionStorage.setItem("products", JSON.stringify(data));
        } else {
        }
      } catch (error) {
        setNetWorkError("Failed to fetch blogs. Please try again later.");
      } finally {
        setProductsLoading(false);
      }
    };
    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      setProducts(JSON.parse(storedProducts));
      setProductsLoading(false);
    } else {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    const fetchAndSetImagesData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/get-product-images`);
        const data = response.data;

        // Transform the array into an object with productId as the key
        const imagesDataObj = data.reduce((acc, item) => {
          acc[item.productId] = item.images;
          return acc;
        }, {});

        setImagesData(imagesDataObj);
      } catch (error) {
        console.error(
          "Error fetching product images:",
          error.response?.data?.message || error.message
        );
      }
    };

    fetchAndSetImagesData();
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/get-blogs`);
        setBlogs(response.data);
        // Extract unique categories
        const uniqueCategories = [
          ...new Set(response.data.map((blog) => blog.category)),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        setNetWorkError("Failed to fetch blogs. Please try again later.");
      } finally {
        setBlogsLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  // ${BACKEND_URL}/api/blogs

  useEffect(() => {
    const fetchFilteredBlogs = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/get-filtered-blogs`, {
          params: { search: searchTerm, category: selectedCategory },
        });
        setBlogs(response.data); // Set filtered blogs
      } catch (error) {
        setNetWorkError("Failed to fetch blogs. Please try again later."); // Update error state with a user-friendly message
      } finally {
        setBlogsLoading(false);
      }
    };

    fetchFilteredBlogs(); // Call the async function
  }, [searchTerm, selectedCategory]);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/sale/get-active-sales`
        );

        if (response.data) {
          setSales(response.data);
        }
      } catch (error) {
        setNetWorkError("Failed to fetch blogs. Please try again later.");
      }
    };

    fetchSales();
  }, []);

  const getActiveSaleForProduct = (productId) => {
    const activeProductSale = sales.find(
      (sale) =>
        sale.type === "product" &&
        sale.isPromotional === false &&
        sale.productIds.includes(productId) &&
        new Date(sale.startDate) <= new Date() &&
        new Date(sale.endDate) >= new Date()
    );

    // Check for any global sale
    const activeGlobalSale = sales.find(
      (sale) =>
        sale.type === "global" &&
        sale.isPromotional === false &&
        new Date(sale.startDate) <= new Date() &&
        new Date(sale.endDate) >= new Date()
    );

    // Return either product-specific sale or global sale if applicable

    return activeProductSale || activeGlobalSale;
  };

  const value = {
    slides,
    currentIndex,
    setCurrentIndex,
    products,
    setProducts,
    blogs,
    setBlogs,
    filteredBlogs,
    setFilteredBlogs,
    searchTerm,
    setSearchTerm,
    selectedCategory,
    setSelectedCategory,
    setCategories,
    categories,
    loginStatus,
    setLoginStatus,
    loading,
    sales,
    getActiveSaleForProduct,
    netWorkError,
    slidesError,
    imagesData,
    setNetWorkError,
    productsLoading,
    setProductsLoading,
    blogsLoading,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
