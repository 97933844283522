
import './App.css';

import Navbar from './Components/Navbar';
import AppProvider from './AppContext'

import React,{useEffect} from 'react';
import Home from './Components/Home/Home';
import Footer from './Components/Footer';
import { BrowserRouter as Router,Routes,Route,useLocation} from 'react-router-dom';
import Signup from './Components/Signup';
import Login from './Components/Login';
import AddProducts from './Components/AddProducts';
import Shop from './Components/Shop';
import ProductDetails from './Components/productDetails'
import { CartProvider, useProvider } from './CartContext';
import Cart from './Components/Cart';
import DistributorForm from './Components/DistributorForm';
import { OrderProvider } from './OrderContext';
import Checkout from './Components/Checkout';
import ShopProducts from './Components/ShopProducts';
import About from './Components/About';
import Contact from './Components/Contact';
import ShippingPolicy from './Components/ShippingPolicy';
import ReturnPolicy from './Components/ReturnPolicy';
import TermsandConditions from './Components/TermsandConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Careers from './Components/Careers';
import Apply from './Components/Apply';
import BlogForm from './Components/Blogs/BlogForm';
import BlogList from './Components/Blogs/BlogList';
import BlogItem from './Components/Blogs/BlogItem';
import UpdateProductForm from './Components/UpdateProductForm';
import UserDashboard from './Components/UserDashboard';
import ProtectedRoute from './Components/ProtectedRoute';
import ReviewPopup from './Components/ReviewPopup';
import Form from './Components/Distributor/Form'
import Form2 from './Components/Distributor/Form2'
import Form3 from './Components/Distributor/Form3'
import Form4 from './Components/Distributor/Form4'
import AdminPage from './Components/Distributor/AdminPage';
import SuccessPage from './Components/Distributor/SuccessPage'
import ErrorPage from './Components/Distributor/ErrorPage'
import AdminDashboard from './Components/AdminDashboard';
import ReactGA from 'react-ga4';
import Logout from './Components/Logout';

import LoggedInCheckout from './Components/LoggedInCheckout';

import RequestResetForm from './Components/RequestResetForm';
import ResetPasswordForm from './Components/ResetPasswordForm';
import RichTextEditor from './Components/TextEditor';
import { Navigate } from 'react-router-dom';
import BloggerEditor from './Components/Blogs/BloggerEditor';
import CreateBlog from './Components/Blogs/CreateBlog';
import ProductImageForm from './Components/ProductImageForm';
import CustomReactQuillEditor from './Components/Blogs/ReactQuillEditor';
import SendBusinessEnquiry from './Components/SendBusinessEnquiry';


function App() {
 
  return (
    <AppProvider>
      <CartProvider>
        <OrderProvider>
          <Router>
        <MainRoutes/>
        </Router>
    </OrderProvider>
    </CartProvider>
    </AppProvider>
  );
}


function MainRoutes() {
  const location = useLocation();
  const measurementID=process.env.REACT_APP_GA_MEASUREMENT_ID

  useEffect(() => {
        ReactGA.initialize(measurementID);
    }, []);

    useEffect(() => {
        // Send pageview when the route changes
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

   

    useEffect(() => {
        const getUTMParameters = () => {
            const urlParams = new URLSearchParams(window.location.search);
            return {
                utm_source: urlParams.get('utm_source') || '',
                utm_medium: urlParams.get('utm_medium') || '',
                utm_campaign: urlParams.get('utm_campaign') || '',
                utm_term: urlParams.get('utm_term') || '',
                utm_content: urlParams.get('utm_content') || '',
                referrer: document.referrer || 'Direct', // 'Direct' if no referrer
            };
        };

        // Get existing traffic data from localStorage (if any)
        const existingTrafficData = localStorage.getItem('trafficData')
            ? JSON.parse(localStorage.getItem('trafficData'))
            : {};

        // Get the latest UTM parameters and referrer
        const newTrafficData = getUTMParameters();

        // Merge existing traffic data with the new traffic data (new data takes priority)
        const trafficData = {
            ...existingTrafficData,
            ...newTrafficData,
        };

        // Save the updated traffic data back to localStorage
        localStorage.setItem('trafficData', JSON.stringify(trafficData));
        

    }, [location]);

  const distributorRoutes = [
    '/distributor',
    '/distributor/candf-agents',
    '/distributor/super-stockist',
    '/distributor/dealer',
    '/distributor/candf-agents-only',
    '/distributor/admin',
    '/distributor/login',
    '/distributor/success',
    '/distributor/error'
  ];

  const isDistributorRoute = distributorRoutes.some(route => location.pathname.startsWith(route));

   const validRoutes = [
        '/',
        '/signup',
        '/login',
        '/addproducts',
        '/update-products',
        '/shop',
        '/cart',
        '/distributor',
        '/checkout',
        '/about',
        '/contact',
        '/shipping-policy',
        '/refund-policy',
        '/terms-and-conditions',
        '/privacy-policy',
        '/careers',
        '/apply',
        '/post-blog',
        '/blogs',
        '/blogs/:id',
        '/user-dashboard',
        '/product/:productId/review',
        '/distributor/candf-agents',
        '/distributor/super-stockist',
        '/distributor/dealer',
        '/distributor/candf-agents-only',
        '/distributor/admin',
        '/distributor/login',
        '/distributor/success',
        '/distributor/error',
        '/admin-dashboard',
        '/logout',
        '/login-checkout',
        '/request-password-rest',
        '/reset-password/:token',
        '/shop/:productId'
    ];

    const isRouteValid = validRoutes.some(route => location.pathname === route);

  return (
    
      <div className="App" >
        <Routes>
          <Route index  element={<Home/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/login' 
          element={<Login/>}
          />
          <Route path='/addproducts' element={<AddProducts/>}/>
          <Route path='/update-products' element={<UpdateProductForm/>}/>
          <Route path='/shop' element={<Shop/>}/>
          {/* <Route path="/shop/:id" element={<ProductDetails/>}/> */}
          <Route path='/cart' element={<Cart/>}></Route>
          <Route path='/distributor' element={<DistributorForm/>}/>
          <Route path='/checkout' element={<Checkout/>}/>
          <Route path='/shop/:productId' element={<ShopProducts/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/shipping-policy' element={<ShippingPolicy/>}/>
          <Route path='/refund-policy' element={<ReturnPolicy/>}/>
          <Route path='/terms-and-conditions' element={<TermsandConditions/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='careers' element={<Careers/>}/>
          <Route path='/apply' element={<Apply/>}/>
          <Route path='/post-blog' element={<BlogForm/>}/>
          <Route path='/blogs' element={<BlogList/>}/>
          <Route path='/blogs/:id' element={<BlogItem/>}/>
          {/* <Route path='/user-dashboard' element={<UserDashboard/>}/> */}
          <Route 
                    path="/user-dashboard" 
                    element={
                        <ProtectedRoute>
                            <UserDashboard />
                        </ProtectedRoute>
                    } 
                />

          <Route path='/product/:productId/review'
                element={
                  <ProtectedRoute>
                    <ReviewPopup/>
                  </ProtectedRoute>
                }
                />

          <Route path='/distributor/candf-agents' element={<Form/>}></Route>
          <Route path='/distributor/super-stockist' element={<Form2/>}/>
          <Route path='/distributor/dealer' element={<Form3/>}/>
          <Route path='/distributor/candf-agents-only' element={<Form4/>}/>
          <Route path='/distributor/admin' element={<AdminPage/>}></Route>
          <Route path='/distributor/login' element={<Login/>}/>
          <Route path='/distributor/success' element={<SuccessPage/>}/>
          <Route path='/distributor/error' element={<ErrorPage/>}/>
          <Route path='/admin-dashboard' 
          element={<ProtectedRoute><AdminDashboard/></ProtectedRoute>}
          />
          <Route path='/logout' element={<ProtectedRoute>
            <Logout/>
          </ProtectedRoute>}
          />
          
          <Route path='/login-checkout' 
          element={<ProtectedRoute><LoggedInCheckout/></ProtectedRoute>}
          />
          <Route path='request-password-rest' element={<RequestResetForm/>}/>
          <Route path='reset-password/:token' element={<ResetPasswordForm/>}/>
          {/* <Route path='/blogger-editor' element={<BloggerEditor/>}/> */}
          <Route path='/create-blog' element={<CreateBlog/>}/>
          <Route path='/business-enquiry' element={<SendBusinessEnquiry/>}/>
          
          

          <Route path="*" element={<Navigate to="/" replace />} />
          
        
          
        </Routes>
        {!isDistributorRoute && < Footer/>}
         
    </div>
  );
}

export default App;
