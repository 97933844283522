


import React from 'react';
import MobileNavbar from './MobileNavbar';
import DesktopNavbar from './DesktopNavbar';

const Navbar = ({isHomePage}) => {
    const isMobile = window.innerWidth < 768;

    return (
        <>
            {isMobile ? <MobileNavbar /> : <DesktopNavbar isHomePage={isHomePage}/>}
        </>
    );
}

export default Navbar;
