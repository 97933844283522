import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../AlertPopup";

function FormPage5({ onPrevious, formData, setFormData }) {
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.numberOfInstitutions) {
      newErrors.numberOfInstitutions = "Please enter number of Institutions";
    }

    if (!formData.orderVolume) {
      newErrors.orderVolume = "Please enter order volume per month";
    }
    if (!formData.godownSize) {
      newErrors.godownSize = "Please enter Godown Size";
    }
    if (!formData.transportFacilities) {
      newErrors.transportFacilities = "Please select a valid response";
    }
    if (!formData.investmentCapacity) {
      newErrors.investmentCapacity = "Please enter investment capacity";
    }
    if (
      formData.transportFacilities === "Yes" &&
      !formData.typeOfTransportFacilities
    ) {
      newErrors.typeOfTransportFacilities = "Please select type of facilities";
    }

    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
  };

  const handleSubmit = async () => {
    const formDataFiles = new FormData();
    formDataFiles.append("shopImage", formData.shopImage);
    formDataFiles.append("shopVideo", formData.shopVideo);

    for (const [key, value] of Object.entries(formData)) {
      formDataFiles.append(key, value);
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_URL}/distributor/submit-form`,
        formDataFiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        navigate("/distributor/success");
      } else {
        setAlertMessage("Form submission failed");
      }
    } catch (err) {
      setAlertMessage("Form submission failed");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="form">
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage("")} />
      <h1>Retailer</h1>
      <div className="text-question">
        <p>
          How many Institutions do you currently serve? <span>*</span>
        </p>
        <input
          type="text"
          name="numberOfInstitutions"
          value={formData.numberOfInstitutions || ""}
          onChange={handleChange}
        ></input>
        {errors.numberOfInstitutions ? (
          <div className="error-message">{errors.numberOfInstitutions}</div>
        ) : (
          ""
        )}
      </div>

      <div className="text-question">
        <p>
          Average order volume per month? <span>*</span>
        </p>
        <input
          type="text"
          name="orderVolume"
          value={formData.orderVolume || ""}
          onChange={handleChange}
        ></input>
        {errors.orderVolume ? (
          <div className="error-message">{errors.orderVolume}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-question">
        <p>
          Godown size (in sq.ft)? <span>*</span>
        </p>
        <input
          type="text"
          name="godownSize"
          value={formData.godownSize || ""}
          onChange={handleChange}
        ></input>
        {errors.godownSize ? (
          <div className="error-message">{errors.godownSize}</div>
        ) : (
          ""
        )}
      </div>
      <div className="radio">
        <p>
          Available Transport facilities? <span>*</span>
        </p>
        <div className="options">
          <div className="option">
            <input
              type="radio"
              name="transportFacilities"
              value="Yes"
              checked={formData.transportFacilities === "Yes"}
              onChange={handleChange}
            />
            <label>Yes</label>
          </div>
          <div className="option">
            <input
              type="radio"
              name="transportFacilities"
              value="No"
              checked={formData.transportFacilities === "No"}
              onChange={handleChange}
            />
            <label>No</label>
          </div>
          {errors.transportFacilities ? (
            <div className="error-message">{errors.transportFacilities}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      {formData.transportFacilities === "Yes" && (
        <div className="radio">
          <p>
            If Yes,What type of transportation do you use? <span>*</span>
          </p>
          <div className="options">
            <div className="option">
              <input
                type="radio"
                name="typeOfTransportFacilities"
                value="Own"
                checked={formData.typeOfTransportFacilities === "Own"}
                onChange={handleChange}
              />
              <label>Own</label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="typeOfTransportFacilities"
                value="Rented"
                checked={formData.typeOfTransportFacilities === "Rented"}
                onChange={handleChange}
              />
              <label>Rented</label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="typeOfTransportFacilities"
                value="Third Party Logistics"
                checked={
                  formData.typeOfTransportFacilities === "Third Party Logistics"
                }
                onChange={handleChange}
              />
              <label>Third Party Logistics</label>
            </div>
            {errors.typeOfTransportFacilities ? (
              <div className="error-message">
                {errors.typeOfTransportFacilities}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <div className="text-question">
        <p>
          Your Investment capacity(monthly)? <span>*</span>
        </p>
        <input
          type="text"
          name="investmentCapacity"
          value={formData.investmentCapacity || ""}
          onChange={handleChange}
        ></input>
        {errors.investmentCapacity ? (
          <div className="error-message">{errors.investmentCapacity}</div>
        ) : (
          ""
        )}
      </div>

      <div className="previous-buttons">
        <button onClick={onPrevious}>Previous</button>
        <button onClick={handleSubmit} disabled={!isValid}>
          Submit
        </button>
        {isLoading && <div className="loader"></div>}
      </div>
    </div>
  );
}

export default FormPage5;
