import React, { useState } from "react";
import "../Styles/addProducts.css";
import axios from "axios";
import AlertPopup from "./AlertPopup";

function AddProducts() {
  const [productId, setProductID] = useState("");
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [quotedPrice, setQuotedPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [shortDescription, setShortDescription] = useState("");
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [weight, setWeight] = useState("");
  const [dietType, setDietType] = useState("");
  const [packing, setPacking] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [isAvailable, setIsAvailable] = useState(true);
  const [isFeatured, setIsFeatured] = useState("");
  const [rating, setRating] = useState("");
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [description, setDescription] = useState("");
  const [fixedCost, setFixedCost] = useState(50);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // const handleFileUpload = async (e) => {
  //     const file = e.target.files[0];
  //     const base64 = await convertToBase64(file);
  //     setImageUrl(base64);
  // };

  // const convertToBase64 = (file) => {
  //     return new Promise((resolve, reject) => {
  //         const fileReader = new FileReader();
  //         fileReader.readAsDataURL(file);
  //         fileReader.onload = () => resolve(fileReader.result);
  //         fileReader.onerror = (error) => reject(error);
  //     });
  // };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(`${BACKEND_URL}/upload-files`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (response.ok) {
          setImageUrl(data.url);
        } else {
          console.error("Error uploading file:", data.error);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!productId) newErrors.productId = "Product ID is required.";
    if (!title) newErrors.title = "Title is required.";
    if (!imageUrl) newErrors.imageUrl = "Image is required.";
    if (!quotedPrice || quotedPrice <= 0)
      newErrors.quotedPrice = "Quoted Price must be greater than 0.";
    if (!discountPrice || discountPrice <= 0)
      newErrors.discountPrice = "Discount Price must be greater than 0.";
    if (!shortDescription)
      newErrors.shortDescription = "Short Description is required.";
    if (!brand) newErrors.brand = "Brand is required.";
    if (!weight) newErrors.weight = "Weight is required.";
    if (!dietType) newErrors.dietType = "Diet Type is required.";
    if (!packing) newErrors.packing = "Package details are required.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await axios.post(`${BACKEND_URL}/addproduct`, {
        productId,
        title,
        imageUrl,
        quotePrice: quotedPrice,
        discountPrice,
        shortDescription,
        brand,
        weight,
        dietType,
        package: packing,
        category,
        longDescription,
        isStockAvailable: isAvailable,
        isFeatured,
        rating,
        specifications: specifications
          .split("\n")
          .filter((paragraph) => paragraph.trim() !== ""),
        description: description
          .split("\n")
          .filter((paragraph) => paragraph.trim() !== ""),
        fixedShippingCost: fixedCost,
      });

      if (response.status === 200 || response.status === 201) {
        setAlertMessage("Product successfully added");
      } else {
        setAlertMessage("Error adding product");
      }
    } catch (error) {
      setAlertMessage("An error occurred during product submission.");
    }
  };

  return (
    <div className="add-products">
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage("")} />
      <div className="add-products-card">
        <form className="add-products-form" onSubmit={handleSubmit}>
          <input
            type="text"
            id="productId"
            placeholder="Product ID *"
            value={productId}
            onChange={(e) => setProductID(e.target.value)}
          />
          {errors.productId && (
            <p className="error-message">{errors.productId}</p>
          )}

          <input
            type="text"
            id="title"
            placeholder="Title *"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && <p className="error-message">{errors.title}</p>}

          <div className="input-group">
            <label htmlFor="image">Product Image *</label>
            <input type="file" id="image" onChange={handleFileUpload} />
          </div>
          {errors.imageUrl && (
            <p className="error-message">{errors.imageUrl}</p>
          )}

          <input
            type="number"
            id="quotedPrice"
            placeholder="Quoted Price *"
            onChange={(e) => setQuotedPrice(e.target.value)}
          />
          {errors.quotedPrice && (
            <p className="error-message">{errors.quotedPrice}</p>
          )}

          <input
            type="number"
            id="discountPrice"
            placeholder="Discount Price *"
            onChange={(e) => setDiscountPrice(e.target.value)}
          />
          {errors.discountPrice && (
            <p className="error-message">{errors.discountPrice}</p>
          )}

          <textarea
            id="shortDescription"
            placeholder="Short Description *"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
          />
          {errors.shortDescription && (
            <p className="error-message">{errors.shortDescription}</p>
          )}

          <input
            type="text"
            id="brand"
            placeholder="Brand *"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
          {errors.brand && <p className="error-message">{errors.brand}</p>}

          <input
            type="text"
            id="weight"
            placeholder="Weight *"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          {errors.weight && <p className="error-message">{errors.weight}</p>}

          <input
            type="text"
            id="dietType"
            placeholder="Diet Type *"
            value={dietType}
            onChange={(e) => setDietType(e.target.value)}
          />
          {errors.dietType && (
            <p className="error-message">{errors.dietType}</p>
          )}

          <input
            type="text"
            id="package"
            placeholder="Packing Details *"
            value={packing}
            onChange={(e) => setPacking(e.target.value)}
          />
          {errors.packing && <p className="error-message">{errors.packing}</p>}

          <input
            type="text"
            id="category"
            placeholder="Product Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />

          <textarea
            id="Description"
            placeholder="Description (separate paragraphs with line space)"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div className="input-group">
            <label htmlFor="stock-availability">Availability in Stock</label>
            <select
              id="stock-availability"
              value={isAvailable}
              onChange={(e) => setIsAvailable(e.target.value)}
            >
              <option value="">Select...</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="featured">Featured?</label>
            <select
              id="featured"
              value={isFeatured}
              onChange={(e) => setIsFeatured(e.target.value)}
            >
              <option value="">Select...</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>

          <input
            type="number"
            min="0"
            max="5"
            id="rating"
            placeholder="Rating"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          />
          <textarea
            name="specifications"
            placeholder="Description (Separate paragraphs with line breaks)"
            value={specifications}
            onChange={(e) => setSpecifications(e.target.value)}
          />
          <input
            type="number"
            id="Fixed Shipping Cost"
            placeholder="Fixed Cost *"
            value={fixedCost}
            onChange={(e) => setFixedCost(e.target.value)}
          />

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default AddProducts;
