import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomReactQuillEditor = () => {
  const [content, setContent] = useState(""); // Properly initialized state

  const handleEditorChange = (value) => {
    setContent(value); // Update state correctly to maintain content
  };

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const base64Image = reader.result;
        const quill = document.querySelector(".ql-editor");
        const range = quill.getSelection();

        if (range) {
          quill.insertEmbed(range.index, "image", base64Image);
        }
      };

      reader.readAsDataURL(file);
    };
  };

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "font",
    "size",
    "list",
    "bullet",
    "align",
    "link",
    "image",
    "video",
  ];

  return (
    <div>
      <ReactQuill
        value={content} // Controlled component
        onChange={handleEditorChange}
        modules={modules}
        formats={formats}
        style={{
          height: "400px",
          fontFamily: "Poppins, Inter, Raleway, Arial, sans-serif",
        }}
      />
    </div>
  );
};

export default CustomReactQuillEditor;

