import React, { useState } from "react";
import axios from "axios";
import "../../Styles/BlogForm.css"; // Import the new CSS file

const BlogForm = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState([
    { subheading: "", paragraphs: [""] },
  ]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [error, setError] = useState("");

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddSection = () => {
    setContent([...content, { subheading: "", paragraphs: [""] }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const blogData = { title, category, author, image, content, summary };

    try {
      await axios.post(`${BACKEND_URL}/api/blogs`, blogData);
      // Clear the form after submission
      setTitle("");
      setCategory("");
      setAuthor("");
      setImage("");
      setSummary("");

      setContent([{ subheading: "", paragraphs: [""] }]);
    } catch (error) {
      setError("Failed to post blog. Please try again later ");
    }
  };

  if (error) {
    return (
      <div className="error">
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  return (
    <form className="blog-form" onSubmit={handleSubmit}>
      <h2 className="form-title">Create a New Blog Post</h2>
      <input
        type="text"
        className="form-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        required
      />
      <input
        type="text"
        className="form-input"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        placeholder="Category"
        required
      />
      <input
        type="text"
        className="form-input"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        placeholder="Author"
        required
      />
      <input
        type="file"
        className="form-input"
        onChange={handleImageUpload}
        placeholder="upload image"
        required
      />
      <input
        type="text"
        className="form-input"
        value={summary}
        onChange={(e) => setSummary(e.target.value)}
        placeholder="Summary"
        required
      />

      {content.map((section, index) => (
        <div className="section" key={index}>
          <input
            type="text"
            className="subheading-input"
            value={section.subheading}
            onChange={(e) => {
              const newSections = [...content];
              newSections[index].subheading = e.target.value;
              setContent(newSections);
            }}
            placeholder="Subheading"
            required
          />
          {section.paragraphs.map((paragraph, pIndex) => (
            <input
              key={pIndex}
              type="text"
              className="paragraph-input"
              value={paragraph}
              onChange={(e) => {
                const newSections = [...content];
                newSections[index].paragraphs[pIndex] = e.target.value;
                setContent(newSections);
              }}
              placeholder="Paragraph"
              required
            />
          ))}
          <button
            type="button"
            className="add-paragraph-btn"
            onClick={() => {
              const newSections = [...content];
              newSections[index].paragraphs.push("");
              setContent(newSections);
            }}
          >
            Add Paragraph
          </button>
        </div>
      ))}
      <button
        type="button"
        className="add-section-btn"
        onClick={handleAddSection}
      >
        Add Section
      </button>
      <button type="submit" className="submit-btn">
        Submit
      </button>
    </form>
  );
};

export default BlogForm;
