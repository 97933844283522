import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useParams } from "react-router-dom";
import "../../Styles/BlogItem.css";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Helmet } from "react-helmet";

const BlogItem = () => {
  const { blogs, networkError } = useContext(AppContext);
  const { id } = useParams();
  // const blog = blogs.find((b) => b._id === id);
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const foundBlog = blogs.find((b) => b._id === id);
        if (foundBlog) {
          setBlog(foundBlog); // Use the blog from context if available
        } else {
          // Fetch from the backend if not found in context
          const response = await axios.get(`${BACKEND_URL}/blog/${id}`);
          setBlog(response.data);
        }
      } catch (err) {
        setError("Blog not found or an error occurred while fetching it.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id, blogs]);

  const navigateBack = () => {
    navigate(-1);
  };

  if (networkError) {
    return (
      <div className="error-class">
        <p>{networkError}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  return (
    <>
      <div className="blog-item-container">
        <div className="page-info">
          <div className="page-info-navigation">
            <div
              className="page-info-navigation-icons"
              onClick={() => {
                navigate("/");
              }}
            >
              <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
            </div>

            <h5>Blogs</h5>
          </div>
        </div>

        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="blog-item">
            {networkError ? (
              <div className="error-message">{networkError}</div>
            ) : (
              <>
                <Helmet>
                  <meta charset="UTF-8"></meta>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                  ></meta>
                  <meta name="author" content={blog.metaAuthor} />
                  <meta name="robots" content={blog.metaRobots}></meta>
                  <link rel="canonical" href={blog.metaCanonical}></link>
                  <meta name="description" content={blog.metaDescription} />
                  <meta name="keywords" content={blog.keywords.join(", ")} />
                  <title>{blog.metaTitle}</title>
                </Helmet>
                <h2>{blog.title}</h2>
                <p>
                  by: {blog.author} on{" "}
                  {new Date(blog.datePosted).toLocaleDateString()}
                </p>

                <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>

                {error && <div className="signup-erros">{error}</div>}

                <button
                  onClick={navigateBack}
                  className="blog-details-back-btn"
                >
                  Back
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <Navbar isHomePage={false} />
    </>
  );
};

export default BlogItem;
