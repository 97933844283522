import React, { useEffect, useState } from "react";
import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ManageSales = () => {
  const [sales, setSales] = useState([]);
  const [selectedSale, setSelectedSale] = useState(null);
  const [formData, setFormData] = useState({
    couponCode: "",
    type: "global",
    discount: "",
    discountType: "",
    minPurchase: "",
    productIds: [],
    startDate: "",
    endDate: "",
    isPromotional: false,
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Fetch all sales
  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/sale/get-sales`);

        setSales(response.data);
      } catch (error) {
        setError("Failed to fetch sales");
      }
    };

    fetchSales();
  }, []);

  // Handle dropdown change to select a sale
  const handleSaleSelect = (e) => {
    const saleId = e.target.value;
    const sale = sales.find((s) => s._id === saleId);
    setSelectedSale(sale);

    if (sale) {
      setFormData({
        couponCode: sale.couponCode,
        type: sale.type,
        discount: sale.discount,
        discountType: sale.discountType,
        minPurchase: sale.minPurchase,
        productIds: sale.productIds || [],
        startDate: sale.startDate.split("T")[0],
        endDate: sale.endDate.split("T")[0],
        isPromotional: sale.isPromotional,
      });
    }
  };

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "productIds") {
      // Split input value by commas and trim whitespace
      const productIdsArray = value.split(",").map((id) => id.trim());
      setFormData((prevData) => ({
        ...prevData,
        productIds: productIdsArray,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle updating the sale
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      if (!selectedSale) {
        setError("Please select a sale to update");
        return;
      }

      const updatedSale = {
        ...formData,
        startDate: new Date(formData.startDate).toISOString(),
        endDate: new Date(formData.endDate).toISOString(),
      };

      await axios.put(
        `${BACKEND_URL}/sale/update-sale/${selectedSale._id}`,
        updatedSale
      );
      setSuccess("Sale updated successfully");
      setError("");
    } catch (error) {
      setError("Failed to update the sale");
      setSuccess("");
    }
  };

  // Handle deleting the sale
  const handleDelete = async () => {
    try {
      if (!selectedSale) {
        setError("Please select a sale to delete");
        return;
      }

      await axios.delete(`${BACKEND_URL}/sale/delete-sale/${selectedSale._id}`);
      setSales((prevSales) =>
        prevSales.filter((sale) => sale._id !== selectedSale._id)
      );
      setSelectedSale(null);
      setFormData({
        couponCode: "",
        type: "global",
        discount: "",
        discountType: "",
        minPurchase: "",
        productIds: [],
        startDate: "",
        endDate: "",
        isPromotional: false,
      });
      setSuccess("Sale deleted successfully");
      setError("");
    } catch (error) {
      setError("Failed to delete the sale");
      setSuccess("");
    }
  };

  return (
    <div className="manage-sale-container">
      <h2>Manage Sales</h2>

      <div className="sale-input-group">
        <label>Select Sale:</label>
        <select value={selectedSale?._id || ""} onChange={handleSaleSelect}>
          <option value="" disabled>
            -- Select a Sale --
          </option>
          {sales &&
            sales.map((sale) => (
              <option key={sale._id} value={sale._id}>
                {sale.couponCode} ({sale.type})
              </option>
            ))}
        </select>
      </div>

      {selectedSale && (
        <form onSubmit={handleUpdate}>
          <div className="sale-input-group">
            <label>Coupon Code:</label>
            <input
              type="text"
              name="couponCode"
              value={formData.couponCode}
              onChange={handleChange}
              required
            />
          </div>

          <div className="sale-input-group">
            <label>Sale Type:</label>
            <select name="type" value={formData.type} onChange={handleChange}>
              <option value="global">Global Sale</option>
              <option value="product">Product Sale</option>
            </select>
          </div>
          <div className="sale-input-group">
            <label>Is Promotional:</label>
            <select
              name="isPromotional"
              value={formData.isPromotional}
              onChange={handleChange}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>

          <div className="sale-input-group">
            <label>Discount:</label>
            <input
              type="text"
              name="discount"
              value={formData.discount}
              onChange={handleChange}
              required
            />
          </div>

          <div className="sale-input-group">
            <label>Discount Type:</label>
            <select
              name="discountType"
              value={formData.discountType}
              onChange={handleChange}
            >
              <option value="percentage">Percentage</option>
              <option value="amount">Amount</option>
            </select>
          </div>

          <div className="sale-input-group">
            <label>Minimum Purchase:</label>
            <input
              type="text"
              name="minPurchase"
              value={formData.minPurchase}
              onChange={handleChange}
              required
            />
          </div>

          {formData.type === "product" && (
            <div className="sale-input-group">
              <label>Product IDs (Comma Separated):</label>
              <input
                type="text"
                name="productIds"
                value={formData.productIds.join(", ")}
                onChange={handleChange}
                placeholder="e.g,product1,product2,product3"
                required
              />
            </div>
          )}

          <div className="sale-input-group">
            <label>Start Date:</label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              required
            />
          </div>

          <div className="sale-input-group">
            <label>End Date:</label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit">Update Sale</button>
          {selectedSale && (
            <button onClick={handleDelete} style={{ marginTop: "10px" }}>
              Delete Sale
            </button>
          )}
        </form>
      )}

      {/* Success/Error messages */}
      {success && <p style={{ color: "green" }}>{success}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default ManageSales;
