// import React, { useContext, useEffect, useState } from 'react';
// import axios from 'axios';
// import BlogItems from './BlogItems';
// import SearchBar from './SearchBar';
// import { AppContext } from '../../AppContext';
// import '../../Styles/BlogList.css'
// import Navbar from '../Navbar';
// import { useNavigate } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHouse, faGreaterThan} 
// from '@fortawesome/free-solid-svg-icons'

// const BlogList = () => {

//     const {blogs,setBlogs,filteredBlogs,setFilteredBlogs,searchTerm,setSearchTerm,selectedCategory,setSelectedCategory,categories,setCategories}=useContext(AppContext)

//     const navigate=useNavigate()

    


//     return (
//         <div className='blog-component-container'>
//              <div className='page-info'>
            
//             <div className='page-info-navigation'>
//                 <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
//                 <h5>Blogs</h5>
//             </div>
//             </div>
//         <div className='blog-list-container'>
//             <div className='filters-mobile'>
//             {/* <SearchBar setSearchTerm={setSearchTerm} /> */}
//             <input
//             type="text"
//             placeholder="Search blogs..."
//             onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <select onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
//                 <option value="">All Categories</option>
//                 {categories.map((category, index) => (
//                     <option key={index} value={category}>{category}</option>
//                 ))}
//             </select>
//             </div>
            
//             <div className='blog-list'>
//             {filteredBlogs.length ? filteredBlogs.map(blog => (
//                 <BlogItems key={blog._id} blog={blog} />
//             )):<p>No Blogs found for the search term</p>}
//             </div>
//             <div className='filters'>
//             {/* <SearchBar setSearchTerm={setSearchTerm} /> */}
//             <input
//             type="text"
//             placeholder="Search blogs..."
//             onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <select onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
//                 <option value="">All Categories</option>
//                 {categories.map((category, index) => (
//                     <option key={index} value={category}>{category}</option>
//                 ))}
//             </select>
//             </div>
//         </div>

//             <Navbar isHomePage={false}/>
//         </div>
//     );
// };

// export default BlogList;



import React, { useContext, useState,useEffect } from 'react';
import BlogItems from './BlogItems';
import { AppContext } from '../../AppContext';
import '../../Styles/BlogList.css';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'


const BlogList = () => {
    const {
        filteredBlogs,
        setFilteredBlogs,
        searchTerm,
        setSearchTerm,
        selectedCategory,
        setSelectedCategory,
        categories,
        blogs
        
    } = useContext(AppContext);

    const navigate = useNavigate();
    const [isLoading,setIsLoading]=useState(true)
    const BACKEND_URL=process.env.REACT_APP_BACKEND_URL



    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 10; // Number of blogs to display per page

    // Calculate pagination
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 1000); 

        return () => clearTimeout(timer); 
    }, [navigate]);


    return (

        
        <div className='blog-component-container'>
            {isLoading?<div className='loader' ></div>:(
                <>
            <div className='page-info'>
                <div className='page-info-navigation'>
                    <div
                        className='page-info-navigation-icons'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
                    </div>
                    <h5>Blogs</h5>
                </div>
            </div>
            <div className='blog-list-container'>
                <div className='filters-mobile'>
                    <input
                        type='text'
                        placeholder='Search blogs...'
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        value={selectedCategory}
                    >
                        <option value=''>All Categories</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='blog-list'>
                    {currentBlogs.length ? (
                        currentBlogs.map((blog) => (
                            <BlogItems key={blog._id} blog={blog}  searchTerm={searchTerm}/>
                        ))
                    ) : (
                        <p className='no-blog-msg'>No Blogs found.</p>
                    )}
                    <div className='pagination'>
                    <button
                        className='page-button'
                        onClick={handlePrevious}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className='page-info'>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        className='page-button'
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
                </div>
                
                <div className='filters'>
                    <input
                        type='text'
                        placeholder='Search blogs...'
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        value={selectedCategory}
                    >
                        <option value=''>All Categories</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            </>
            )}
            <Navbar isHomePage={false} />
        </div>
        
    );
};

export default BlogList;

