import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/Aryadhenu_Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUser, faCartShopping, faAngleDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../AppContext';
import { useCart } from '../CartContext';
import defaultLogo from '../Assets/default-logo.png';
import '../Styles/mobileNavbar.css'
import { useNavigate } from 'react-router-dom';
import dummyImage from '../Assets/user-dummy.png'


const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

const MobileNavbar = ({ isHomePage }) => {
    const { slides, currentIndex } = useContext(AppContext);
    // const color = slides[currentIndex].backgroundColor;
    const { getCartItemCount, cart } = useCart();
    const cartItemCount = getCartItemCount();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isShowDistributor,setIsShowDistributor]=useState(false)
    const [isMobileUserOpen,setIsMobileUserOpen]=useState(false)
    const navigate=useNavigate()

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsShowDistributor(false)
    };

    const toggleUserMenu=()=>{
        setIsMobileUserOpen(!isMobileUserOpen)
    }

    const showDistributor=()=>{
        setIsShowDistributor(!isShowDistributor)
    }

    useEffect(() => {
        const handleScroll = () => {
            const heroElement = document.querySelector('.hero-sample');
            if (heroElement) {
                const heroHeight = heroElement.offsetHeight;
                const scrollPosition = window.scrollY;
                const threshold = 200; 

                setIsScrolled(scrollPosition > threshold);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleNavigate = () => {
        // If logged in, navigate to logout; otherwise, navigate to login
        // navigate(loginStatus ? '/logout' : '/login');
        if(isTokenValid()){
          navigate('/logout')
        }else{
          navigate('/login')
        }
    };

    const handleLogoNavigate=()=>{
        navigate('/')
    }
    
     const userPicture = localStorage.getItem('user-picture');
      const firstLetter=localStorage.getItem('email')?localStorage.getItem('email').charAt(0).toUpperCase():'';

    return (
        <>
            <div className='marquee'>
                <div className='marquee-content'>
                <p>Get Flat 15% OFF on all products with coupon code "FLAT15"</p>
                </div>
            </div>
            <div className={`navbar-mobile ${isHomePage ? 'home' : ''} `}>
                <div className='logo'>
                    {/* <img src={defaultLogo} alt='aryadhenu-logo' className='logo-image' id='default-logo' /> */}
                    <img src={Logo} alt='aryadhenu-logo' className='logo-image' id='default-logo' onClick={handleLogoNavigate}/>
                </div>
                
                <div className='mobile-navbar-links'>
                    <div className='mobile-menu-icon' onClick={toggleMenu}>
                    <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars}  style={{color:'#ffffff'}}/>
                    </div>
                    
                    <div className='mobile-nav-icons'>
                        {/* <FontAwesomeIcon icon={faMagnifyingGlass} className='fontawesome-icon' style={{color:'#ffffff'}}/> */}
                        <div onClick={toggleUserMenu} >
                            {/* <FontAwesomeIcon icon={faUser} className='fontawesome-icon' style={{color:'#ffffff'}} /> */}
                             {isTokenValid() ?
              <div className="email-avatar">
                <img src={userPicture || dummyImage}/>
            </div>:(
              <FontAwesomeIcon icon={faUser}  className='fontawesome-icon' style={{color:'#ffffff'}} />
            )
              }
                        </div>
                        <Link to='/cart' style={{ textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faCartShopping} className='fontawesome-icon' style={{color:'#ffffff'}}/>
                            {cart.length > 0 && (
                                <span>{cartItemCount}</span>
                            )}
                        </Link>
                    </div>
                </div>
                <div className={`mobile-nav-user ${isMobileUserOpen ? 'open' : ''}`}>
                    {isTokenValid()?<>
                     {userPicture && <img src={userPicture}/>}
                     <p>{localStorage.getItem('email')}</p>
                     <Link to='/user-dashboard'>Your Orders</Link>
                     <Link to='/user-dashboard'>Update Profile</Link>
                     <Link to='/logout'>Logout</Link>
                    
                 </>:(
                  <>
                    <Link to="/login">Login</Link>
                    <Link to="/signup">Create Account</Link>
                    
                  </>
                )}
                       
                    </div>
                <div className={`mobile-nav-links ${isMenuOpen ? 'open' : ''}`}>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/shop'>Shop</Link></li>
                            <li><Link to='/bulk-order'>Bulk Order</Link></li>
                            <li><Link to='/contact'>Contact</Link></li>
                            {/* <li className='nav-links-dropdown'>
                                <Link>Distributor </Link><div className='nav-links-fontawesome-icon' onClick={showDistributor}><FontAwesomeIcon icon={faAngleDown}  /></div>
                                
                            </li> */}
                            <li>
                               <a href='/distributor/candf-agents' target='_blank'>Distributor Form</a>
                            </li>
                        </ul>
                       
                    </div>
                     <div className={`distributor-dropdown-content ${isShowDistributor?'open':''}`}>
                                    <ul>
                                    <li>
                                    <Link to="/distributor/candf-agents-only">C & F agents only</Link>
                                    </li>
                                    <li>
                                    <Link to="/distributor/candf-agents">C & F agents</Link>
                                    </li>
                                    <li>
                                    <Link to="/distributor/super-stockist">Super Stockist</Link>
                                    </li>
                                    <li>
                                    <Link to="/distributor/dealer">Dealers</Link>
                                    </li>
                                    </ul>
                                </div>
            </div>
        </>
    );
}

export default MobileNavbar;
