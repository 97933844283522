import React from "react";
import "../Styles/ThankYouMessage.css"; // Import CSS file
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const ThankYouMessage = () => {
    const navigate=useNavigate()

    const onContinueShopping=()=>{
        navigate('/shop')

    }
  return (
    <>
    <div className="thank-you-container">
      <div className="checkmark-container">
        <svg className="checkmark" viewBox="0 0 52 52">
          <circle className="checkmark-circle" cx="26" cy="26" r="25" />
          <path
            className="checkmark-check"
            fill="none"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 27l7 7 16-16"
          />
        </svg>
      </div>
      <h2>Thank You for Your Purchase! 🎉</h2>
      <p>Your order has been placed successfully. We'll notify you once it's shipped.</p>
      <button className="continue-btn" onClick={onContinueShopping}>
        Continue Shopping 🛍️
      </button>
      
    </div>
    <Navbar isHomePage={false}/>
    </>
  );
};

export default ThankYouMessage;

