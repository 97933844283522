// import React, { useState, useEffect } from "react";
// import { Editor } from "@tinymce/tinymce-react";

// const CustomTinyMCEEditor = () => {
//   const [content,setContent]=useState('')
  

//   // Only check TinyMCE when it's available in the window object
//   useEffect(() => {
//     if (typeof window.tinymce === "undefined") {
     
//     } else {
      
//     }
//   }, []);

//   const handleEditorChange = (newContent) => {
//     setContent(newContent);
//   };

//   return (
//     <Editor
//       apiKey="cvmovar1q86mf55ozqh7nhx4r0ssvkfm6grapuxgfbdmto8x"
//       initialValue={content}
      
//       init={{
//         height: 600,
//          directionality: 'ltr',
//          toolbar: 'paste',
//          language: 'en',
//         content_style: `
//         body {
//             direction: ltr !important;
//             text-align: left important;
//             padding: 10px;
//         }
//     `,
//         menubar: true,
//         selector: 'paragraph',
//         plugins: [
//           'advlist', 'autolink', 'autosave', 'code', 'emoticons', 'fullscreen', 
//           'image', 'imagetools', 'link', 'lists', 'media', 'preview', 'quickbars', 
//           'table', 'wordcount',
//         ],
//         toolbar: `undo redo | styles | bold italic underline | fontselect fontsizeselect formatselect |
//                   alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
//                   link image media table | emoticons | preview fullscreen code `,
//         font_formats: `Poppins=Poppins,sans-serif; Inter=Inter,sans-serif; Raleway=Raleway,sans-serif; Cursive=Cursive,cursive;`,
//         content_style: `
//           @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Inter:wght@400;500;600&family=Raleway:wght@400;500;600&display=swap');
//           body {
//             font-family: Poppins, Inter, Raleway, Arial, sans-serif;
//             font-size: 14pt;
//           }
//         `,
//         onInit: (editor) => {
//           // Ensure tinymce is initialized before accessing the active editor
//           if (!editor) {
            
//           } else {
            
//           }
//         },
//         file_picker_types: 'image',
        
//         file_picker_callback: (cb, value, meta) => {
//           const input = document.createElement('input');
//           input.setAttribute('type', 'file');
//           input.setAttribute('accept', 'image/*');
//           input.addEventListener('change', (e) => {
//             const file = e.target.files[0];
//             const reader = new FileReader();
            
//             reader.onload = () => {
//               if (!window.tinymce.activeEditor) {
               
//                 return;
//               }

//               const id = 'blobid' + (new Date()).getTime();
//               const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
//               const base64 = reader.result.split(',')[1];
//               const blobInfo = blobCache.create(id, file, base64);
//               blobCache.add(blobInfo);

//               cb(blobInfo.blobUri(), { title: file.name });
//             };

//             reader.readAsDataURL(file);
//           });

//           input.click();
//         },
//         image_title: true,
//         automatic_uploads: true,
        
//       }}
//       onEditorChange={handleEditorChange}
//     />
//   );
// };

// export default CustomTinyMCEEditor;



// import React, { useState } from "react";
// import { Editor } from "@tinymce/tinymce-react";

// const CustomTinyMCEEditor = () => {
//   const [content, setContent] = useState("");

//   const handleEditorChange = (newContent) => {
//     setContent(newContent);
//   };

//   return (
//     <Editor
//       apiKey="cvmovar1q86mf55ozqh7nhx4r0ssvkfm6grapuxgfbdmto8x"
//       value={content}
//       init={{
//         height: 600,
//         menubar: true,
//         directionality: "ltr", // Set default text direction
//         plugins: [
//           'advlist', 'autolink', 'autosave', 'code', 'emoticons', 'fullscreen', 
//           'image', 'imagetools', 'link', 'lists', 'media', 'preview', 'quickbars', 
//           'table', 'wordcount',
//         ],
//         toolbar: `undo redo | styles | bold italic underline | fontselect fontsizeselect formatselect |
//                   alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
//                   link image media table | emoticons | preview fullscreen code `,
//         font_formats: `Poppins=Poppins,sans-serif; Inter=Inter,sans-serif; Raleway=Raleway,sans-serif; Cursive=Cursive,cursive;`,
//         content_style: `
//           @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Inter:wght@400;500;600&family=Raleway:wght@400;500;600&display=swap');
//           body {
//             font-family: Poppins, Inter, Raleway, Arial, sans-serif;
//             font-size: 14pt;
//             direction: ltr !important; /* Force LTR direction */
//             text-align: left !important; /* Force left alignment */
//           }
//         `,
//         file_picker_types: "image",
//         file_picker_callback: (cb, value, meta) => {
//           const input = document.createElement("input");
//           input.setAttribute("type", "file");
//           input.setAttribute("accept", "image/*");
//           input.addEventListener("change", (e) => {
//             const file = e.target.files[0];
//             const reader = new FileReader();

//             reader.onload = () => {
//               const id = "blobid" + new Date().getTime();
//               const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
//               const base64 = reader.result.split(",")[1];
//               const blobInfo = blobCache.create(id, file, base64);
//               blobCache.add(blobInfo);

//               cb(blobInfo.blobUri(), { title: file.name });
//             };

//             reader.readAsDataURL(file);
//           });

//           input.click();
//         },
//       }}
//       onEditorChange={handleEditorChange}
//     />
//   );
// };

// export default CustomTinyMCEEditor;


import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const CustomTinyMCEEditor = ({setContent,content}) => {
  // const [content, setContent] = useState("");

  const handleEditorChange = (newContent) => {
    setContent(newContent);
  };

  const tinymceKey=process.env.REACT_APP_TINY_MCE_KEY

  return (
    <Editor
      apiKey={tinymceKey}
      value={content}
      init={{
        height: 600,
        menubar: true,
        directionality: "ltr", // Set default text direction
        plugins: [
          "advlist", "autolink", "autosave", "code", "emoticons", "fullscreen", 
          "image", "imagetools", "link", "lists", "media", "preview", "quickbars", 
          "table", "wordcount",
        ],
        toolbar: `undo redo | styles | bold italic underline | fontselect fontsizeselect formatselect |
                  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |
                  link image media table | emoticons | preview fullscreen code `,
        font_formats: `Poppins=Poppins,sans-serif; Inter=Inter,sans-serif; Raleway=Raleway,sans-serif; Cursive=Cursive,cursive;`,
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Inter:wght@400;500;600&family=Raleway:wght@400;500;600&display=swap');
          body {
            font-family: Poppins, Inter, Raleway, Arial, sans-serif;
            font-size: 14pt;
            direction: ltr !important; /* Enforce LTR direction */
            text-align: left !important; /* Enforce left alignment */
          }
        `,
        forced_root_block_attrs: {
          dir: "ltr", // Force each block to have LTR direction
        },
        file_picker_types: "image",
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.addEventListener("change", (e) => {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
              const id = "blobid" + new Date().getTime();
              const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
              const base64 = reader.result.split(",")[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };

            reader.readAsDataURL(file);
          });

          input.click();
        },
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default CustomTinyMCEEditor;



