import React, { useState, useEffect, useContext } from "react";
import "../Styles/Checkout.css";
import Navbar from "./Navbar";
import { useCart } from "../CartContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import Payment from "./Payment";
import AlertPopup from "./AlertPopup";
import { AppContext } from "../AppContext";
import ConversionTracker from "./ConversionTracker";

function Checkout() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [townCity, setTownCity] = useState("");
  const [state, setState] = useState("Haryana");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [signUpcheck, setSignUpcheck] = useState(false);
  const [isCouponCodeVisible, setIsCouponCodeVisible] = useState(false);
  const [isShippingAddressDifferent, setIsShippingAddressDifferent] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [shippingFirstName, setShippingFirstName] = useState("");
  const [shippingLastName, setShippingLastName] = useState("");
  const [shippingState, setShippingState] = useState("Haryana");
  const [shippingStreetAddress, setShippingStreetAddress] = useState("");
  const [shippingTownCity, setShippingTownCity] = useState("");
  const [shippingPincode, setShippingPincode] = useState("");
  const {
    cart,
    deadWeight,
    pincode,
    setPincode,
    subtotal,
    setSubTotal,
    shippingCost,
    setShippingCost,
    setDeadWeight,
    removeFromCart,
  } = useCart();
  const [loading, setLoading] = useState(false);
  const [systemOrderId, setSystemOrderId] = useState("");
  const [courierId, setCourierId] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [discountedSubtotal, setDiscountedSubtotal] = useState("");
  const [discount, setDiscount] = useState(null);
  const [errors, setErrors] = useState({});
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { sales } = useContext(AppContext);
  const [enableFixedShippingCost, setEnableFixedShippingCost] = useState(null);
  const [fixedShippingCost, setFixedShippingCost] = useState(50);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Fetch current status
    const fetchStatus = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/enable-shipping-cost`);
        setEnableFixedShippingCost(response.data.isFixedCostShippingEnabled);
      } catch (error) {}
    };

    fetchStatus();
  }, []);

  useEffect(() => {
    calculateShippingCost();
  }, [cart, enableFixedShippingCost]);

  const calculateShippingCost = () => {
    if (enableFixedShippingCost) {
      // Calculate shipping cost based on fixedShippingCost and product quantity
      const totalFixedShippingCost = cart.reduce((total, product) => {
        const productFixedShippingCost = product.product.fixedShippingCost || 0;

        return total + productFixedShippingCost * product.quant;
      }, 0);

      setFixedShippingCost(totalFixedShippingCost);
    } else {
      setFixedShippingCost(50); // Example flat rate
    }
  };

  function validateForm() {
    const newErrors = {};

    if (!/^[A-Za-z .]{3,25}$/.test(firstName)) {
      newErrors.firstName =
        "First name length should be between 3 and 25 characters. Only alphabets,dots(.),spaces are allowed.";
    }
    if (!/^[A-Za-z .]{3,25}$/.test(lastName)) {
      newErrors.lastName =
        "Last name length should be between 3 and 25 characters. Only alphabets,dots(.),spaces are allowed.";
    }

    if (!/^[A-Za-z .]{3,25}$/.test(shippingFirstName)) {
      newErrors.shippingFirstName =
        "First name length should be between 3 and 25 characters. Only alphabets,dots(.),spaces are allowed.";
    }
    if (!/^[A-Za-z .]{3,25}$/.test(shippingLastName)) {
      newErrors.shippingLastName =
        "Last name length should be between 3 and 25 characters. Only alphabets,dots(.),spaces are allowed.";
    }

    if (!/^[0-9]{6}$/.test(pin)) {
      newErrors.pin = "Pincode should be a valid 6 digit pincode.";
    }
    if (!/^[0-9]{6}$/.test(pincode)) {
      newErrors.pincode = "Pincode should be a valid 6 digit pincode.";
    }
    if (!/^[A-Za-z0-9'.,\-\/ ]{10,50}$/.test(shippingStreetAddress)) {
      newErrors.shippingStreetAddress =
        "Street Address must be between 10 and 50 characters  Only Alphanumeric, spaces and some Special characters like  '. , - / are allowed ";
    }
    if (!/^[A-Za-z0-9'.,\-\/ ]{10,50}$/.test(streetAddress)) {
      newErrors.streetAddress =
        "Street Address must be between 10 and 50 characters  Only Alphanumeric, spaces and some Special characters like  '. , - / are allowed ";
    }
    if (!/^[A-Za-z0-9'.,\-\/ ]{0,50}$/.test(townCity)) {
      newErrors.townCity =
        "Town/City must be between 10 and 50 characters  Only Alphanumeric, spaces and some Special characters like  '. , - / are allowed ";
    }
    if (!/^[A-Za-z0-9'.,\-\/ ]{0,50}$/.test(shippingTownCity)) {
      newErrors.shippingTownCity =
        "Town/City must be between 10 and 50 characters  Only Alphanumeric, spaces and some Special characters like  '. , - / are allowed ";
    }
    if (!/^(0?[6-9][0-9]{9})$/.test(phone)) {
      newErrors.phone =
        "Phone number should 10 digits long. Should be a valid phone number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const [paymentDetails, setPaymentDetails] = useState(null);

  const handlePaymentSuccess = (status, orderId, paymentId) => {
    setPaymentDetails({ status, orderId, paymentId });

    if (status === 200) {
      placeOrder({ status, orderId, paymentId });
    } else if (status === 500) {
      setAlertMessage("Payment verification failed. Cannot place the order");
      navigate("/cart");
    } else if (status === undefined) {
      setAlertMessage("Payment status is undefined. Please try again.");
      navigate("/cart");
    } else {
      setAlertMessage(
        "Unexpected status received. Please check the payment process."
      );
      navigate("/cart");
    }
  };

  const navigate = useNavigate();

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
  ];

  useEffect(() => {
    const calculateDeadWeight = () => {
      let weight = 0;
      cart.forEach((item) => {
        const Weight = item.product.weight;
        const quantity = item.quant;

        if (Weight === "900 grams") {
          weight += 1 * quantity;
        } else if (Weight === "1800 grams") {
          weight += 2 * quantity;
        } else if (Weight === "4500 grams") {
          weight += 5 * quantity;
        } else if (Weight === "9000 grams") {
          weight += 10 * quantity;
        } else if (Weight === "18000 grams") {
          weight += 20 * quantity;
        } else if (Weight === "300 grams") {
          weight += 0.5 * quantity;
        } else if (Weight === "1 Litre") {
          weight += 1 * quantity;
        } else if (Weight === "500ml") {
          weight += 0.7 * quantity;
        }
      });
      if (weight === 6) {
        setDeadWeight(5);
      } else if (weight === 11) {
        setDeadWeight(10);
      } else if (weight === 21) {
        setDeadWeight(20);
      } else if (weight === 22) {
        setDeadWeight(20);
      } else {
        setDeadWeight(weight);
      }
    };
    calculateDeadWeight();
  }, [cart]);

  useEffect(() => {
    const total = cart.reduce((acc, product) => {
      return acc + product.product.discountPrice * product.quant;
    }, 0);

    setSubTotal(total);
  }, [cart]);

  const applyCoupon = (couponCode) => {
    // Find the coupon in sales based on the code

    const coupon = sales.find((c) => c.couponCode === couponCode);

    if (!coupon) {
      setAlertMessage("Invalid coupon code");
      setDiscount(0);
      setDiscountedSubtotal(subtotal);
      return;
    }

    let discount = 0;

    if (coupon.type === "global") {
      // Global coupon: Check if cart total meets the minimum purchase requirement
      if (subtotal >= coupon.minPurchase) {
        if (coupon.discountType === "amount") {
          discount = coupon.discount; // Flat discount
        } else if (coupon.discountType === "percentage") {
          discount = (subtotal * coupon.discount) / 100; // Percentage discount
        }
      } else {
        setAlertMessage(
          `Cart total must be at least ₹${coupon.minPurchase} to use this coupon.`
        );
      }
    } else if (coupon.type === "product") {
      // Find all products in the cart that match the coupon's productIds array
      const matchingProducts = cart.filter(
        (product) => coupon.productIds.includes(product.product._id) // Check if product ID is in the coupon's productIds
      );

      if (matchingProducts.length > 0) {
        // Calculate the total for all matching products
        const totalForMatchingProducts = matchingProducts.reduce(
          (total, product) =>
            total + product.product.discountPrice * product.quant,
          0
        );

        if (totalForMatchingProducts >= coupon.minPurchase) {
          if (coupon.discountType === "amount") {
            discount = coupon.discount; // Flat discount
          } else if (coupon.discountType === "percentage") {
            discount = (totalForMatchingProducts * coupon.discount) / 100; // Percentage discount
          }
        } else {
          setAlertMessage(
            `Total for applicable products must be at least ₹${coupon.minPurchase} to use this coupon.`
          );
        }
      } else {
        setAlertMessage("Coupon code not applicable on selected products.");
      }
    } else if (coupon.type === "promotional") {
      // Global coupon: Check if cart total meets the minimum purchase requirement
      if (subtotal >= coupon.minPurchase) {
        if (coupon.discountType === "amount") {
          discount = coupon.discount; // Flat discount
        } else if (coupon.discountType === "percentage") {
          discount = (subtotal * coupon.discount) / 100; // Percentage discount
        }
      } else {
        setAlertMessage(
          `Cart total must be at least ₹${coupon.minPurchase} to use this coupon.`
        );
      }
    }

    // Apply discount if valid
    if (discount > 0) {
      setDiscount(discount);
      setDiscountedSubtotal(subtotal - discount);
      setAlertMessage("Coupon applied successfully!");
    } else {
      setDiscount(0);
      setDiscountedSubtotal(subtotal);
    }
  };

  const handleCouponVisibility = () => {
    setIsCouponCodeVisible(!isCouponCodeVisible);
  };

  const date = new Date();

  //  product.product._id==='66beeb80a473757ab338277c'?'Aryadhenu Shudh Desi Khand, 900gm (Pack of 2)':
  //                         product.product._id==='66beebffa473757ab3382782'?'Aryadhenu Shudh Desi Khand, 900gm (Pack of 5)':
  //                         product.product._id==='66bde511c2865cb753446b86'?'Aryadhenu Shudh Desi Khand, 900gm':
  //                         product.product._id==='66bde7a7c2865cb753446b8a'?'Aryadhenu Shudh Desi Khand, 900gm (Pack of 10)':
  //                         product.product._id==='66bde2b5c2865cb753446b81'?'Aryadhenu Shudh Desi Gur 900GM':
  //                         product.product._id==='66bde370c2865cb753446b83'?'Aryadhenu Desi Boora-900gm':
  //                         product.product._id==='66bde603c2865cb753446b88'?'Aryadhenu Desi Boora-300gm':
  //                         product.product._id==='66bde8b6c2865cb753446b8c'?'Aryadhenu Sudh Desi Shakkar, 900gm':
  //                         product.product._id==='66beee19a473757ab338278a'?'Aryadhenu Wood Pressed Mustard Oil':
  //                         product.product._id==='66eed6edaab22e27c03af0ba'?'Indian A2 Cow Desi Ghee':
  //                         product.product._id==='67398b8e420c8ada3f026c49'?'Aryadhenu Shudh Desi Khand, 900gm (Pack of 20)':
  //                         'miscelleaneous',
  const productDetails = cart.map((product) => ({
    product_category: "FMCG",
    product_sub_category: "",
    product_name: product.product.title.replace(/[^a-zA-Z0-9,\/\- ]/g, ""),
    product_quantity: product.quant,
    each_product_invoice_amount: product.product.discountPrice * product.quant,
    each_product_collectable_amount: 0,
    hsn: "",
  }));

  const productsToSaveOrder = cart.map((product) => ({
    productId: product.product._id,
    productName: product.product.title,
    quantity: product.quant,
    price: product.product.discountPrice,
    subtotal: product.product.discountPrice * product.quant,
  }));

  const body = {
    shipment_category: "b2c",
    warehouse_detail: {
      pickup_location_id: 112581,
      return_location_id: 112581,
    },
    consignee_detail: {
      first_name: shippingFirstName || firstName,
      last_name: shippingLastName || lastName,
      company_name: "",
      contact_number_primary: phone,
      contact_number_secondary: "",
      email_id: email,
      consignee_address: {
        address_line1: shippingStreetAddress || streetAddress,
        address_line2: shippingTownCity || townCity,
        address_landmark: "",
        pincode: pin || pincode,
      },
    },
    order_detail: {
      invoice_date: date.toISOString(),
      invoice_id: `INV-AR-${phone}-${Math.floor(
        1000 + Math.random() * 9000
      ).toString()}`,
      payment_type: "Prepaid",
      shipment_invoice_amount: subtotal,
      total_collectable_amount: 0,
      box_details: [
        {
          each_box_dead_weight: deadWeight,
          each_box_length: 15,
          each_box_width: deadWeight * 8,
          each_box_height: 25,
          each_box_invoice_amount: subtotal,
          each_box_collectable_amount: 0,
          box_count: 1,
          product_details: productDetails,
        },
      ],
      ewaybill_number: "",
      document_detail: {
        invoice_document_file: "",
        ewaybill_document_file: "",
      },
    },
  };

  const handleAddOrder = async () => {
    // if(!validateForm()){
    //     setAlertMessage('Please check form validations.')
    // }
    validateForm();

    setLoading(true);

    if (deadWeight > 30) {
      setAlertMessage(
        "For orders of more than 30kg please contact us directly on 9467843826."
      );
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/add-single-order`, {
        body: body,
      });

      if (response.status === 200) {
        const shippingData = response.data.data;
        const system_order_id = response.data.system_order_id;

        setSystemOrderId(system_order_id);
        setCourierId(shippingData.courier.courier_id);

        if (enableFixedShippingCost) {
          setShippingCost(fixedShippingCost);
        } else {
          setShippingCost(shippingData.courier_charge);
        }
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getTrafficData = () => {
    const trafficData = localStorage.getItem("trafficData");
    return trafficData ? JSON.parse(trafficData) : null;
  };

  const placeOrder = async ({ status, orderId, paymentId }) => {
    setLoading(true);

    if (status === 200 && systemOrderId && courierId) {
      const body = {
        system_order_id: systemOrderId,
        courier_id: courierId,
      };

      try {
        const response = await axios.post(
          `${BACKEND_URL}/manifest-single-order`,
          body
        );

        if (response.status === 200) {
          try {
            const awbResponse = await axios.post(`${BACKEND_URL}/get-awb`, {
              system_order_id: systemOrderId,
            });

            const trafficData = getTrafficData();

            const orderData = {
              userId: email,
              orderStatus: "Placed",
              paymentStatus: "Completed",
              customerName: `${firstName} ${lastName}`,
              emailAddress: email,
              phoneNumber: phone,
              shippingAddress: `${shippingStreetAddress || streetAddress} ${
                shippingTownCity || townCity
              } ${shippingState || state} ${pin || pincode}`,
              billingAddress: `${streetAddress} ${shippingTownCity} ${state} ${pin}`,
              products: productsToSaveOrder,
              trackingNumber: awbResponse.data.awb,
              shippingCost: shippingCost,
              totalAmount: (discountedSubtotal || subtotal) + shippingCost,
              transactionId: paymentId,
              discounts: `${discount ? discount : ""}`,
              couponCode: discount && couponCode ? couponCode : "",
              utmDetails: trafficData,
            };

            const orderDetailsResponse = await axios.post(
              `${BACKEND_URL}/save-order-details`,
              orderData
            );
            const sendOrderSuccessMail = await axios.post(
              `${BACKEND_URL}/api/applications/send-order-placed-message`,
              {
                name: firstName,
                email: email,
                trackingNumber: awbResponse.data.awb,
              }
            );
            setAlertMessage("Order placed successfully");
            if (window.gtag) {
              window.gtag("event", "conversion", {
                send_to: "AW-11460015937/392dCIDsko0ZEMHux9gq",
                transaction_id: orderId,
                value: (discountedSubtotal || subtotal) + shippingCost,
                currency: "INR",
              });
            } else {
              console.error("Google Ads tracking not initialized");
            }

            navigate("/thank-you");
            cart.forEach((product) => {
              removeFromCart(product.product._id);
            });
          } catch (error) {
            const trafficData = getTrafficData();

            const orderData = {
              userId: email,
              orderStatus: "Need to Ship",
              paymentStatus: "Completed",
              customerName: `${firstName} ${lastName}`,
              emailAddress: email,
              phoneNumber: phone,
              shippingAddress: `${shippingStreetAddress || streetAddress} ${
                shippingTownCity || townCity
              } ${shippingState || state} ${pin || pincode}`,
              billingAddress: `${streetAddress} ${shippingTownCity} ${state} ${pin}`,
              products: productsToSaveOrder,
              shippingCost: shippingCost,
              totalAmount: (discountedSubtotal || subtotal) + shippingCost,
              transactionId: paymentId,
              discounts: `${discount ? discount : ""}`,
              couponCode: discount && couponCode ? couponCode : "",
              utmDetails: trafficData,
            };

            const orderDetailsResponse = await axios.post(
              `${BACKEND_URL}/save-order-details`,
              orderData
            );
            const sendOrderSuccessMail = await axios.post(
              `${BACKEND_URL}/api/applications/send-order-placed-message`,
              {
                name: firstName,
                email: email,
               
              }
            );
            
            if (window.gtag) {
              window.gtag("event", "conversion", {
                send_to: "AW-11460015937/392dCIDsko0ZEMHux9gq",
                transaction_id: orderId,
                value: (discountedSubtotal || subtotal) + shippingCost,
                currency: "INR",
              });
            } else {
              console.error("Google Ads tracking not initialized");
            }

            navigate("/thank-you");
            cart.forEach((product) => {
              removeFromCart(product.product._id);
            });
            setAlertMessage("Order placed successfully.Our team will ship your order");
           
          }
        } else {
          const trafficData = getTrafficData();

            const orderData = {
              userId: email,
              orderStatus: "Need to Ship",
              paymentStatus: "Completed",
              customerName: `${firstName} ${lastName}`,
              emailAddress: email,
              phoneNumber: phone,
              shippingAddress: `${shippingStreetAddress || streetAddress} ${
                shippingTownCity || townCity
              } ${shippingState || state} ${pin || pincode}`,
              billingAddress: `${streetAddress} ${shippingTownCity} ${state} ${pin}`,
              products: productsToSaveOrder,
              shippingCost: shippingCost,
              totalAmount: (discountedSubtotal || subtotal) + shippingCost,
              transactionId: paymentId,
              discounts: `${discount ? discount : ""}`,
              couponCode: discount && couponCode ? couponCode : "",
              utmDetails: trafficData,
            };

            const orderDetailsResponse = await axios.post(
              `${BACKEND_URL}/save-order-details`,
              orderData
            );
            const sendOrderSuccessMail = await axios.post(
              `${BACKEND_URL}/api/applications/send-order-placed-message`,
              {
                name: firstName,
                email: email,
               
              }
            );
            
            if (window.gtag) {
              window.gtag("event", "conversion", {
                send_to: "AW-11460015937/392dCIDsko0ZEMHux9gq",
                transaction_id: orderId,
                value: (discountedSubtotal || subtotal) + shippingCost,
                currency: "INR",
              });
            } else {
              console.error("Google Ads tracking not initialized");
            }

            navigate("/thank-you");
            cart.forEach((product) => {
              removeFromCart(product.product._id);
            });
            setAlertMessage("Order placed successfully.Our team will ship your order");
           
        }
      } catch (error) {
        const trafficData = getTrafficData();

            const orderData = {
              userId: email,
              orderStatus: "Need to Ship",
              paymentStatus: "Completed",
              customerName: `${firstName} ${lastName}`,
              emailAddress: email,
              phoneNumber: phone,
              shippingAddress: `${shippingStreetAddress || streetAddress} ${
                shippingTownCity || townCity
              } ${shippingState || state} ${pin || pincode}`,
              billingAddress: `${streetAddress} ${shippingTownCity} ${state} ${pin}`,
              products: productsToSaveOrder,
              shippingCost: shippingCost,
              totalAmount: (discountedSubtotal || subtotal) + shippingCost,
              transactionId: paymentId,
              discounts: `${discount ? discount : ""}`,
              couponCode: discount && couponCode ? couponCode : "",
              utmDetails: trafficData,
            };

            const orderDetailsResponse = await axios.post(
              `${BACKEND_URL}/save-order-details`,
              orderData
            );
            const sendOrderSuccessMail = await axios.post(
              `${BACKEND_URL}/api/applications/send-order-placed-message`,
              {
                name: firstName,
                email: email,
               
              }
            );
            
            if (window.gtag) {
              window.gtag("event", "conversion", {
                send_to: "AW-11460015937/392dCIDsko0ZEMHux9gq",
                transaction_id: orderId,
                value: (discountedSubtotal || subtotal) + shippingCost,
                currency: "INR",
              });
            } else {
              console.error("Google Ads tracking not initialized");
            }

            navigate("/thank-you");
            cart.forEach((product) => {
              removeFromCart(product.product._id);
            });
            setAlertMessage("Order placed successfully.Our team will ship your order");
           
      } finally {
        setLoading(false);
      }
    } else if (!systemOrderId || !courierId) {
      setAlertMessage("Please click Proceed to Pay before payment.");
    } else {
      setLoading(false); // End loading if payment details are not valid
      setAlertMessage("Invalid payment details. Cannot place order.");
    }
  };

  const shouldDisableFields = systemOrderId && courierId;
  return (
    <>
      <div className="checkout-container">
        {loading && <div className="loader"></div>}
        <div className="page-info">
          <h1>Check Out</h1>
          <div className="page-info-navigation">
            <div
              className="page-info-navigation-icons"
              onClick={() => {
                navigate("/");
              }}
            >
              <FontAwesomeIcon icon={faHouse}></FontAwesomeIcon>
            </div>
          </div>
        </div>
        <AlertPopup
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />

        <h3 onClick={handleCouponVisibility}>
          Have a coupon?Click here to enter your code
        </h3>
        {isCouponCodeVisible ? (
          <div className="couponcode-container">
            <div className="coupons-container">
              <h1>Available Coupons</h1>
              {sales.length === 0 ? (
                <p>No sales coupons available.</p>
              ) : (
                <div className="coupon-cards">
                  {sales
                    .filter((coupon) => coupon.isPromotional === false)
                    .map((coupon) => (
                      <div className="coupon-card" key={coupon._id}>
                        <h2>{coupon.couponCode}</h2>
                        <p>
                          <strong>Discount:</strong>{" "}
                          {coupon.discountType === "percentage"
                            ? `${coupon.discount}%`
                            : `₹${coupon.discount}`}
                        </p>
                        <p>
                          <strong>Min Purchase:</strong> ₹{coupon.minPurchase}
                        </p>
                        <p>
                          <strong>
                            Expires on{" "}
                            {new Intl.DateTimeFormat("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                            })
                              .format(new Date(coupon.endDate))
                              .replace(/\//g, "-")}
                          </strong>
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <p>If you have a coupon code,please apply it below.</p>
            <div className="coupon-code">
              <input
                type="text"
                id="coupon"
                onChange={(e) => {
                  setCouponCode(e.target.value);
                }}
                placeholder="Coupon Code"
              />
              <button
                onClick={() => applyCoupon(couponCode)}
                className="checkout-container-button"
              >
                Apply Coupon
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="checkout-row-contaner">
          <div className="billing-container">
            <div className="billing-details">
              <h1>Billing Details</h1>
              <div className="billing-form">
                <div className="text-input">
                  <label htmlFor="firstName">
                    First name <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    required
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  ></input>
                </div>
                {errors.firstName ? (
                  <div className="error-message">{errors.firstName}</div>
                ) : (
                  ""
                )}

                <div className="text-input">
                  <label htmlFor="lastName">
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  />
                  {errors.lastName ? (
                    <div className="error-message">{errors.lastName}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-input">
                  <label htmlFor="country">
                    Country/Region <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="country"
                    value="India"
                    disabled={shouldDisableFields}
                  />
                </div>
                <div className="text-input">
                  <label htmlFor="streetAddress">
                    Street Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="streetAddress"
                    required
                    onChange={(e) => {
                      setStreetAddress(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  />
                </div>
                {errors.streetAddress ? (
                  <div className="error-message">{errors.streetAddress}</div>
                ) : (
                  ""
                )}
                <div className="text-input">
                  <label htmlFor="townCity">
                    Town/City <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="townCity"
                    required
                    onChange={(e) => {
                      setTownCity(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  />
                </div>
                {errors.townCity ? (
                  <div className="error-message">{errors.townCity}</div>
                ) : (
                  ""
                )}
                <div className="text-input">
                  <label htmlFor="state">
                    State <span>*</span>
                  </label>
                  <select
                    id="state"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  >
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-input">
                  <label htmlFor="pincode">
                    Pincode <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="pincode"
                    required
                    onChange={(e) => {
                      setPin(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  />
                </div>
                {errors.pin ? (
                  <div className="error-message">{errors.pin}</div>
                ) : (
                  ""
                )}

                <div className="text-input">
                  <label htmlFor="phone">
                    Phone<span>*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    required
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  />
                </div>
                {errors.phone ? (
                  <div className="error-message">{errors.phone}</div>
                ) : (
                  ""
                )}

                <div className="text-input">
                  <label htmlFor="email">
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    disabled={shouldDisableFields}
                  />
                </div>
                {/* <div className='check-box'>
                     <label>
                        <input 
                        type="checkbox" 
                        checked={signUpcheck} 
                        onChange={(e)=>{setSignUpcheck(!signUpcheck)}} 
                       />
                      Sign me up to receive email updates and news. (optional)
                     </label>
                    </div> */}
              </div>
            </div>
            <div className="ship-to-different">
              <div className="check-box">
                <label>
                  <input
                    type="checkbox"
                    checked={isShippingAddressDifferent}
                    onChange={(e) => {
                      setIsShippingAddressDifferent(
                        !isShippingAddressDifferent
                      );
                    }}
                    disabled={shouldDisableFields}
                  />
                  Ship to a different address?
                </label>
              </div>
              {isShippingAddressDifferent ? (
                <div className="shipping-form">
                  <div className="text-input">
                    <label htmlFor="shippingFirstName">
                      First name <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shippingFirstName"
                      required
                      onChange={(e) => {
                        setShippingFirstName(e.target.value);
                      }}
                      disabled={shouldDisableFields}
                    ></input>
                  </div>
                  {errors.shippingFirstName ? (
                    <div className="error-message">
                      {errors.shippingFirstName}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-input">
                    <label htmlFor="shippingLastName">
                      Last Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shippingLastName"
                      required
                      onChange={(e) => {
                        setShippingLastName(e.target.value);
                      }}
                      disabled={shouldDisableFields}
                    />
                  </div>
                  {errors.shippingLastName ? (
                    <div className="error-message">
                      {errors.shippingLastName}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="text-input">
                    <label htmlFor="shippingCountry">
                      Country/Region <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shippingCountry"
                      value="India"
                      disabled={shouldDisableFields}
                    />
                  </div>
                  <div className="text-input">
                    <label htmlFor="shippingStreetAddress">
                      Street Address <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shippingStreetAddress"
                      required
                      onChange={(e) => {
                        setShippingStreetAddress(e.target.value);
                      }}
                      disabled={shouldDisableFields}
                    />
                  </div>
                  {errors.shippingStreetAddress ? (
                    <div className="error-message">
                      {errors.shippingStreetAddress}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-input">
                    <label htmlFor="shippingTownCity">
                      Town/City <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shippingTownCity"
                      required
                      onChange={(e) => {
                        setShippingTownCity(e.target.value);
                      }}
                      disabled={shouldDisableFields}
                    />
                  </div>
                  {errors.shippingTownCity ? (
                    <div className="error-message">
                      {errors.shippingTownCity}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-input">
                    <label htmlFor="ShippingState">
                      State <span>*</span>
                    </label>
                    <select
                      id="shippingState"
                      value={state}
                      onChange={(e) => {
                        setShippingState(e.target.value);
                      }}
                      disabled={shouldDisableFields}
                    >
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="text-input">
                    <label htmlFor="shippingPincode">
                      Pincode <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="shippingPincode"
                      required
                      onChange={(e) => {
                        setShippingPincode(e.target.value);
                      }}
                      disabled={shouldDisableFields}
                    />
                  </div>
                  {errors.pincode ? (
                    <div className="error-message">{errors.pincode}</div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <button
              onClick={handleAddOrder}
              className="checkout-container-button"
            >
              Proceed to Pay
            </button>
          </div>

          <div className="order-details">
            <h5>Your Order</h5>
            <table>
              <thead>
                <tr>
                  <th>Product x Quantity</th>
                  <th>SubTotal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {cart.map((product) => (
                      <p>
                        {product.product.title} X {product.quant}
                      </p>
                    ))}
                  </td>
                  <td>
                    {cart.map((product) => (
                      <p>{product.product.discountPrice * product.quant}</p>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td>{subtotal}</td>
                </tr>
                {discount > 0 && (
                  <tr>
                    <td>Offer Applied</td>
                    <td>{discount}</td>
                  </tr>
                )}
                <tr>
                  <td>Shipping</td>
                  <td>{shippingCost}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td style={{ color: "#e42727" }}>
                    <b>{(discountedSubtotal || subtotal) + shippingCost}</b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Payment
              onPaymentSuccess={handlePaymentSuccess}
              amount={(discountedSubtotal || subtotal) + shippingCost}
              systemOrderId={systemOrderId}
              courierId={courierId}
              email={email}
              phone={phone}
              customername={firstName}
            />
          </div>
        </div>
      </div>
      <Navbar isHomePage={false} />
    </>
  );
}

export default Checkout;
