import React, { useState, useContext ,useMemo} from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../AppContext';
import '../Styles/ReviewPopup.css';
import Navbar from './Navbar';

const ReviewPopup = () => {
    const { productId } = useParams();
     
    const location=useLocation()
    const topReviews=location.state?.topReviews || [];

   
   
    const navigate = useNavigate();

    const [username, setUsername] = useState( ''); 
    const [rating, setRating] = useState(0);
    const [reviewMessage, setReviewMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const email=localStorage.getItem('email')
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

     

    const handleSubmit = async () => {
        if (rating === 0 || reviewMessage === '' || username === '') {
            setErrorMessage("Please provide a username, rating, and review message.");
            return;
        }

        try {
          
            await axios.post(`${BACKEND_URL}/create-review`, {
                productId,
                email:email,
                username,  
                stars: rating,
                reviewDescription: reviewMessage,
            });

            const updatedRating = calculateNewRating(topReviews);
            
            const body={
                rating:updatedRating
            }
           await axios.patch(`${BACKEND_URL}/products?_id=${productId}`, body);
           
            
            navigate(-1); 
          } catch (error) {
            setErrorMessage("Error posting review. Please try again.");
        }
    };

    const calculateNewRating = (reviews) => {
    if (!reviews || reviews.length === 0) return 0;
    const totalStars = reviews.reduce((sum, review) => sum + review.stars, 0);
    
    return totalStars / reviews.length;
};

    return (
        <>
        <div className="review-popup">
            <h2>Write a Review</h2>

            <div className="form-group">
                <label>Username:</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your username"
                />
            </div>

            <div className="form-group">
                <label>Rating:</label>
                <select value={rating} onChange={(e) => setRating(Number(e.target.value))}>
                    <option value="0">Select Rating</option>
                    <option value="1">⭐</option>
                    <option value="2">⭐⭐</option>
                    <option value="3">⭐⭐⭐</option>
                    <option value="4">⭐⭐⭐⭐</option>
                    <option value="5">⭐⭐⭐⭐⭐</option>
                </select>
            </div>

            <div className="form-group">
                <label>Review:</label>
                <textarea 
                    value={reviewMessage}
                    onChange={(e) => setReviewMessage(e.target.value)}
                    rows="4"
                    placeholder="Write your review here..."
                ></textarea>
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button className="submit-button" onClick={handleSubmit}>Post Review</button>
            
        </div>
        <Navbar isHomePage={false}/>
        </>
    );
};

export default ReviewPopup;

