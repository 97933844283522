import React, { useState } from "react";
import axios from "axios";

const UpdateSlideForm = ({ slide, onUpdate }) => {
  const [formData, setFormData] = useState({
    title: slide.title,
    description: slide.description,
    imgSrc: slide.imgSrc,
    mobileImgSrc: slide.mobileImgSrc,
    id: slide.id,
  });

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Convert a file to base64 and update the relevant state field
  // const handleFileChange = (e, fieldName) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFormData((prevData) => ({ ...prevData, [fieldName]: reader.result }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleFileChange = async (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(`${BACKEND_URL}/upload-files`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (response.ok) {
          setFormData((prevData) => ({ ...prevData, [fieldName]: data.url }));
        } else {
          console.error("Error uploading file:", data.error);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "formdata for updates");

    try {
      const response = await axios.put(
        `${BACKEND_URL}/slides/update-slides/${slide._id}`,
        formData
      );
      onUpdate(response.data); // Update parent state with new slide data
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="slider-update-input">
        <label>Title:</label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
      </div>
      <div className="slider-update-input">
        <label>Description:</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        ></textarea>
      </div>
      <div className="slider-update-input">
        <label>ID</label>
        <input
          type="text"
          name="id"
          value={formData.id}
          onChange={handleInputChange}
        ></input>
      </div>
      <div className="slider-update-input">
        <label>Upload Image (Desktop):</label>
        <input type="file" onChange={(e) => handleFileChange(e, "imgSrc")} />
      </div>
      <div className="slider-update-input">
        <label>Upload Image (Mobile):</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, "mobileImgSrc")}
        />
      </div>
      <button type="submit">Update Slide</button>
    </form>
  );
};

export default UpdateSlideForm;
