import React from 'react'
import '../../Styles/Advantages.css'
import alternative from '../../Assets/advantages/alternative.png'
import chemicalfree from '../../Assets/advantages/chemicalfree.png'
import quality from '../../Assets/advantages/quality.png'
import hygiene from '../../Assets/advantages/hygiene.png'
import allProducts from '../../Assets/advantages/all.png'



function Advantages() {
  return (
    <div className='advantages-container'>
      
        
        <div className='advantage-header'>
            <h1>Welcome To</h1>
            <h1> <span style={{color:'#E42727'}}>Aryadhenu</span> <span style={{color:'#109031'}}>Healthy </span> <span style={{color:'#f9c12e'}}>Family</span></h1>
        </div>
        <div className='advantage-cards'>
            <div className='alternative-card'>
                <div className='alternative-card-icon'>
                  <img src={alternative} alt='alternative logo'/>
                </div>
                <h3>Best White Sugar Alternative</h3>
                <p>Aryadhenu <strong>Shudh Desi Khand</strong> is the perfect <strong>natural alternative</strong> to refined sugar, offering a rich aroma, authentic taste, and essential minerals that support overall well-being.</p>
                
            </div>
            <div className='chemicalfree-card'>
                <div className='chemicalfree-card-icon'>
                  <img src={chemicalfree} alt='chemicalfree logo'/>
                </div>
                <h3>100% Chemical-Free</h3>
                <p>Our products are 100% pure, unadulterated, free from chemicals and sulfur, and do not contain Artificial flavours which are commonly found in white sugar.</p>
                
            </div>
            <div className='quality-card'>
                <div className='quality-card-icon'>
                  <img src={quality} alt='quality logo'/>
                </div>
                <h3>No Compromise with Quality</h3>
                <p>Quality is our top priority, and we make no compromises. Made from best quality sugarcane & undergoes stringent quality checks.</p>
                
            </div>
            <div className='hygiene-card'>
                <div className='hygiene-card-icon'>
                  <img src={hygiene} alt='hygiene logo'/>
                </div>
                <h3>Hygienically Packed</h3>
                <p>Aryadhenu Products are hygienically packed with utmost care during packaging.</p>
                
            </div>
        </div>
        <img src={allProducts} alt='All products' className='allproducts-image'/>
        
        
    </div>
  )
}

export default Advantages