import React from 'react'
import '../Styles/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../Assets/Aryadhenu_Logo.png'
import {faPhone,faEnvelope,faLandmark,faLocationDot, faP, faX} from '@fortawesome/free-solid-svg-icons'
import { faTwitter,faFacebookF ,faPinterest,faInstagram, faYoutube, faSquareXTwitter, faAmazon} from '@fortawesome/free-brands-svg-icons'

import { Link } from 'react-router-dom'




function Footer() {
  return (
    <div className='footer-container'>
    <div className='footer'>
        <div className='brand-container'>
            <img src={Logo} alt='Aryadhenu logo'/>
            <p>Aryadhenu Natural Diet: A Heritage of Purity and Wellness. We bring tradition to your table by crafting authentic, Sulphur & Chemical-free sweeteners. 
            </p>
            <p>The One & Only Best healthy alternative of the White Sugar (Slow Posion) is Aryadhenu Shudh Desi Khand.</p>

            <h5>Healthy Family, Happy Family! 😊🌱</h5>
            <div className='social'>
                <a href='https://x.com/Aryadhenu_' target='_black'>
                <FontAwesomeIcon icon={faSquareXTwitter} style={{color:'#ffffff'}}/>
                </a>
                <a href='https://www.facebook.com/aryadhenu' target="_blank">
                <FontAwesomeIcon icon={faFacebookF} style={{color:'#ffffff'}}/>
                </a>
                <a href='https://youtube.com/@aryadhenu?si=lgJJjYGpXszEvD7d' target='_blank'>
                <FontAwesomeIcon icon={faYoutube} style={{color:'#ffffff', marginTop:'5px'}}/>
                </a>
                <a href='https://www.instagram.com/aryadhenu.natural.diet' target='_blank'>
                <FontAwesomeIcon icon={faInstagram} style={{color:'#ffffff'}}/>
                </a>
                
            </div>
            <p style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>We sell on amazon too. <a href='https://www.amazon.in/stores/Aryadhenu/page/2F059C34-3F95-44E6-8C4A-545AE544C6F4?ref_=ast_bln' target='_black'><FontAwesomeIcon icon={faAmazon} style={{color:'#FF9900',width:'50px',height:'50px'}}/></a></p>
        </div>
        <div className='side-border'></div>
        <div className='links'>
            <h5>Links</h5>
            <a href='/refund-policy'>Return/Refund Policy</a>
            <a href='/terms-and-conditions'>Terms & Conditions of Use</a>
            <a href='/shipping-policy'>Shipping Policy</a>
            <a href='/privacy-policy'>Privacy Policy</a>
            <a href='/business-enquiry'>Business Enquiry</a>
            
        </div>
         <div className='links-one'>
            <h5>Accounts</h5>
            <a href='/user-dashboard'>Dashboard</a>
            <a href='/user-dashboard'>Orders</a>
            <a href='/careers'>Careers</a>
            <a href='/blogs'>Blog</a>
        </div>
        <div className='links-one'>
            <h5>Nav Links</h5>
                <a href='/'>Home</a>
                <a href='/shop'>Shop</a>
                <a href='/about'>About</a>
                <a href='/contact'>Contact</a>
        </div>

        <div className='contact-details'>
            <h5>Contact Us</h5>
            <p><FontAwesomeIcon icon={faLocationDot} style={{color:'#ffffff'}}/> Village Balawas, PO Nalwa, Hisar, Haryana 125037, India</p>
            <p><FontAwesomeIcon icon={faEnvelope}  style={{color:'#ffffff'}}/> customercare@aryadhenu.com</p>
            <p><FontAwesomeIcon icon={faPhone}  style={{color:'#ffffff'}}/> +91 9467843826</p>
        </div>
    </div>
    <div className='end-border'></div>
    <div className='copyright'>
        <p style={{marginBottom:'0px'}}>Copyright &#169; 2021 Aryadhenu</p>
    </div>
    </div>
  )
}

export default Footer