import React, { useState, useEffect } from "react";
import "../Styles/Contact.css";
import contactBanner from "../Assets/contactus-banner.jpeg";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faPhone,
  faEnvelope,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import AlertPopup from "./AlertPopup";

const BulkOrderForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^(0?[6-9][0-9]{9})$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits & start with 6-9";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setAlertMessage("Please fill all required fields correctly.");
      return;
    }

    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/applications/bulk-order`,
        formData
      );
      if (response.status === 200) {
        setAlertMessage("Bulk order request submitted successfully.");
        setFormData({
          fullName: "",
          companyName: "",
          email: "",
          phone: "",
          message: "",
        });
        setErrors({});
      } else {
        setAlertMessage("Could not submit your request. Please try again.");
      }
    } catch {
      setAlertMessage("Failed to submit the form.");
    }
  };

  return (
    <div className="contact-container">
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <AlertPopup
            message={alertMessage}
            onClose={() => setAlertMessage("")}
          />

          <div className="page-info">
            <h1>Bulk Order</h1>
            <div className="page-info-navigation">
              <div
                className="page-info-navigation-icons"
                onClick={() => navigate("/")}
              >
                <FontAwesomeIcon icon={faHouse} />
              </div>
            </div>
          </div>

          <div className="contact-details-container">
            <div className="contact-form">
              <h5>Bulk Orders</h5>

              <div className="input-fields">
                <div className="input">
                  <label>
                    Full Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                  {errors.fullName && (
                    <div className="error-message">{errors.fullName}</div>
                  )}
                </div>

                <div className="input">
                  <label>
                    Company Name (Optional)
                    <span style={{ visibility: "hidden" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="input-fields">
                <div className="input">
                  <label>
                    Email Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>

                <div className="input">
                  <label>
                    Phone Number <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <div className="error-message">{errors.phone}</div>
                  )}
                </div>
              </div>

              <div className="input-fields">
                <div className="input">
                  <label>
                    Message <span>*</span>
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Add Product Name, Quantity Required, Packaging Preference, Delivery Location etc."
                  />
                  {errors.message && (
                    <div className="error-message">{errors.message}</div>
                  )}
                </div>
              </div>

              <button onClick={handleSubmit}>Submit Order</button>
            </div>

            <div className="contact-information">
              <div className="information-section">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} style={{ color: "#fff" }} />
                </div>
                <h5>Call Us Now :</h5>
                <p>+91 94678 43826</p>
                <div className="border"></div>
              </div>

              <div className="information-section">
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: "#fff" }}
                  />
                </div>
                <h5>Email :</h5>
                <p>customercare@aryadhenu.com</p>
                <div className="border"></div>
              </div>

              <div className="information-section">
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faLocationArrow}
                    style={{ color: "#fff" }}
                  />
                </div>
                <h5>Our Address :</h5>
                <p>Village Balawas, PO Nalwa</p>
                <p>Hisar, Haryana 125037, India</p>
                <div className="border"></div>
              </div>
            </div>
          </div>
        </>
      )}

      <Navbar isHomePage={false} />
    </div>
  );
};

export default BulkOrderForm;
