import React, { useState } from "react";
import axios from "axios";
import "../Styles/addProducts.css"; // Reuse the CSS for consistent styling
import AlertPopup from "./AlertPopup";

const UpdateProduct = () => {
  const [productId, setProductId] = useState("");
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    title: "",
    imageUrl: "",
    quotePrice: "",
    discountPrice: "",
    fixedCost: "",
    shortDescription: "",
    brand: "",
    weight: "",
    dietType: "",
    package: "",
    category: "",
    longDescription: "",
    rating: "",
    isStockAvailable: true,
    isFeatured: null,
    deadWeight: "",
    specifications: "",
    description: "",
  });
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "isFeatured" || name === "isStockAvailable"
          ? value === "true"
          : value,
    });
  };

  // const handleImageUpload = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             setFormData({ ...formData, imageUrl: reader.result });
  //         };
  //         reader.readAsDataURL(file);
  //     }
  // };

  const handleImageUpload = async (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(`${BACKEND_URL}/upload-files`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (response.ok) {
          setFormData((prevData) => ({ ...prevData, imageUrl: data.url }));
        } else {
          console.error("Error uploading file:", data.error);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {};
    if (formData.title) body.title = formData.title;
    if (formData.imageUrl) body.imageUrl = formData.imageUrl;
    if (formData.quotePrice) body.quotePrice = formData.quotePrice;
    if (formData.discountPrice) body.discountPrice = formData.discountPrice;
    if (formData.shortDescription)
      body.shortDescription = formData.shortDescription;
    if (formData.brand) body.brand = formData.brand;
    if (formData.weight) body.weight = formData.weight;
    if (formData.dietType) body.dietType = formData.dietType;
    if (formData.package) body.package = formData.package;
    if (formData.category) body.category = formData.category;
    if (formData.longDescription)
      body.longDescription = formData.longDescription;
    if (formData.rating) body.rating = formData.rating;
    if (formData.isStockAvailable !== undefined)
      body.isStockAvailable = formData.isStockAvailable;
    if (formData.isFeatured !== null) body.isFeatured = formData.isFeatured;
    if (formData.deadWeight) body.deadWeight = formData.deadWeight;
    if (formData.specifications)
      body.specifications = formData.specifications
        .split("\n")
        .filter((paragraph) => paragraph.trim() !== "");
    if (formData.description)
      body.description = formData.description
        .split("\n")
        .filter((paragraph) => paragraph.trim() !== "");
    if (formData.fixedCost) body.fixedShippingCost = formData.fixedCost;

    try {
      const response = await axios.patch(
        `${BACKEND_URL}/products?_id=${productId}`,
        body
      );
      setAlertMessage(response.data.message);
      setFormData({
        title: "",
        imageUrl: "",
        quotePrice: "",
        discountPrice: "",
        shortDescription: "",
        brand: "",
        weight: "",
        dietType: "",
        package: "",
        category: "",
        longDescription: "",
        rating: "",
        isStockAvailable: true,
        isFeatured: null,
        deadWeight: "",
        specifications: "",
        description: "",
        fixedCost: "",
      });
    } catch (error) {
      setAlertMessage("Failed to update product.");
    }
  };

  return (
    <div className="add-products">
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage("")} />
      <div className="add-products-card">
        <form className="add-products-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Product ID *"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            required
          />
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleChange}
          />
          <input type="file" accept="image/*" onChange={handleImageUpload} />
          <input
            type="number"
            name="quotePrice"
            placeholder="Quote Price"
            value={formData.quotePrice}
            onChange={handleChange}
          />
          <input
            type="number"
            name="discountPrice"
            placeholder="Discount Price"
            value={formData.discountPrice}
            onChange={handleChange}
          />
          <input
            type="text"
            name="shortDescription"
            placeholder="Short Description"
            value={formData.shortDescription}
            onChange={handleChange}
          />
          <input
            type="text"
            name="brand"
            placeholder="Brand"
            value={formData.brand}
            onChange={handleChange}
          />
          <input
            type="text"
            name="weight"
            placeholder="Weight"
            value={formData.weight}
            onChange={handleChange}
          />
          <input
            type="text"
            name="dietType"
            placeholder="Diet Type"
            value={formData.dietType}
            onChange={handleChange}
          />
          <input
            type="text"
            name="package"
            placeholder="Package"
            value={formData.package}
            onChange={handleChange}
          />
          <input
            type="text"
            name="category"
            placeholder="Category"
            value={formData.category}
            onChange={handleChange}
          />
          {/* <textarea name="longDescription" placeholder="Long Description" value={formData.longDescription} onChange={handleChange} /> */}
          <textarea
            name="description"
            placeholder="Description (Separate paragraphs with line breaks)"
            value={formData.description}
            onChange={handleChange}
          />
          <input
            type="number"
            min="0"
            max="5"
            name="rating"
            placeholder="Rating"
            value={formData.rating}
            onChange={handleChange}
          />
          <input
            type="text"
            name="fixedCost"
            placeholder="Fixed Shipping Cost" // Input for fixed cost
            value={formData.fixedCost}
            onChange={handleChange}
          />
          {/* <div className="checkbox-container">
                        <label>
                            <input type="checkbox" name="isStockAvailable" checked={formData.isStockAvailable} onChange={handleChange} /> Stock Available
                        </label>
                        <label>
                            <input type="checkbox" name="isFeatured" checked={formData.isFeatured} onChange={handleChange} /> Featured
                        </label>
                    </div> */}

          <div className="input-group">
            <label htmlFor="stock-availability">Availability in Stock</label>
            <select
              id="stock-availability"
              name="isStockAvailable"
              value={formData.isStockAvailable}
              onChange={handleChange}
              required
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="featured">Featured?</label>
            <select
              id="featured"
              name="isFeatured"
              value={formData.isFeatured}
              onChange={handleChange}
              required
            >
              <option value="">No Selection</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <input
            type="text"
            name="deadWeight"
            placeholder="Dead Weight"
            value={formData.deadWeight}
            onChange={handleChange}
          />
          <textarea
            name="specifications"
            placeholder="Specifications (Separate paragraphs with line breaks)"
            value={formData.specifications}
            onChange={handleChange}
          />
          <button type="submit">Update Product</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateProduct;
