import React, { useState } from 'react'
import '../Styles/DistributorForm.css'

function DistributorForm() {

  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});

  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(currentPage - 1);

  

  return (
    <div className='distributor-form'>
        {currentPage === 1 && (
        <FormPage1
          onNext={nextPage}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {currentPage === 2 && (
        <FormPage2
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {currentPage === 3 && (
        <FormPage3
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      
      {currentPage === 4 && (
        <FormPage4
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
        />
      )} 
      {currentPage === 5 && (
        <FormPage5
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
          
        />
      )}
    </div>
  )
}

export default DistributorForm


function FormPage1({ onNext, formData, setFormData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='form'>
      <label>
        Full Name:
    </label>
        <input
          type="text"
          name="name"
          value={formData.name || ''}
          onChange={handleChange}
        />
      
      <label>
        Phone Number:
        </label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber || ''}
          onChange={handleChange}
        />
    
      <label>
        Email:
        </label>
        <input
          type="text"
          name="email"
          value={formData.email || ''}
          onChange={handleChange}
        />
      
      <label>
        Firm Name:
        </label>
        <input
          type="text"
          name="firmName"
          value={formData.firmName || ''}
          onChange={handleChange}
        />
      
      <label>
        GST Number:
        </label>
        <input
          type="text"
          name="gstin"
          value={formData.gstin || ''}
          onChange={handleChange}
        />
      
      <label>
        Address:
         </label>
        <input
          type="text"
          name="address"
          value={formData.address || ''}
          onChange={handleChange}
        />
     
      <label>
        State:
        </label>
        <input
          type="text"
          name="state"
          value={formData.state || ''}
          onChange={handleChange}
        />
      
      <label>
        City:
        </label>
        <input
          type="text"
          name="city"
          value={formData.city || ''}
          onChange={handleChange}
        />
      
      <button onClick={onNext}>Next</button>
    </div>
  );
}

function FormPage2({onNext,onPrevious,formData,setFormData}){

    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='form'>
        <div className='radio'>
        <p>How long have you been in the FMCG distribution business?</p>
        <div className='options'>
        <div className='option'>
        <input
            type="radio"
            
            name="FMCGBusinessDuration"
            value="3-5 years"
            checked={formData.FMCGBusinessDuration === "3-5 years"}
            onChange={handleChange}
          />
        <label >
             3-5 years
        </label>
        </div>
          
         <div className='option'>
        
          <input
            type="radio"
            name="FMCGBusinessDuration"
            value="5-10 years"
            checked={formData.FMCGBusinessDuration === "5-10 years"}
            onChange={handleChange}
          />
         <label>
          5-10 years
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="FMCGBusinessDuration"
            value="10-20 years"
            checked={formData.FMCGBusinessDuration === "10-20 years"}
            onChange={handleChange}
          />
          <label>
          10-20 years
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="FMCGBusinessDuration"
            value="more than 20 years"
            checked={formData.FMCGBusinessDuration === "more than 20 years"}
            onChange={handleChange}
          />
          <label>
          more than 20 years
        </label>
        </div>
        </div>
      </div>
      <div className='text-question'>
        <p>Please describe your experience in FMCG distribution. (Any Award,  inventory management or  sales operations etc.)</p>
        <input type='text' name='fmcgdistributionexp'
               value={formData.fmcgdistributionexp||''}
               onChange={handleChange}
        ></input>
      </div>
      <div className='text-question'>
        <p>Please provide the names of the FMCG brands you currently distribute.</p>
        <input type='text' name='fmcgdistributionbrands'
               value={formData.fmcgdistributionbrands||''}
               onChange={handleChange}
        ></input>
      </div>
      <div className='radio'>
        <p>What is your Annual Turnover?</p>
        <div className='options'>
        <div className='option'>
        <input
            type="radio"
            name="AnnualTurnOver"
            value="less than 10 lakhs"
            checked={formData.AnnualTurnOver === "less than 10 lakhs"}
            onChange={handleChange}
          />
        <label >
             less than 10 lakhs
        </label>
        </div>
          
         <div className='option'>
        
          <input
            type="radio"
            name="AnnualTurnOver"
            value="10-50 lakhs"
            checked={formData.AnnualTurnOver === "10-50 lakhs"}
            onChange={handleChange}
          />
         <label>
          10-50 lakhs
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="AnnualTurnOver"
            value="50 lakhs - 1 crore"
            checked={formData.AnnualTurnOver === "50 lakhs - 1 crore"}
            onChange={handleChange}
          />
          <label>
          50 lakhs - 1 crore
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="AnnualTurnOver"
            value="more than 1 crore"
            checked={formData.AnnualTurnOver === "more than 1 crore"}
            onChange={handleChange}
          />
          <label>
          more than 1 crore
        </label>
        </div>
        </div>
      </div>



      <button onClick={onPrevious}>Previous</button>
      <button onClick={onNext}>Next</button>
    </div>
  )

}

function FormPage3({onNext,onPrevious,formData,setFormData}){

    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

  };

  return (
    <div className='form'>

      <div className='text-question'>
        <p>Please provide the warehouse size.</p>
        <input type='text' name='warehouseSize'
               value={formData.warehouseSize||''}
               onChange={handleChange}
        ></input>
      </div>
      
      <div className='radio'>
        <p>What type of transaportation do you use for distribution?</p>
        <div className='options'>
        <div className='option'>
        <input
            type="radio"
            
            name="TransportationType"
            value="Own Vehicles"
            checked={formData.TransportationType === "Own Vehicles"}
            onChange={handleChange}
          />
        <label >
             Own Vehicles
        </label>
        </div>
          
         <div className='option'>
        
          <input
            type="radio"
            name="TransportationType"
            value="Rented Vehicles"
            checked={formData.TransportationType === "Rented Vehicles"}
            onChange={handleChange}
          />
         <label>
          Rented Vehicles
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="TransportationType"
            value="Third Party Logistics"
            checked={formData.TransportationType === "Third Party Logistics"}
            onChange={handleChange}
          />
          <label>
          Third Party Logistics
        </label>
        </div>
        
        
        </div>
      </div>
      <div className='text-question'>
        <p>How many sales persons do you have?</p>
        <input type='text' name='salesPersons'
               value={formData.salesPersons||''}
               onChange={handleChange}
        ></input>
      </div>

      



      <button onClick={onPrevious}>Previous</button>
      <button onClick={onNext}>Next</button>
    </div>
  )

}

function FormPage4({onNext,onPrevious,formData,setFormData}){

    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='form'>

      <div className='text-question'>
        <p>Which regions/areas do you currently cover?.</p>
        <input type='text' name='regions'
               value={formData.regions||''}
               onChange={handleChange}
        ></input>
      </div>
      
      <div className='radio'>
        <p>WHow many retail outlets do you supply to?</p>
        <div className='options'>
        <div className='option'>
        <input
            type="radio"
            
            name="RetailOutlets"
            value="Less than 50"
            checked={formData.RetailOutlets === "Less than 50"}
            onChange={handleChange}
          />
        <label >
             Less than 50
        </label>
        </div>
          
         <div className='option'>
        
          <input
            type="radio"
            name="RetailOutlets"
            value="50-100"
            checked={formData.RetailOutlets === "50-100"}
            onChange={handleChange}
          />
         <label>
          50-100
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="RetailOutlets"
            value="100-200"
            checked={formData.RetailOutlets === "100-200"}
            onChange={handleChange}
          />
          <label>
          100-200
        </label>
        </div>
        <div className='option'>
        
          <input
            type="radio"
           name="RetailOutlets"
            value="more than 200"
            checked={formData.RetailOutlets === "more than 200"}
            onChange={handleChange}
          />
          <label>
          more than 200
        </label>
        </div>
        
        
        </div>
      </div>
      

      



      <button onClick={onPrevious}>Previous</button>
      <button onClick={onNext}>Next</button>
    </div>
  )

}


function FormPage5({onPrevious,formData,setFormData}){

    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
   const handleSubmit=async()=>{

    
   }
  return (
    <div className='form'>
      
      <div className='radio'>
        <p>Do you have necessary Lincence and Permission to distribute FMCG?</p>
        <div className='options'>
        <div className='option'>
        <input
            type="radio"
            
            name="Permissions"
            value="Yes"
            checked={formData.Permissions === "Yes"}
            onChange={handleChange}
          />
        <label >
             Yes
        </label>
        </div>
        <div className='option'>
        <input
            type="radio"
            
            name="Permissions"
            value="No"
            checked={formData.Permissions === "No"}
            onChange={handleChange}
          />
        <label >
             No
        </label>
        </div>
        
          
         
        
        
        </div>
      </div>

       <div className='radio'>
        <p>Have you ever been involved in any legal disputes related to your business?</p>
        <div className='options'>
        <div className='option'>
        <input
            type="radio"
            
            name="LegalDisputes"
            value="Yes"
            checked={formData.LegalDisputes === "Yes"}
            onChange={handleChange}
          />
        <label >
             Yes
        </label>
        </div>
        <div className='option'>
        <input
            type="radio"
            
            name="LegalDisputes"
            value="No"
            checked={formData.LegalDisputes=== "No"}
            onChange={handleChange}
          />
        <label >
             No
        </label>
        </div>
        
          
         
        
        
        </div>
      </div>
      

      



      <button onClick={onPrevious}>Previous</button>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  )

}



