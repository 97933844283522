import React, { useState } from "react";

import axios from "axios";
import AlertPopup from "../AlertPopup";
import BloggerEditor from '../Blogs/BloggerEditor'
import './CreateBlog.css'
const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [alertMessage,setAlertMessage]=useState('')
  const [error,setError]=useState('')
  const [category,setCategory]=useState('')

  const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newBlog = { title, author, summary,category, content };
      await axios.post(`${BACKEND_URL}/api/create-blogs`, newBlog);
      setAlertMessage("Blog created successfully!");
      setTitle("")
      setAuthor("")
      setContent("")
      setSummary("")
      setCategory("")
    } catch (error) {
      
      setError('Failed to create blog')
    }
  };

  if(error){
    return(
    <div className='error-class'>
      <p>{error}</p>
      <button onClick={() => window.location.reload()}>Retry</button>
    </div>
    )
  }



  return (
    <div className="create-blog-form">
    <form onSubmit={handleSubmit} >
      <h2>Create Blog</h2>
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />

      <input
        type="text"
        placeholder="Author"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        required
      />

      <input
        type="text"
        placeholder="Summary"
        value={summary}
        onChange={(e) => setSummary(e.target.value)}
        required
      />
      <input type='text'
      placeholder='Category'
      value={category}
      onChange={(e)=>setCategory(e.target.value)}
      required
      />
      <BloggerEditor setContent={setContent} content={content}/>
  

      <button type="submit" className="create-blog-btn">Create Blog</button>
    </form>
    {/* <div>
        <h3>Preview Content</h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div> */}
    </div>
  );
};

export default CreateBlog;
