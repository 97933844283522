import React from 'react';
import { useNavigate} from 'react-router-dom';
import '../../Styles/blog-items.css'

const BlogItems = ({blog,searchTerm}) => {
    const navigate = useNavigate();

     const highlightText = (text, term) => {
        if (!term) return text; // If no search term, return original text

        // Create a regex to match the search term (case-insensitive)
        const regex = new RegExp(`(${term})`, 'gi');

        // Replace matches with highlighted text
        return text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
    };

    const handleReadMore = () => {
        navigate(`/blogs/${blog._id}`);  // Adjust based on your routing setup
    };

    //  const hasRelevantMatch = () => {
    //     const contentMatch = blog.content.some((section) =>
    //         section.subheading.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         section.paragraphs.some((paragraph) =>
    //             paragraph.toLowerCase().includes(searchTerm.toLowerCase())
    //         )
    //     );

    //     const titleMatch = blog.title.toLowerCase().includes(searchTerm.toLowerCase());
    //     const summaryMatch = blog.summary.toLowerCase().includes(searchTerm.toLowerCase());

    //     return contentMatch && !titleMatch && !summaryMatch;
    // };

    const hasRelevantMatch = () => {
    const regex = new RegExp(searchTerm, 'i'); // Case-insensitive search

    // Match title or summary
    const titleMatch = regex.test(blog.title);
    const summaryMatch = regex.test(blog.summary);

    // Match in content HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(blog.content || '', 'text/html');
    const contentText = doc.body.textContent || ''; // Extract plain text from HTML
    const contentMatch = regex.test(contentText);

    return contentMatch && !titleMatch && !summaryMatch; // Relevant match if content matches but not title or summary
};


    const extractFirstImage = (htmlContent) => {
    if (!htmlContent) return null;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const firstImage = doc.querySelector('img');

    return firstImage ? firstImage.src : null; // Return the image source or null if no image is found
  };

  const firstImageSrc = extractFirstImage(blog.content);
    

    return (
        <div className="blog-items-container">
            
            <img src={firstImageSrc}  />
            <div className='blog-items'>
            <h3 dangerouslySetInnerHTML={{
                        __html: highlightText(blog.title, searchTerm),
                    }}></h3>
            <p  dangerouslySetInnerHTML={{
                        __html: highlightText(blog.summary, searchTerm),
                    }}></p> {/* Display the summary here */}
            
            {hasRelevantMatch() && (
                    <span className="note">Relevant match inside the blog content. Please click Read more</span>
                )}
            <button onClick={handleReadMore}>Read More</button>
            </div>
        </div>
    );
};

export default BlogItems;
