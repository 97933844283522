import React, { useEffect, useState } from "react";
import axios from "axios";
import AlertPopup from "../AlertPopup";

function FormPage6({ formData, setFormData, setCurrentPage }) {
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [success, setSuccess] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onNext = () => {
    if (formData.categories === "Super Stockist") {
      setCurrentPage(3);
    } else if (
      formData.categories ===
      "Dealer, Distributor, Sub Distributor, Wholesaler, Hawker"
    ) {
      setCurrentPage(4);
    } else if (formData.categories === "Retailer") {
      setCurrentPage(5);
    }
  };

  //  ^(?:\+91[-.\s]?)?(?:0?\d{10}|\d{10}|\d{11})$|^(?:0\d{2,5}[-.\s]?\d{6,8})$
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (files && files[0]) {
      if (name === "shopImage") {
        setImageUrl(URL.createObjectURL(files[0]));

        setFormData({ ...formData, shopImage: files[0] });
      } else if (name === "shopVideo") {
        setVideoUrl(URL.createObjectURL(files[0]));
        setFormData({ ...formData, shopVideo: files[0] });
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) {
      newErrors.firstName = "Please enter your first name";
    }
    if (!formData.lastName) {
      newErrors.lastName = "Please enter your last name";
    }
    const numberPattern = /^(?:0\d{10}|\d{10})$/;
    if (
      !formData.contactNumber ||
      !numberPattern.test(formData.contactNumber)
    ) {
      newErrors.contactNumber =
        "Please enter a valid phone number.(e.g 9876543210,09876543210).Do not put +91 before number";
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.companyName) {
      newErrors.companyName = "Please enter your company name";
    }
    const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9A-Z]{5}[0-9A-Z][Z][0-9A-Z]$/;
    if (!formData.gstin || !gstPattern.test(formData.gstin)) {
      newErrors.gstin = "Please enter a valid GST number";
    }

    if (!formData.location) {
      newErrors.location = "Please enter your location";
    }
    if (!formData.operatingAreas) {
      newErrors.operatingAreas = "Please enter your areas of operating";
    }
    if (!formData.experience) {
      newErrors.experience = "Please enter experience";
    }
    if (!formData.monthlyTurnover) {
      newErrors.monthlyTurnover = "Please enter your monthly turnover";
    }
    if (!formData.productCategories) {
      newErrors.productCategories = "Please select product category";
    }
    if (!formData.categories) {
      newErrors.categories = "Please select one of the category";
    }
    if (
      !formData.productCategories_other &&
      formData.productCategories === "Other"
    ) {
      newErrors.productCategories_other = "Please enter other category";
    }
    if (!formData.shopImage) {
      newErrors.shopImage = "Please upload shop image";
    }
    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
  };

  useEffect(() => {
    if (isValid && isPhoneNumberVerified) {
      onNext();
    }
  }, [isValid, isPhoneNumberVerified]);

  const handleNext = async () => {
    if (!isPhoneNumberVerified) {
      setAlertMessage("Please verify your phone number");
    }

    if (!isValid) {
      setAlertMessage("Please fill all mandatory fields");
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BACKEND_URL}/distributor/verify-gstin`,
        {
          gstin: formData.gstin,
        }
      );
      if (response.status === 200) {
        setIsVerified(true);
        setFormData({
          ...formData,
          gstinStatus: response.data.data.gstinStatus,
          legalNameOfBusiness: response.data.data.legalNameOfBusiness,
          principalPlaceState: response.data.data.principalPlaceState,
        });
      } else {
        setAlertMessage("GST verification verified");
      }
    } catch (err) {
      // setAlertMessage('GST verification failed. Please enter valid GST Number')
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendOtp = async () => {
    const Errors = {};
    const success = {};

    setIsLoading(true);
    try {
      const response = await axios.post(`${BACKEND_URL}/distributor/get-otp`, {
        mobileNumber: `91${formData.contactNumber}`,
      });

      if (response.status === 200) {
        success.Otp = "OTP sent";
        setOtpSent(true);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          Otp: "Invalid OTP",
        }));
      }
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Otp: "Error in Sending OTP",
      }));
    } finally {
      setIsLoading(false);
    }

    setSuccess(success);
  };

  const handleVerify = async () => {
    const Errors = {};
    const body = {
      mobileNumber: `91${formData.contactNumber}`,
      otp: otp,
    };

    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BACKEND_URL}/distributor/verify-otp`,
        {
          params: body,
        }
      );

      if (response.status === 200) {
        setSuccess((prev) => ({
          ...prev,
          OtpVerificationSuccess: "Phone number verified",
        }));
        setIsPhoneNumberVerified(true);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          OtpVerification: "Invalid OTP",
        }));
      }
    } catch {
      setErrors((prevErrors) => ({
        ...prevErrors,
        OtpVerification: "Error in Sending OTP",
      }));
    }
    setIsLoading(false);

    setErrors(Errors);
  };

  return (
    <div className="form">
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage("")} />
      <div className="text-block">
        <h1>
          Become an Aryadhenu Super Stockist, Dealer, Distributor, Sub
          Distributor, Wholesaler, Hawker, and Retailer
        </h1>
        <p>
          Join the Aryadhenu family and bring high-quality, natural grocery
          products to your customers! We're looking for dedicated Super
          Stockist, Dealer, Distributor, Sub Distributor, Wholesaler, Hawker and
          Retailer across India to help expand our reach. If you're passionate
          about health and quality, fill out this form to express your interest
          and we'll get in touch with you soon. Let's grow together with
          Aryadhenu's Shudh Desi Khand-Boora, Desi Gur-Shakkar, Indian A2 Cow
          Desi Ghee and Pure Mustard Oil.
        </p>
      </div>
      <div className="text-input">
        <label>
          First Name: <span>*</span>
        </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName || ""}
          onChange={handleChange}
        />
        {errors.firstName ? (
          <div className="error-message">{errors.firstName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          Last Name: <span>*</span>
        </label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName || ""}
          onChange={handleChange}
        />
        {errors.lastName ? (
          <div className="error-message">{errors.lastName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          Contact Number: <span>*</span>
        </label>
        <div className="number-field">
          <input
            type="text"
            name="contactNumber"
            value={formData.contactNumber || ""}
            onChange={handleChange}
            disabled={isPhoneNumberVerified}
          />
          {!isPhoneNumberVerified && (
            <button onClick={handleSendOtp} disabled={!formData.contactNumber}>
              Send OTP
            </button>
          )}
        </div>
        {errors.Otp ? <div className="error-message">{errors.Otp}</div> : ""}
        {success.Otp && !isPhoneNumberVerified ? (
          <div className="success-note">{success.Otp}</div>
        ) : (
          ""
        )}

        {errors.contactNumber ? (
          <div className="error-message">{errors.contactNumber}</div>
        ) : (
          ""
        )}

        {formData.contactNumber && !isPhoneNumberVerified && otpSent && (
          <div className="number-field">
            <p className="error-message">
              Please verify Phone number to continue
            </p>
            <input
              type="text"
              name="otp"
              placeholder="Please enter otp recieved on your phone number"
              value={otp || ""}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button onClick={handleVerify} disabled={!otp}>
              Verify
            </button>
          </div>
        )}
        {success.OtpVerificationSuccess ? (
          <div className="success-note">{success.OtpVerificationSuccess}</div>
        ) : (
          ""
        )}
        {errors.OtpVerification ? (
          <div className="error-message">{errors.OtpVerification}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>Alternate Contact Number(Optional):</label>
        <input
          type="text"
          name="alternateContactNumber"
          value={formData.alternateContactNumber || ""}
          onChange={handleChange}
        />
      </div>
      <div className="text-input">
        <label>
          Email: <span>*</span>
        </label>
        <input
          type="text"
          name="email"
          value={formData.email || ""}
          onChange={handleChange}
        />
        {errors.email ? (
          <div className="error-message">{errors.email}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          Company Name: <span>*</span>
        </label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName || ""}
          onChange={handleChange}
        />
        {errors.companyName ? (
          <div className="error-message">{errors.companyName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          GST Number: <span>*</span>
        </label>
        <input
          type="text"
          name="gstin"
          value={formData.gstin || ""}
          onChange={handleChange}
        />
        {errors.gstin ? (
          <div className="error-message">{errors.gstin}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          Current Location:<span>*</span>
        </label>
        <input
          type="text"
          name="location"
          value={formData.location || ""}
          onChange={handleChange}
        />
        {errors.location ? (
          <div className="error-message">{errors.location}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          Which areas do you currently operate in (city/region)? <span>*</span>
        </label>
        <input
          type="text"
          name="operatingAreas"
          value={formData.operatingAreas || ""}
          onChange={handleChange}
        />
        {errors.operatingAreas ? (
          <div className="error-message">{errors.operatingAreas}</div>
        ) : (
          ""
        )}
      </div>

      <div className="radio">
        <p>
          What product categories do you currently handle? <span>*</span>
        </p>
        <div className="options">
          <div className="option">
            <input
              type="radio"
              name="productCategories"
              value="FMCG"
              checked={formData.productCategories === "FMCG"}
              onChange={handleChange}
            />
            <label>FMCG</label>
          </div>
          <div className="option">
            <input
              type="radio"
              name="productCategories"
              value="Other"
              checked={formData.productCategories === "Other"}
              onChange={handleChange}
            />
            <label>Other</label>
            {formData.productCategories === "Other" && (
              <input
                type="text"
                name="productCategories_other"
                value={formData.productCategories_other || ""}
                onChange={handleChange}
                className="other-category-input"
              />
            )}
          </div>
          {errors.productCategories ? (
            <div className="error-message">{errors.productCategories}</div>
          ) : (
            ""
          )}
          {errors.productCategories_other ? (
            <div className="error-message">
              {errors.productCategories_other}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="text-input">
        <label>
          How many years of experience do you have?<span>*</span>
        </label>
        <input
          type="text"
          name="experience"
          value={formData.experience || ""}
          onChange={handleChange}
        />
        {errors.experience ? (
          <div className="error-message">{errors.experience}</div>
        ) : (
          ""
        )}
      </div>
      <div className="text-input">
        <label>
          What is your current monthly turnover?<span>*</span>
        </label>
        <input
          type="text"
          name="monthlyTurnover"
          value={formData.monthlyTurnover || ""}
          onChange={handleChange}
        />
        {errors.monthlyTurnover ? (
          <div className="error-message">{errors.monthlyTurnover}</div>
        ) : (
          ""
        )}
      </div>

      <div className="radio">
        <p>
          Which of the following categories do you fall under? <span>*</span>
        </p>
        <div className="options">
          <div className="option">
            <input
              type="radio"
              name="categories"
              value="Super Stockist"
              checked={formData.categories === "Super Stockist"}
              onChange={handleChange}
            />
            <label>Super Stockist</label>
          </div>
          <div className="option">
            <input
              type="radio"
              name="categories"
              value="Dealer, Distributor, Sub Distributor, Wholesaler, Hawker"
              checked={
                formData.categories ===
                "Dealer, Distributor, Sub Distributor, Wholesaler, Hawker"
              }
              onChange={handleChange}
            />
            <label>
              Dealer, Distributor, Sub Distributor, Wholesaler, Hawker
            </label>
          </div>
          <div className="option">
            <input
              type="radio"
              name="categories"
              value="Retailer"
              checked={formData.categories === "Retailer"}
              onChange={handleChange}
            />
            <label>Retailer</label>
          </div>
          {errors.categories ? (
            <div className="error-message">{errors.categories}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="text-input">
        <label>
          Upload Shop Image: <span>*</span>
        </label>
        <input
          type="file"
          name="shopImage"
          accept="image/*"
          onChange={handleFileChange}
        />
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Shop Preview"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        )}
      </div>
      {errors.shopImage ? (
        <div className="error-message">{errors.shopImage}</div>
      ) : (
        ""
      )}

      <div className="text-input">
        <label>Upload Shop Video:</label>
        <input
          type="file"
          name="shopVideo"
          accept="video/*"
          onChange={handleFileChange}
        />
        {videoUrl && (
          <video controls style={{ width: "100px", height: "100px" }}>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      <button onClick={handleNext} disabled={!isValid}>
        Next
      </button>
      {isLoading && <div className="loader"></div>}
    </div>
  );
}

export default FormPage6;
