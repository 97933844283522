import React,{useState} from 'react';
import axios from 'axios';
import AlertPopup from './AlertPopup';


const RAZORPAY_KEY_ID=process.env.REACT_APP_RAZORPAY_KEY_ID
 const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
const Payment = ({onPaymentSuccess,amount,systemOrderId,courierId,email,phone,customername}) => {
    const [alertMessage,setAlertMessage]=useState('')
    const handlePayment = async () => {

        if(!systemOrderId || !courierId){
            setAlertMessage('Please click Proceed to Pay before payment.')
        }
        else{
        try {
            // Request an order from your backend
            const { data } = await axios.post(`${BACKEND_URL}/create-order`, {
                amount: amount*100, // Amount in INR
                
            });

            const options = {
                key: RAZORPAY_KEY_ID, // Your Razorpay Key ID
                amount: data.amount,
                currency: data.currency,
                name: 'Aryadhenu',
                description: 'Shop at Aryadhenu',
                order_id: data.id, // The order ID returned from the server
                handler: async function (response) {
                    try {
                        // Verify the payment on the backend
                      const res=await axios.post(`${BACKEND_URL}/verify-payment`, {
                            order_id: response.razorpay_order_id,
                            payment_id: response.razorpay_payment_id,
                            signature: response.razorpay_signature,
                        });

                        
                        if(res.status===200){
                          setAlertMessage('Payment Successful');
                          onPaymentSuccess(200, response.razorpay_order_id, response.razorpay_payment_id);

                        }else{
                            setAlertMessage('Payment failed')
                            onPaymentSuccess(400, response.razorpay_order_id, response.razorpay_payment_id);
                        }
                        
                    } catch (error) {
                        onPaymentSuccess(500, response.razorpay_order_id, response.razorpay_payment_id);
                        
                        setAlertMessage('Payment Verification Failed');
                        
                    }
                },
                prefill: {
                    name: customername,
                    email: email,
                    contact: phone,
                },
                theme: {
                    color: '#3399cc',
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

            //  Razorpay.open(options);
        } catch (error) {
            
        }
    }
    };

    return (
        <div>
             <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            <button onClick={handlePayment} className='checkout-container-button'
            style={{
                    backgroundColor: !systemOrderId || !courierId ? '#ccc' : '#e42727',
                    
                }}
            >Pay Now</button>
        </div>
    );
};

export default Payment;
