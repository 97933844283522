import React ,{useState,useEffect, useContext}from 'react'
import { useNavigate } from 'react-router-dom';
import {Helmet} from 'react-helmet'

import '../../Styles/Home.css'





// import profilePic from '../../Assets/sample-profile-pic.png'

import {faCartShopping,faArrowRightLong,faArrowLeftLong,faIndianRupeeSign,faTruckFast,faHeadset} 
from '@fortawesome/free-solid-svg-icons'
import Hero from './Hero';
import Navbar from '../Navbar';
import MakingProcess from './MakingProcess';
import Ghee from '../../Assets/website 500px ghee.jpg'
import Khand500 from '../../Assets/khand500.png'

import Products from './Products';

import Advantages from './Advantages';
import family from '../../Assets/Home-Page/Family.jpeg'
import Reviews from './Reviews';
import { AppContext,loading } from '../../AppContext';
import BlogPagination from './BlogPagination';
import Testimonials from './Testimonials';

















function Home() {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollTestimonial,setScrollTestimonial]=useState(0);
  const {blogs,netWorkError}=useContext(AppContext)
  const navigate=useNavigate()
  const [isLoading,setIsLoading]=useState(true)

  useEffect(() => {
       window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 1000); 

        return () => clearTimeout(timer); 
    }, [navigate]);

  useEffect(() => {
        // Calculate container width for exactly 4 cards
        const calculateContainerWidth = () => {
            const cardWidth = 300; // Width of each card
            const margin = 30; // Total margin (left + right) around each card
            const visibleCards=4;
            setContainerWidth((cardWidth + margin) * visibleCards-margin); // 4 cards visible at a time
        };

        calculateContainerWidth();
        window.addEventListener('resize', calculateContainerWidth);
        return () => window.removeEventListener('resize', calculateContainerWidth);
    }, []);

   
    
    const handleNavigate=()=>{
      navigate('/shop')
    }

    
    

    
    
  
  return (
    <div className='home'>
      <Helmet>
        <meta charset="UTF-8"></meta>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
         ></meta>
        <meta name="author" content="Aryadhenu" />
        <meta name="robots" content=""></meta>
        <link rel="canonical" href=""></link>
        <title>Aryadhenu Natural Diet Pvt Ltd</title>
        <meta name="description" content="Zero Chemicals, Natural Sweeteners. One & Only Substitute to Sugar. Pure Sweetness, Pure Energy" />
        <meta name='keywords' content="Aryadhenu,jaggery gur,healthy sweet,raw sugars,cane sugar,low sugar snacks,no sugar snacks,sweet and healthy foods,pure cane sugar,raw cane sugar,sugar pure cane,sugar cane raw sugar,jaggery gur,jaggery power,Khand,khandsari,Desi Khand,Organic Khand,Khand,jaggery nutrients
        ,Desi Khand,desi Khand online,desi khand sugar,1 kg khand price,best desi khand,buy desi khand,desi khand 1 kg price,Jaggery,gur jaggery,gur and sugar,Gur Making,cane jaggery,sugarcane jaggery,gur from sugarcane,gud price 1kg,indian jaggery,jagari food,jaggery what is,what is jaggery powder,what is jaggery powder in hindi,Sugar,brown sugar,sweeteners,Natural Sugar,cane sugar,sugar substitutes,Natural Sweeteners,brown sugar replacement,natural sugar,sugar replacement,natural sugar substitutes,natural sugar alternatives,best natural sweetener,white sugar,sugar alternatives,desi khand,desi khand sugar,Cane Sugar,sugarcane jaggery,Shakkar,desi shakkar,Jaggery Powder,organic jaggery powder,jaggery powder organic,jaggery powder price,gud shakkar,gud powder,best jaggery powder,jaggery powder 1 kg price,jaggery powder online,shakkar price 1kg,1 kilo shakkar,shakkar price,gud ka powder,natural jaggery powder,cane jaggery powder,gur powder,gud shakkar,best jaggery powder,1 kg shakkar price,cane jaggery powder,shakkar online,online shakkar,boora,boora sugar,natural ,raw,best,gur,desi,healthy ,substitute,sugar,online gur" />
      </Helmet>
      {isLoading?<div className='loader-container'>
        <div className='loader-container'>
        <div className='loader'></div>
      </div>
      </div>:(
        <>
        <div>
        {/* <HeroCarousel/> */}
        
        <Hero/>
       
        </div>
        <div className='intro-left'>
          <img src={Khand500} alt='Khand'></img>
           <div className='intro-content-left'>
            
            <h1>Delight Taste</h1>
            <h2> Desi Khandsari Sugar</h2>
            <p>Everyone loves sweets, but those made with chemically processed white sugar and artificial additives lack the rich taste of Indian sweets and desserts. So prepare sweets with 100% pure, raw, and sulfur-free Aryadhenu khandsari sugar, which fulfils all your sweet cravings and meets your taste and nutritional needs.
            </p>
            <button onClick={handleNavigate}>Shop Now</button>

           </div>
           
        </div>
        <div className='intro'>
           <div className='intro-content'>
            <h1>Delight Taste</h1>
            <h2>Indian A2 Cow Ghee</h2>
            <p>विदेशी नस्ल की गाय का घी हृदय रोग, मोटापा और शुगर का मुख्य कारण है हम भारतीय को इससे बचना होगा। खेतों में चरने वाली राजस्थानी गाय के दूध से निर्मित, 
              Good Cholesterol से भरपूर, खानें में स्वादिष्ट, आर्यधेनु देसी घी का सेवन करें - निरोग रहें। 
            </p>
            <button onClick={handleNavigate}>Shop Now</button>

           </div>
           <img src={Ghee} alt='Ghee'></img>
        </div>
         
        
        <Products/>
        <div> <MakingProcess/></div>
        <div><Advantages/></div>
       
        
        
       {/* <div><Reviews/></div> */}
       <div><Testimonials/></div>
        {netWorkError ?<div className='error-message'>{netWorkError}</div>:(
        <BlogPagination blogs={blogs}/>
        )}
         <div className='contact'>
          <img src={family} alt='contact'/>
        </div>

        </>
        )}
        
         <Navbar isHomePage={true}/>
         
      
    </div>
  )
}

export default Home