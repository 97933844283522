import React, { useState } from 'react';
import axios from 'axios';
import '../Styles/ProductImagesForm.css'
import AlertPopup from './AlertPopup';


const ProductImageForm = () => {
    const [productId, setProductId] = useState('');
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [alertMessage,setAlertMessage]=useState('')
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

    const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert images to Base64
        const base64Image1 = image1 ? await toBase64(image1) : null;
        const base64Image2 = image2 ? await toBase64(image2) : null;
        const base64Image3 = image3 ? await toBase64(image3) : null;

        const payload = {
            productId,
            image1: base64Image1,
            image2: base64Image2,
            image3: base64Image3,
        };

        try {
            const response = await axios.post(`${BACKEND_URL}/create-update-product-images`, payload);
            setAlertMessage(response.data.message);
        } catch (error) {
            console.error(error);
            setAlertMessage('Error uploading images');
        }
    };

    return (
        <div className="product-images-form">
            <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            <h2>Upload Product Images</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="productId">Product ID</label>
                    <input
                        type="text"
                        id="productId"
                        name="productId"
                        value={productId}
                        onChange={(e) => setProductId(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label className="file-label" htmlFor="image1">Image 1</label>
                    <input
                        type="file"
                        id="image1"
                        name="image1"
                        onChange={(e) => setImage1(e.target.files[0])}
                    />
                </div>
                <div className="form-group">
                    <label className="file-label" htmlFor="image2">Image 2</label>
                    <input
                        type="file"
                        id="image2"
                        name="image2"
                        onChange={(e) => setImage2(e.target.files[0])}
                    />
                </div>
                <div className="form-group">
                    <label className="file-label" htmlFor="image3">Image 3</label>
                    <input
                        type="file"
                        id="image3"
                        name="image3"
                        onChange={(e) => setImage3(e.target.files[0])}
                    />
                </div>
                <button type="submit" className='product-images-btn'>Submit</button>
            </form>
        </div>
    );
};

export default ProductImageForm;
