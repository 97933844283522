import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faIndianRupeeSign,
  faArrowRightLong,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import "../../Styles/TrendingProducts.css";
import background from "../../Assets/Home-Page/background .png";
import { useNavigate } from "react-router-dom";

function Products() {
  const { products, setProducts, getActiveSaleForProduct } =
    useContext(AppContext);

  const [messages, setMessages] = useState({});

  const [productsLabel, setProductsLabel] = useState("featured");
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/getProduct`);

        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          setProducts(data);
          sessionStorage.setItem("products", JSON.stringify(data));
          setMessages({ success: response.message });
        } else {
          setMessages({ error: response.message });
        }
      } catch (error) {
        setMessages({ error: { error } });
      }
    };
    const storedProducts = sessionStorage.getItem("products");
    if (storedProducts) {
      setProducts(JSON.parse(storedProducts));
    } else {
      fetchProducts();
    }
  }, [navigate]);

  const getFeatureProducts = () => {
    let featuredProducts = [];
    featuredProducts = products.filter((product) => product.isFeatured);
    return featuredProducts.slice(currentIndex, currentIndex + 4);
  };

  const featuredProductsLength = Math.ceil(
    products.filter((p) => p.isFeatured).length
  );
  const handleFeaturedNext = () => {
    if (currentIndex + 4 < featuredProductsLength) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleFeaturedPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const getLatestProducts = () => {
    return products
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort by timestamp descending
      .slice(0, 4); // Get top 4 products
  };

  const getTopRatedProducts = () => {
    return products
      .sort((a, b) => b.rating - a.rating) // Sort by rating descending
      .slice(0, 4); // Get top 4 products
  };

  const handleProductClick = (productId) => {
    // navigate('/shop', { state: { currentProduct: product } }); // Navigate to ShopProducts with current product
    navigate(`/shop/${productId}`);
  };

  const calculateDiscountPercentage = (quotePrice, discountPrice) => {
    if (quotePrice && discountPrice) {
      const discount = ((quotePrice - discountPrice) / quotePrice) * 100;
      return discount > 0 ? discount.toFixed(1) : null; // Limit to 1 decimal
    }
    return null;
  };
  return (
    <div className="trending-products">
      <div className="trending-products-title">
        <p className="title">Trending Products</p>
      </div>
      <div className="trending-products-buttons">
        <button
          style={{
            backgroundColor: productsLabel === "featured" ? "#e42727" : "#fff",
            color: productsLabel === "featured" ? "#fff" : "black",
          }}
          onClick={(e) => setProductsLabel("featured")}
        >
          Featured
        </button>
        <button
          style={{
            backgroundColor: productsLabel === "latest" ? "#FF3C4E" : "#fff",
            color: productsLabel === "latest" ? "#fff" : "black",
          }}
          onClick={(e) => setProductsLabel("latest")}
        >
          Latest
        </button>
        <button
          style={{
            backgroundColor:
              productsLabel === "bestSeller" ? "#FF3C4E" : "#fff",
            color: productsLabel === "bestSeller" ? "#fff" : "black",
          }}
          onClick={(e) => setProductsLabel("bestSeller")}
        >
          Best Seller
        </button>
      </div>
      <div className="trending-products-card">
        <ul>
          {productsLabel === "featured" &&
            getFeatureProducts().map((product) => {
              const discountPercentage = calculateDiscountPercentage(
                product.quotePrice,
                product.discountPrice
              );
              const activeSale = getActiveSaleForProduct(product._id);
              return (
                <li
                  key={product.id}
                  className="product-card"
                  onClick={() => handleProductClick(product._id)}
                >
                  {discountPercentage > 0 && (
                    <div className="discounted-badge">
                      {discountPercentage}% off
                    </div>
                  )}

                  {activeSale && activeSale.isPromotional === false && (
                    <div
                      className="sale-information"
                      style={{
                        backgroundImage:
                          "linear-gradient(45deg,rgb(241, 10, 10),rgb(47, 11, 4))",
                      }}
                    >
                      <p>
                        {" "}
                        {activeSale.discountType === "amount"
                          ? `Flat ₹${activeSale.discount} Off`
                          : `${activeSale.discount}% Off`}{" "}
                      </p>
                      <p className="sale-min-purchase">
                        Min Purchase: Rs.{activeSale.minPurchase}
                      </p>
                      <p>Coupon:{activeSale.couponCode}</p>
                    </div>
                  )}

                  <img src={product.imageUrl} alt="product image"></img>
                  <p>{product.title}</p>
                  <span
                    className="review-stars"
                    style={{
                      backgroundColor: "transparent",
                      borderStyle: "none",
                    }}
                  >
                    {"⭐".repeat(product.rating)}
                  </span>
                  <div className="price-details">
                    {product.discountPrice === product.quotePrice ? (
                      <>
                        <p style={{ marginRight: "10px", fontSize: "14px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            color: "#E42727",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginLeft: "0px",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.discountPrice}
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          style={{
                            color: "#E42727",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.discountPrice}
                        </p>
                        <p style={{ marginRight: "10px", fontSize: "14px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            textDecoration: "line-through",
                            marginLeft: "0px",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.quotePrice}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="add-cart">
                    {/* <p><FontAwesomeIcon icon={faCartShopping} ></FontAwesomeIcon>  Add to Cart</p> */}
                  </div>
                </li>
              );
            })}

          {productsLabel === "latest" &&
            getLatestProducts().map((product) => {
              const discountPercentage = calculateDiscountPercentage(
                product.quotePrice,
                product.discountPrice
              );
              const activeSale = getActiveSaleForProduct(product._id);
              return (
                <li
                  key={product.id}
                  className="product-card"
                  onClick={() => handleProductClick(product._id)}
                >
                  {discountPercentage > 0 && (
                    <div className="discounted-badge">
                      {discountPercentage}% off
                    </div>
                  )}

                  {activeSale && activeSale.isPromotional === false && (
                    <div
                      className="sale-information"
                      style={{
                        backgroundImage:
                          "linear-gradient(45deg,rgb(241, 10, 10),rgb(47, 11, 4))",
                      }}
                    >
                      <p>
                        {" "}
                        {activeSale.discountType === "amount"
                          ? `Flat ₹${activeSale.discount} Off`
                          : `${activeSale.discount}% Off`}{" "}
                      </p>
                      <p className="sale-min-purchase">
                        Min Purchase: Rs.{activeSale.minPurchase}
                      </p>
                      <p>Coupon:{activeSale.couponCode}</p>
                    </div>
                  )}
                  <img src={product.imageUrl} alt="product image" />
                  <p>{product.title}</p>
                  <span
                    className="review-stars"
                    style={{
                      backgroundColor: "transparent",
                      borderStyle: "none",
                    }}
                  >
                    {"⭐".repeat(product.rating)}
                  </span>
                  <div className="price-details">
                    {product.discountPrice === product.quotePrice ? (
                      <>
                        <p style={{ marginRight: "10px", fontSize: "14px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            color: "#E42727",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginLeft: "0px",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.discountPrice}
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          style={{
                            color: "#E42727",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.discountPrice}
                        </p>
                        <p style={{ marginRight: "10px", fontSize: "14px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            textDecoration: "line-through",
                            marginLeft: "0px",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.quotePrice}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="add-cart">
                    {/* <p>
                <FontAwesomeIcon icon={faCartShopping} /> Add to Cart
              </p> */}
                  </div>
                </li>
              );
            })}

          {productsLabel === "bestSeller" &&
            getTopRatedProducts().map((product) => {
              const activeSale = getActiveSaleForProduct(product._id);
              return (
                <li
                  key={product.id}
                  className="product-card"
                  onClick={() => handleProductClick(product._id)}
                >
                  {activeSale && activeSale.isPromotional === false && (
                    <div
                      className="sale-information"
                      style={{
                        backgroundImage:
                          "linear-gradient(45deg,rgb(241, 10, 10),rgb(47, 11, 4))",
                      }}
                    >
                      <p>
                        {" "}
                        {activeSale.discountType === "amount"
                          ? `Flat ₹${activeSale.discount} Off`
                          : `${activeSale.discount}% Off`}{" "}
                      </p>
                      <p className="sale-min-purchase">
                        Min Purchase: Rs.{activeSale.minPurchase}
                      </p>
                      <p>Coupon:{activeSale.couponCode}</p>
                    </div>
                  )}
                  <img src={product.imageUrl} alt="product image" />
                  <p>{product.title}</p>
                  <span
                    className="review-stars"
                    style={{
                      backgroundColor: "transparent",
                      borderStyle: "none",
                    }}
                  >
                    {"⭐".repeat(product.rating)}
                  </span>
                  <div className="price-details">
                    {product.discountPrice === product.quotePrice ? (
                      <>
                        <p style={{ marginRight: "10px", fontSize: "14px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            color: "#E42727",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginLeft: "0px",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.discountPrice}
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          style={{
                            color: "#E42727",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.discountPrice}
                        </p>
                        <p style={{ marginRight: "10px", fontSize: "14px" }}>
                          MRP
                        </p>
                        <p
                          style={{
                            textDecoration: "line-through",
                            marginLeft: "0px",
                          }}
                        >
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                          {product.quotePrice}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="add-cart">
                    {/* <p>
                <FontAwesomeIcon icon={faCartShopping} /> Add to Cart
              </p> */}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="scroll-cards">
        <button className="left-scroll" onClick={handleFeaturedPrev}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <button className="right-scroll" onClick={handleFeaturedNext}>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
    </div>
  );
}

export default Products;
